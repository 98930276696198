import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BASE_PATH } from "../utils/constants";

// Bootstrap Components
import { Col, Container, Row } from "react-bootstrap";

// Custom Components
import { JumbotronCarousel } from "../components/Carousel/Carousel";
import CtaButton from "../components/CtaButton/CtaButton";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import formatMoney from "../utils/formatMoney";

// API
import { getPopularProducts } from "../api/product";
import { getBrands } from "../api/brand";

// Assets
import About1 from "../assets/images/historia-la-relojeria.jpg";
// import About1 from "../assets/images/watch-about--index-1.jpg";
import About2 from "../assets/images/identificar-reloj.jpg";
// import About2 from "../assets/images/watch-about--index-2.jpg";
import TiktokIcon from "../assets/images/tiktok-icon--white.svg";
import Category1 from "../assets/images/relojes-nuevos.jpg";
import Category2 from "../assets/images/relojes-seminuevos.jpg";
import Category3 from "../assets/images/watch-category--index-3.jpg";
import Category4 from "../assets/images/relojes-rifa.jpg";
// import Product1 from "../assets/images/watch-models--index-1.jpg";
// import Product2 from "../assets/images/watch-models--index-2.jpg";
// import Product3 from "../assets/images/watch-models--index-3.jpg";

// import jumbotron1 from "../assets/images/jumbotron-bg.jpg";
import jumbotron1 from "../assets/images/indice.jpg";
import jumbotron2 from "../assets/images/jumbotron2.jpg";
// import jumbotron2 from "../assets/images/watch-men--index.jpg";
// import jumbotron3 from "../assets/images/watch-women--index.jpg";
import jumbotron3 from "../assets/images/jumbotron3.jpg";
import jumbotron4 from "../assets/images/jumbotron4.jpg";
// import jumbotron3 from "../assets/images/watch-women--index.jpg";

export default function Home() {
  const [popularProducts, setPopularProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getPopularProducts();
      setPopularProducts(response.data);

      // if (query.platform) {
      // }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getBrands();
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
          delete response.data[index].attributes.nombre;
          delete response.data[index].attributes.imagen.data.attributes
            .alternativeText;
          delete response.data[index].attributes.imagen.data.attributes.caption;
          delete response.data[index].attributes.imagen.data.attributes
            .createdAt;
          delete response.data[index].attributes.imagen.data.attributes.ext;
          delete response.data[index].attributes.imagen.data.attributes.hash;
          delete response.data[index].attributes.imagen.data.attributes.height;
          delete response.data[index].attributes.imagen.data.attributes.mime;
          delete response.data[index].attributes.imagen.data.attributes.name;
          delete response.data[index].attributes.imagen.data.attributes
            .previewUrl;
          delete response.data[index].attributes.imagen.data.attributes
            .provider;
          delete response.data[index].attributes.imagen.data.attributes
            .provider_metadata;
          delete response.data[index].attributes.imagen.data.attributes.size;
          delete response.data[index].attributes.imagen.data.attributes
            .updatedAt;
        }
        setBrands(response.data);
      }
      // initFilters();
      // if (query.platform) {
      // }
    })();
  }, []);
  return (
    <section className="home">
      {/* <JumbotronCarousel /> */}
      <Carousel showStatus={false} autoPlay={true} infiniteLoop={true}>
        <div>
          <img src={jumbotron1} />
          <div className="slide__media"></div>
          <div className="legend">
            <h2>Descubre los últimos relojes de esta temporada</h2>
            <CtaButton
              text="Descubre"
              align="left"
              extraContainerClasses="jumbotron__slide__cta-container"
              href="/catalogue"
            />
          </div>
        </div>
        <div>
          <img src={jumbotron2} />
          <div className="slide__media"></div>
          <div className="legend">
            <h2>Descubre nuestras subastas diarias</h2>
            <CtaButton
              text="Descubre"
              align="left"
              extraContainerClasses="jumbotron__slide__cta-container"
              href="/bids"
            />
          </div>
        </div>
        <div>
          <img src={jumbotron4} />
          <div className="slide__media"></div>
          <div className="legend">
            <h2>Descubre nuestras rifas diarias</h2>
            <CtaButton
              text="Descubre"
              align="left"
              extraContainerClasses="jumbotron__slide__cta-container"
              href="/raffles"
            />
          </div>
        </div>
      </Carousel>
      <div className="brands__container">
        <Container>
          <Row className="h-100">
            {brands?.map((brand, key) => (
              <Col xs="6" lg="3" key={key}>
                <Link to={`/brand/${brand.attributes.slug}`}>
                  <img
                    src={brand.attributes.imagen.data.attributes.url}
                    className="img-fluid"
                    alt="La Relojeriair"
                  />
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <section className="about">
        <div className="about__container">
          <h3 className="about__title">
            ¿Buscas un reloj? Llegaste al lugar indicado, bienvenido a la
            Relojería.{" "}
          </h3>
          <p className="about__text">
            Te invitamos a que visites nuestro catalogo de relojes originales en
            venta promociones todos los días
          </p>
        </div>
      </section>
      <section className="product-gallery">
        <div className="product-gallery__container">
          <div className="product-gallery__header">
            <p className="product-gallery__header__title">
              Modelos más populares
            </p>
            <Link to="/catalogue" className="product-gallery__header__link">
              conoce todos los modelos aquí
            </Link>
          </div>
          <div className="product-gallery__product-container">
            {/* {loading ? (
              <div>
                <h5>Cargando...</h5>
              </div>
            ) : (
              data.allProductosPopulares.map((product, key) => (
                <Link
                  to={`/productos/${product.producto.id}`}
                  className="product-gallery__product__holder"
                  key={key}
                >
                  <div className="product-gallery__product__image-container">
                    <img
                      src={
                        product.producto.photos[0].image.publicUrlTransformed
                      }
                      alt="Producto 1"
                      className="product-gallery__product__image"
                    />
                  </div>
                  <div className="product-gallery__product__content">
                    <p className="product-gallery__product__name">
                      {product.producto.name}
                    </p>
                  </div>
                </Link>
              ))
            )} */}
            {popularProducts?.map((product, key) => (
              <div className="product-gallery__product" key={key}>
                <Link
                  to={`/product/${product.attributes.producto.data.id}`}
                  className="product-gallery__product__holder"
                >
                  <div className="product-gallery__product__image-container">
                    <img
                      // src={`${BASE_PATH}${product.attributes.producto.data.attributes.imagenes.data[0].attributes.url}`}
                      src={
                        product.attributes.producto.data.attributes.imagenes
                          .data[0].attributes.url
                      }
                      alt="Producto 1"
                      className="product-gallery__product__image img-fluid"
                    />
                  </div>
                  <div className="product-gallery__product__content">
                    <p className="product-gallery__product__name">
                      {product.attributes.producto.data.attributes.nombre}
                    </p>
                    <p className="product-gallery__product__price">Precio: <span>{formatMoney(product.attributes.producto.data.attributes.precio)}</span></p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="catalogue-cta">
        <div className=" catalogue-cta__container">
          <div className="row" style={{ height: "100%" }}>
            <Link
              to="/genre/hombre"
              className="catalogue-cta__cta catalogue-cta__cta--men col-12 col-md-6"
            >
              <div className="catalogue-cta__cta__content">
                <h3 className="catalogue-cta__cta__title">
                  Relojes para hombre
                </h3>
              </div>
            </Link>
            <Link
              to="/genre/dama"
              className="catalogue-cta__cta catalogue-cta__cta--women col-12 col-md-6"
            >
              <div className="catalogue-cta__cta__content">
                <h3 className="catalogue-cta__cta__title">Relojes para dama</h3>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="social-cta">
        <div className="social-cta__container">
          <a
            href="https://www.facebook.com/La-Relojeria-Original-103481138576106"
            target="_blank"
            rel="noreferrer"
            className="social-cta__content"
          >
            <i className="social-cta__icon fab fa-facebook-f"></i>
            <p className="social-cta__text">
              Síguenos{" "}
              <span
                className="social-cta__text__highlight"
                style={{ fontWeight: "bolder" }}
              >
                en Facebook
              </span>
            </p>
          </a>
        </div>
        <div className="social-cta__container">
          <a
            href="https://www.instagram.com/larelojeriair/"
            target="_blank"
            rel="noreferrer"
            className="social-cta__content social-cta__content--ig"
          >
            <i className="social-cta__icon fab fa-instagram"></i>
            <p className="social-cta__text">
              Síguenos{" "}
              <span
                className="social-cta__text__highlight"
                style={{ fontWeight: "bolder" }}
              >
                en Instagram
              </span>
            </p>
          </a>
        </div>
        <div className="social-cta__container">
          <a
            href="https://www.tiktok.com/@larelojeriaoriginal"
            target="_blank"
            rel="noreferrer"
            className="social-cta__content social-cta__content--tk"
          >
            <img
              className="social-cta__icon"
              src={TiktokIcon}
              alt="La Relojería"
            />
            {/* <i className="social-cta__icon fab fa-tiktok"></i> */}
            <p className="social-cta__text">
              Síguenos{" "}
              <span
                className="social-cta__text__highlight"
                style={{ fontWeight: "bolder" }}
              >
                en TikTok
              </span>
            </p>
          </a>
        </div>
      </section>
      <section id="about-us" className="about-cta">
        <div className="about-cta__container">
          <div className="about-cta__category">
            <div className="row g-0 g-lg-4">
              <div className="about-cta__category__image-container col-12 col-md-6">
                <img
                  src={About1}
                  alt="Categoría 1"
                  className="about-cta__category__image img-fluid"
                />
                {/* <div className="about-cta__category__image__cta">
                  <p className="about-cta__category__image__cta__text">
                    Ver modelo
                  </p>
                  <i className="about-cta__category__image__cta__icon fal fa-long-arrow-right"></i>
                </div> */}
              </div>
              <div className="about-cta__category__content col-12 col-md-6 mt-4 mt-lg-0">
                <h4 className="about-cta__category__title">
                  Nos dedicamos a la compra y venta de relojes originales suizos
                  y japoneses con una experiencia y trayectoria de más de 10
                  años en el ámbito de la horologia{" "}
                </h4>
                <p className="about-cta__category__description">
                  Nuestros principios primordiales son la atención y
                  satisfacción del cliente checa nuestro catálogo de modelos de
                  relojes en venta nuestros precios te sorprenderán. Los relojes
                  originales además de ser un accesorio, una joya y sin duda un
                  artículo de valor son parte primordial de una primera
                  impresión encuentra el modelo que más te agrade y se adecue a
                  tus necesidades.
                </p>
                <CtaButton
                  text="Catálogo"
                  href="/catalogue"
                  align="left"
                  extraContainerClasses="about-cta__category__cta-container"
                  extraClasses="about-cta__category__cta"
                />
                <CtaButton
                  text="Catálogo"
                  href="/catalogue"
                  align="left"
                  arrow={true}
                  color="transparent"
                  textColor="red"
                  extraContainerClasses="about-cta__category__cta-container about-cta__category__cta-container--mobile"
                  extraClasses="about-cta__category__cta"
                />
              </div>
            </div>
          </div>
          <div className="about-cta__category">
            <div className="row g-0 g-lg-4">
              <div className="about-cta__category__content col-12 col-md-6">
                <h4 className="about-cta__category__title">
                  Es fácil aprender como usar e identificar cuando un reloj es
                  original pregúntanos con gusto te enseñaremos, somos expertos
                  en la materia
                </h4>
                <p className="about-cta__category__description">
                  Te invitamos a que encuentres el reloj de tus sueños en
                  nuestro catálogo de ventas a un súper precio y con una
                  excelente atención personalizada por nuestra parte, cualquier
                  duda o pregunta no dudes en realizarla estamos para servirte.
                </p>
                <CtaButton
                  text="Catálogo"
                  href="/catalogue"
                  align="left"
                  extraContainerClasses="about-cta__category__cta-container"
                  extraClasses="about-cta__category__cta"
                />
                <CtaButton
                  text="Catálogo"
                  href="/catalogue"
                  align="left"
                  arrow={true}
                  color="transparent"
                  textColor="red"
                  extraContainerClasses="about-cta__category__cta-container about-cta__category__cta-container--mobile"
                  extraClasses="about-cta__category__cta"
                />
              </div>
              <div className="about-cta__category__image-container col-12 col-md-6">
                <img
                  src={About2}
                  alt="Categoría 2"
                  className="about-cta__category__image"
                />
                {/* <div className="about-cta__category__image__cta">
                  <p className="about-cta__category__image__cta__text">
                    Ver modelo
                  </p>
                  <i className="about-cta__category__image__cta__icon fal fa-long-arrow-right"></i>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="categories">
        <div className="categories__container">
          <div className="categories__header">
            <h3 className="categories__title">Categorías</h3>
          </div>
          <div className="categories__category-container">
            <div className="row g-0 g-lg-4">
              <div className="categories__category col-12 col-md-3">
                <div className="categories__category__holder">
                  <div className="categories__category__image-container">
                    <img
                      src={Category1}
                      alt="Categoría 1"
                      className="categories__category__image"
                    />
                  </div>
                  <Link
                    className="categories__category__content"
                    to="/condition/nuevo"
                  >
                    <p className="categories__category__name">Relojes nuevos</p>
                    <i className="categories__category__icon fal fa-chevron-right"></i>
                  </Link>
                </div>
              </div>
              <div className="categories__category col-12 col-md-3">
                <div className="categories__category__holder">
                  <div className="categories__category__image-container">
                    <img
                      src={Category2}
                      alt="Categoría 2"
                      className="categories__category__image"
                    />
                  </div>
                  <Link
                    className="categories__category__content"
                    to="/condition/seminuevo"
                  >
                    <p className="categories__category__name">
                      Relojes seminuevos
                    </p>
                    <i className="categories__category__icon fal fa-chevron-right"></i>
                  </Link>
                </div>
              </div>
              <div className="categories__category col-12 col-md-3">
                <div className="categories__category__holder">
                  <div className="categories__category__image-container">
                    <img
                      src={Category3}
                      alt="Categoría 3"
                      className="categories__category__image"
                    />
                  </div>
                  <Link className="categories__category__content" to="/bids">
                    <p className="categories__category__name">
                      Relojes en subasta
                    </p>
                    <i className="categories__category__icon fal fa-chevron-right"></i>
                  </Link>
                </div>
              </div>
              <div className="categories__category col-12 col-md-3">
                <div className="categories__category__holder">
                  <div className="categories__category__image-container">
                    <img
                      src={Category4}
                      alt="Categoría 3"
                      className="categories__category__image"
                    />
                  </div>
                  <Link className="categories__category__content" to="/raffles">
                    <p className="categories__category__name">
                      Relojes en rifa
                    </p>
                    <i className="categories__category__icon fal fa-chevron-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

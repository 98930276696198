import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Context
import { useProducts } from "../../context/ProductsContext";
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";
import AlertContext from "../../context/AlertContext";

// Custom CSS
// import "./customNav.styles.scss";

// Bootstrap components
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
// import Container from "react-bootstrap/Container";

// API
import { getMeApi } from "../../api/user";
// import useAuth from "../../hooks/useAuth";
// import useCart from "../../hooks/useCart";
import { getProducts } from "../../api/product";

// Custom components
import Alert from "../Alert/Alert";

// Assets
import Logo from "../../assets/images/logo.svg";
import CartIcon from "../../assets/images/cart-icon--black.svg";
import ProfileIcon from "../../assets/images/profile-icon--black.svg";
import TiktokIcon from "../../assets/images/tiktok-icon--red.svg";

import SearchBar from "../SearchBar/SearchBar";

export default function CustomNav() {
  let history = useHistory();

  const [user, setUser] = useState(undefined);
  const [open, setOpen] = useState("");

  // const [products, setProducts] = useState([]);
  const { products } = useProducts();

  const { auth, logout } = useAuth();
  const { totalProductsCart } = useCart();

  const toggleOpen = () => {
    setOpen((prevOpen) => (prevOpen === "" ? "open" : ""));
  };

  const alert = useContext(AlertContext);

  // useEffect(
  //   (logout) => {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       setUser(response);
  //     })();
  //   },
  //   [auth]
  // );

  // useEffect(() => {
  //   (async () => {
  //     const response = await getProducts();
  //     if (response !== null) {
  //       for (let index = 0; index < response.data.length; index++) {
  //         delete response.data[index].attributes.createdAt;
  //         delete response.data[index].attributes.publishedAt;
  //         delete response.data[index].attributes.updatedAt;
  //         delete response.data[index].attributes.caratula;
  //         delete response.data[index].attributes.carcasa;
  //         delete response.data[index].attributes.condicion;
  //         delete response.data[index].attributes.correa;
  //         delete response.data[index].attributes.descripcion;
  //         delete response.data[index].attributes.eta;
  //         delete response.data[index].attributes.informacionGeneral;
  //         delete response.data[index].attributes.genero.data.attributes
  //           .createdAt;
  //         delete response.data[index].attributes.genero.data.attributes
  //           .publishedAt;
  //         delete response.data[index].attributes.genero.data.attributes
  //           .updatedAt;
  //         delete response.data[index].attributes.marca.data.attributes
  //           .createdAt;
  //         delete response.data[index].attributes.marca.data.attributes
  //           .publishedAt;
  //         delete response.data[index].attributes.marca.data.attributes
  //           .updatedAt;
  //         delete response.data[index].attributes.tipoCorrea.data.attributes
  //           .createdAt;
  //         delete response.data[index].attributes.tipoCorrea.data.attributes
  //           .publishedAt;
  //         delete response.data[index].attributes.tipoCorrea.data.attributes
  //           .updatedAt;
  //       }
  //       setProducts(response.data);
  //     }
  //     // if (query.platform) {
  //     // }
  //   })();
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (user !== undefined) {
  //       const response = await getProductsCart(user?.id, logout);
  //       if (response?.data.length > 0) {
  //         setCartItems(response.data);
  //         // console.log(response);
  //       } else setCartItems([]);
  //     }
  //   })();
  // }, [user]);
  // console.log(cartItems);
  return (
    // <Navbar bg="light" expand="lg">
    //   <Container>
    //     <Navbar.Brand href="#home">e-commerce</Navbar.Brand>
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
    //       <Nav className="mx-auto">
    //         <div className="nav-link">
    //           <Link to="/">Home</Link>
    //         </div>
    //         <div className="nav-link">
    //           <Link to="/catalogue">Catalogue</Link>
    //         </div>
    //         <div className="nav-link">
    //           <Link to="/raffles">Rifas</Link>
    //         </div>
    //         <div className="nav-link">
    //           <Link to="/comments">Comentarios</Link>
    //         </div>
    //         {!user && (
    //           <>
    //             <div className="nav-link">
    //               <Link to="/login">Login</Link>
    //             </div>
    //             <div className="nav-link">
    //               <Link to="/register">Register</Link>
    //             </div>
    //           </>
    //         )}
    //         {user !== undefined && user !== null && (
    //           <>
    //             <p className="m-0 p-2">{user.username}</p>
    //             <div className="nav-link">
    //               <Link to="/cart">Tu carrito de compra</Link>
    //             </div>
    //             <p className="m-0 p-2">{productsCart}</p>
    //             <div className="nav-link">
    //               <Link to="/profile">Perfil</Link>
    //             </div>
    //             <p
    //               onClick={() => {
    //                 logout();
    //                 history.push("/login");
    //               }}
    //               className="m-0 p-2"
    //             >
    //               Cerrar sesión
    //             </p>
    //           </>
    //         )}
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    <header>
      <nav className="navbar">
        <div className="navbar__container">
          <div className="navbar__logo-container">
            <div className="navbar__logo-container__social">
              <div className="d-flex">
                <a
                  href="https://www.instagram.com/larelojeriair/"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className=" fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/La-Relojeria-Original-103481138576106"
                  className="mx-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className=" fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.tiktok.com/@larelojeriaoriginal"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TiktokIcon} alt="La Relojeríair" />
                </a>
              </div>
              {auth.idUser && (
                <Link
                  to="/cart"
                  className="navbar__logo-container__cart d-block d-lg-none"
                >
                  <div className="navbar__link-holder">
                    <img
                      src={CartIcon}
                      alt="Carrito de compras"
                      className="navbar__link__icon"
                    />
                    <div>
                      <p className="cart-qty">{totalProductsCart}</p>
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <div className="text-center">
              <Link to="/" className="navbar__logo-link">
                <img
                  src={Logo}
                  alt="El Mundo del Reloj"
                  className="navbar__logo"
                />
              </Link>
            </div>
            <SearchBar
              data={products}
              placeholder="Ingresa el nombre del reloj"
            />
          </div>
          <div className={`navbar__link-container ${open}`}>
            <Link
              to="/"
              activeclassname="active"
              className="navbar__link"
              onClick={() => toggleOpen()}
            >
              <div className="navbar__link-holder">
                <p className="navbar__link__text">Inicio</p>
              </div>
            </Link>
            <Link
              to="/catalogue"
              className="navbar__link"
              onClick={() => toggleOpen()}
            >
              <div className="navbar__link-holder">
                <p className="navbar__link__text">Todos los relojes</p>
              </div>
            </Link>
            <Link
              to="/bids"
              className="navbar__link"
              onClick={() => toggleOpen()}
            >
              <div className="navbar__link-holder">
                <p className="navbar__link__text">Subastas</p>
              </div>
            </Link>
            <Link
              to="/raffles"
              className="navbar__link"
              onClick={() => toggleOpen()}
            >
              <div className="navbar__link-holder">
                <p className="navbar__link__text">Rifas</p>
              </div>
            </Link>
            <Link
              to="/comments"
              className="navbar__link"
              onClick={() => toggleOpen()}
            >
              <div className="navbar__link-holder">
                <p className="navbar__link__text">Opiniones</p>
              </div>
            </Link>
            {Object.keys(auth).length === 0 && (
              <>
                <Link
                  to="/login"
                  className="navbar__link"
                  onClick={() => toggleOpen()}
                >
                  <div className="navbar__link-holder">
                    <p className="navbar__link__text">Iniciar sesión</p>
                  </div>
                </Link>
                <Link
                  to="/register"
                  className="navbar__link"
                  onClick={() => toggleOpen()}
                >
                  <div className="navbar__link-holder">
                    <p className="navbar__link__text">Registro</p>
                  </div>
                </Link>
              </>
            )}
            {auth.idUser && (
              <>
                {/* <p className="m-0 p-2">{user.username}</p> */}
                <Link
                  to="/cart"
                  className="navbar__link navbar__link--cart d-none d-lg-block"
                >
                  <div className="navbar__link-holder">
                    <img
                      src={CartIcon}
                      alt="Carrito de compras"
                      className="navbar__link__icon"
                    />
                    <div>
                      <p className="cart-qty">{totalProductsCart}</p>
                    </div>
                  </div>
                </Link>
                {/* <p className="m-0 p-2">{productsCart}</p> */}
                <Link
                  to="/profile"
                  className="navbar__link navbar__link--profile"
                  onClick={() => toggleOpen()}
                >
                  <div className="navbar__link-holder">
                    <img
                      src={ProfileIcon}
                      alt="Perfil"
                      className="navbar__link__icon--profile"
                    />
                  </div>
                </Link>
                <p
                  onClick={() => {
                    logout();
                    toggleOpen();
                    alert.success(`Saliste de tu cuenta.`);
                    history.push("/login");
                  }}
                  className="m-0 p-2"
                >
                  Cerrar sesión
                </p>
              </>
            )}
          </div>
        </div>
      </nav>
      <div className="navbar__toggle" onClick={() => toggleOpen()}>
        <div className="navbar__toggle__container">
          <div className="navbar__toggle__button">
            <div className="navbar__toggle__button__bar"></div>
            <div className="navbar__toggle__button__bar my-1"></div>
            <div className="navbar__toggle__button__bar"></div>
          </div>
        </div>
      </div>
      <Alert />
    </header>
  );
}

import { authFetch } from "../utils/fetch";
import { BASE_PATH } from "../utils/constants";

export async function createOrder(formDataTemp, logout) {
  // const {token,
  //   orderId,
  //   clientName,
  //   clientEmail,
  //   clientPhoneNumber,
  //   address,
  //   neighborhood,
  //   postalCode,
  //   reference,
  //   municipality,
  //   city,
  //   state,
  //   country,
  //   total,
  //   products} = data;
  const data = JSON.stringify(formDataTemp);
  try {
    // const addressShipping = address;
    const url = `${BASE_PATH}/api/ordenes`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const result = await authFetch(url, params, logout);
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getUserOrders(userId, logout) {
  try {
    const sortItem = "sort=createdAt:desc";
    // const url = `${BASE_PATH}/api/cart-items?${sortItem}&filters[usuario][id]=${userId}&publicationState=preview&populate[productos][populate]=*`;
    const url = `${BASE_PATH}/api/ordenes?${sortItem}&populate=productos&filters[usuario][id]=${userId}&pagination[limit]=40`;
    const response = await authFetch(url, logout);
    // console.log(response);
    // const result = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getOrder(orderId, logout) {
  try {
    const sortItem = "sort=createdAt:desc";
    // const url = `${BASE_PATH}/api/cart-items?${sortItem}&filters[usuario][id]=${orderId}&publicationState=preview&populate[productos][populate]=*`;
    const url = `${BASE_PATH}/api/ordenes/${orderId}`;
    const response = await authFetch(url, logout);
    // console.log(response);
    // const result = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

import { BASE_PATH } from "../utils/constants";
import { authFetch } from "../utils/fetch";

/*
  // @route    GET /api/rifas?[populate]=*
  // @desc     Get all rifas products
  // @access   Public
*/
export async function getBidsProducts() {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/rifas?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/rifas/ID?[populate]=*
  // @desc     Get a specific rifa product
  // @access   Public
*/
export async function getBidProduct(productId) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/rifas/${productId}?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    PUT /api/rifas/ID
  // @desc     Purchase a ticket
  // @access   Private
*/
export async function postBid(productId, bid, logout) {
  const data = JSON.stringify(bid);
  console.log(bid);
  try {
    const url = `${BASE_PATH}/api/rifas/${productId}`;
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const result = await authFetch(url, params, logout);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/portada-rifa
  // @desc     Get hero background for Rifas page
  // @access   Public
*/
export async function getHeroBids() {
  try {
    const url = `${BASE_PATH}/api/portada-rifa?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

import React, { useState, useEffect } from "react";

// API
import { getRaffleProducts, getHeroRaffles } from "../api/raffle";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Custom Components
import RaffleProduct from "../components/RaffleProduct/RaffleProduct";

// Custom CSS
import "../assets/scss/pages/bids.scss";

export default function Bids() {
  const [products, setProducts] = useState(null);
  const [pageHeroBackground, setPageHeroBackground] = useState({});

  useEffect(() => {
    (async () => {
      const response = await getRaffleProducts();
      setProducts(response.data);

      // if (query.platform) {
      // }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const response = await getHeroRaffles();
      if (response !== null) {
        setPageHeroBackground(response.data);
      } else {
        setPageHeroBackground({});
      }

      // if (query.platform) {
      // }
    })();
  }, []);
  // console.log(products);

  return (
    <div className="bids__wrapper">
      <div
        className="jumbotron"
        style={{
          backgroundImage: `url(${pageHeroBackground?.attributes?.portada.data.attributes.url})`,
        }}
      ></div>
      <div className="bids__header">
        <Container>
          <h3>Subastas</h3>
          <p>
            Te invitamos a que conozcas nuestra eficiente forma de venta express
            en la cual encontrarás los mejores precios de toda la página los
            cuales consistirán en que ciertos relojes serán subastados
            diariamente de manera aleatoria en la página, los cuales podrán ser
            de una a múltiples subastas en el mismo día, estas se regirán por la
            regla general de una puja inicial, seguido de pujas mínimas fijas o
            máximas libres dando al ganador la puja más alta a la hora del
            cierre estipulado en cada subasta publicada.
          </p>
        </Container>
      </div>
      <div className="bids__container">
        <Container>
          <Row>
            {products?.map((product, key) => {
              // console.log(
              //   Math.floor(
              //     (product.attributes?.fechaFinal -
              //       product.attributes?.fechaInicio) /
              //       (1000 * 60 * 60 * 24)
              //   )
              // );

              return (
                // <p>hola</p>
                <RaffleProduct key={key} product={product} />
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
}

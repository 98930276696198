import React, { useState, useRef, useContext } from "react";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";

// Context
import AlertContext from "../context/AlertContext";

// Custom Components
import ReCAPTCHA from "react-google-recaptcha";

// Form validation
import { Formik } from "formik";
import * as Yup from "yup";

// API
import { forgotPassword } from "../api/user";

// Custom CSS
import "../assets/scss/pages/forgotPassword.scss";

const initialValues = {
  email: "",
};

let schema = Yup.object().shape({
  email: Yup.string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
});

function ForgotPassword() {
  // const [error, setError] = useState("");
  // const [success, setSuccess] = useState("");

  const captcha = useRef(null);

  const alert = useContext(AlertContext);
  // const onChange = (value) => {
  //   alert.error("");
  // };
  return (
    <section className="forgot-password__wrapper">
      {/* {error && <Alert variant="error">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>} */}
      <Container className="forgot-password__container">
        <h2>Recuperación de contraseña</h2>
        <p>
          Para recuperar tu contraseña ingresa a continuación el correo
          electrónico de tu registro:
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!captcha.current.getValue()) {
              // setError("Por favor completa el captcha.");
              alert.error("Por favor completa el captcha.");
              // setTimeout(() => {
              //   setError("");
              // }, 3000);
              return;
            } else {
              const response = await forgotPassword(values);
              if (response.error?.status === 403) {
                alert.error(
                  "Error en la solicitud. Por favor intenta de nuevo"
                );
                // setError("Error en la solicitud. Por favor intenta de nuevo");
                // setTimeout(() => {
                //   setError("");
                // }, 3000);
                resetForm({
                  email: "",
                });
                captcha.current.reset();
              } else if (response.error?.status === 400) {
                alert.error(
                  "No se reconoce el email enviado. Por favor verifica que sea el correcto."
                );
                // setError(
                //   "No se reconoce el email enviado. Por favor verifica que sea el correcto."
                // );
                // setTimeout(() => {
                //   setError("");
                // }, 3000);
                resetForm({
                  email: "",
                });
                captcha.current.reset();
              }
              if (response.ok === true) {
                alert.success(
                  "Un email ha sido enviado a tu correo. Por favor verifica la bandeja de correo no deseado."
                );
                // setSuccess(
                //   "Un email ha sido enviado a tu correo. Por favor verifica la bandeja de correo no deseado."
                // );
                // setTimeout(() => {
                //   setSuccess("");
                // }, 4000);
                resetForm({
                  email: "",
                });
                captcha.current.reset();
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Escribe tu correo electrónico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={errors.email}
                  />
                  <div className="error-feedback">
                    {touched.email && errors.email}
                  </div>
                </Form.Group>
              </Row>
              <div className="re-captcha mb-lg-3">
                <ReCAPTCHA
                  sitekey="6LegBp4iAAAAAGgvhlMDD9MWz0ehp97HW4zecXNl"
                  // onChange={onChange}
                  ref={captcha}
                />
              </div>
              <Button
                variant="primary"
                type="submit"
                className=""
                disabled={!isValid || isSubmitting}
              >
                {!isSubmitting && "Enviar link"}
                {isSubmitting && "Enviando correo..."}
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </section>
  );
}

export default ForgotPassword;

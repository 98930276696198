import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

// Bootstrap Components
import Row from "react-bootstrap/Row";

// Context
import { useProducts } from "../context/ProductsContext";

// API
import { getGenreProducts } from "../api/product";
import { getWatchBands } from "../api/watch-band";
import { getWatchGenres, getGenre } from "../api/genre";

// Custom Components
import ProductCard from "../components/ProductCard/ProductCard";

// Custom styles
import "../assets/scss/pages/genre.scss";

// Util
import { toggleClass } from "../utils/toggleClass";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";

export default function Genre() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [genre, setGenre] = useState(null);

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      marca: {
        Bulova: false,
        Citizen: false,
        IWC: false,
        Longines: false,
        Mido: false,
        Omega: false,
        "Porsche Design": false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
    },
  };

  const [watchBands, setWatchBands] = useState([]);
  const [watchGenres, setWatchGenres] = useState([]);
  const [testinState, setTestingState] = useState(state);
  const [categories, setCategories] = useState([]);
  const { brands } = useProducts();

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getGenreProducts(slug);
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
          delete response.data[index].attributes.caratula;
          delete response.data[index].attributes.carcasa;
          delete response.data[index].attributes.condicion;
          delete response.data[index].attributes.correa;
          delete response.data[index].attributes.descripcion;
          delete response.data[index].attributes.eta;
          delete response.data[index].attributes.informacionGeneral;
          delete response.data[index].attributes.genero.data.attributes
            .createdAt;
          delete response.data[index].attributes.genero.data.attributes
            .publishedAt;
          delete response.data[index].attributes.genero.data.attributes
            .updatedAt;
          delete response.data[index].attributes.marca.data.attributes
            .createdAt;
          delete response.data[index].attributes.marca.data.attributes
            .publishedAt;
          delete response.data[index].attributes.marca.data.attributes
            .updatedAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .createdAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .publishedAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .updatedAt;
        }
        setProducts(response);
      }
      setLoading(false);
      // if (query.platform) {
      // }
    })(slug);
  }, [slug]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getGenre(slug);
      if (response !== null) {
        // for (let index = 0; index < response.data.length; index++) {
        //   delete response.data[index].attributes.createdAt;
        //   delete response.data[index].attributes.publishedAt;
        //   delete response.data[index].attributes.updatedAt;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .alternativeText;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .caption;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .createdAt;
        //   delete response.data[index].attributes.portada.data.attributes.ext;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .formats;
        //   delete response.data[index].attributes.portada.data.attributes.hash;
        //   delete response.data[index].attributes.portada.data.attributes.height;
        //   delete response.data[index].attributes.portada.data.attributes.mime;
        //   delete response.data[index].attributes.portada.data.attributes.name;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .previewUrl;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .provider;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .provider_metadata;
        //   delete response.data[index].attributes.portada.data.attributes.size;
        //   delete response.data[index].attributes.portada.data.attributes
        //     .updatedAt;
        //   delete response.data[index].attributes.portada.data.attributes.width;
        // }
        setGenre(response.data[0].attributes);
      }
      // if (query.platform) {
      // }
    })(slug);
  }, [slug]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getWatchBands();
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
        }
        setWatchBands(response);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getWatchGenres();
      if (response !== null) {
        setWatchGenres(response);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [slug]);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      marca: [],
      tipo: [],
    };
    const { calibre, genero, correa, marca, tipo, tipoCorrea } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let marcaKey in marca) {
      if (marca[marcaKey]) collectedTrueKeys.marca.push(marcaKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    return collectedTrueKeys;
  };

  const multiPropsFilter = (products, filters) => {
    const filterKeys = Object.keys(filters);
    return products?.data.filter((product) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) return true;
        return filters[key].includes(
          product?.attributes[key].data.attributes.nombre
        );
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(products, filteredCollected());
    return filteredProducts2;
  };

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(".filters");

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };
  useEffect(() => {
    initDropdownToggle();
  }, []);

  // console.log(genre);
  // if (loading) {
  //   <section className="brand-catalogue">
  //     <Container>
  //       <p>Cargando...</p>
  //     </Container>
  //   </section>;
  // }
  return (
    <section className="genre-catalogue">
      <section className="products col-12">
        <div className="products__container">
          <div
            className="products__genre__header"
            style={{
              backgroundImage: `linear-gradient(
                   rgba(0, 0, 0, 0.53),
                   rgba(0, 0, 0, 0.275)
                 ), url(${genre?.portada.data.attributes.url})`,
            }}
          >
            <h3>{genre?.nombre}</h3>
          </div>
          <div className="products__product-container">
            {/* {!loading && data.allProductos?.length > 0 ? (
              data.allProductos.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))
            ) : (
              <p className="products__emtpy">Cargando productos...</p>
            )} */}
            <Row>
              <div className="filters col-12 col-lg-3">
                <div className="filters__container">
                  <div className="filters__header">
                    <img
                      src={FilterIcon}
                      alt="Filtros"
                      className="filters__icon"
                    />
                    <p className="filters__title">Filtros</p>
                  </div>
                  <div className="filters__filter-container">
                    <div
                      className="filters__filter filters__filter--clear filter__item"
                      data-filter="all"
                    >
                      <div className="filters__filter__content">
                        <p
                          className="filters__filter__name filters__filter__name--small"
                          onClick={() => {
                            const selectedFilter = document.querySelectorAll(
                              ".filters__filter__dropdown-menu__item__name"
                            );
                            selectedFilter.forEach((filter) =>
                              filter.classList.remove("active")
                            );
                            setCategories([]);
                            setTestingState(state);
                          }}
                        >
                          Limpiar filtros <i className="far fa-times"></i>
                        </p>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Correa</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {watchBands.data?.map((watchBand, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={watchBand.attributes.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  allFilterClickListener(
                                    watchBand.attributes.nombre,
                                    "tipoCorrea"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");
                                  if (
                                    categories.includes(
                                      watchBand.attributes.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          watchBand.attributes.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      watchBand.attributes.nombre.toLowerCase(),
                                    ]);
                                  }
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {watchBand.attributes.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Marca</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            {brands.data?.map((brand, key) => (
                              <li
                                className="filters__filter__dropdown-menu__item filter__item"
                                data-filter={brand.attributes.nombre.toLowerCase()}
                                key={key}
                                onClick={(e) => {
                                  // console.log(e.target);
                                  allFilterClickListener(
                                    brand.attributes.nombre,
                                    // .toLowerCase()
                                    // .split(" ")
                                    // .join("_"),
                                    "marca"
                                  );
                                  const filtersDropdownMenu =
                                    document.querySelector(
                                      ".filters__filter-container"
                                    );
                                  filtersDropdownMenu.classList.remove("show");
                                  e.target.classList.toggle("active");

                                  if (
                                    categories.includes(
                                      brand.attributes.nombre.toLowerCase()
                                    )
                                  ) {
                                    setCategories(
                                      categories.filter(
                                        (category) =>
                                          category !==
                                          brand.attributes.nombre.toLowerCase()
                                      )
                                    );
                                  } else {
                                    setCategories([
                                      ...categories,
                                      brand.attributes.nombre.toLowerCase(),
                                    ]);
                                  }

                                  // if (
                                  //   filteredProducts.length === 0 &&
                                  //   category === ""
                                  // ) {
                                  //   filterBrandFunction(brand.attributes.nombre);
                                  //   setCategory(brand.attributes.nombre);
                                  // } else {
                                  //   filterGenreFunction([]);
                                  //   setCategory("");
                                  // }
                                  // console.log(
                                  //   products?.filter(
                                  //     (product) =>
                                  //       product.attributes.tipoCorrea.data.attributes
                                  //         .nombre === brand.attributes.nombre
                                  //   )
                                  // );
                                }}
                              >
                                <p className="filters__filter__dropdown-menu__item__name">
                                  {brand.attributes.nombre}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Tipo</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter="japones"
                              onClick={(e) => {
                                allFilterClickListener("Japonés", "tipo");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                if (categories.includes("japonés")) {
                                  setCategories(
                                    categories.filter(
                                      (category) => category !== "japonés"
                                    )
                                  );
                                } else {
                                  setCategories([...categories, "japonés"]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                Japonés
                              </p>
                            </li>
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter="suizo"
                              onClick={(e) => {
                                allFilterClickListener("Suizo", "tipo");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                if (categories.includes("suizo")) {
                                  setCategories(
                                    categories.filter(
                                      (category) => category !== "suizo"
                                    )
                                  );
                                } else {
                                  setCategories([...categories, "suizo"]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                Suizo
                              </p>
                            </li>
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter="vintage"
                              onClick={(e) => {
                                allFilterClickListener("Vintage", "tipo");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                if (categories.includes("vintage")) {
                                  setCategories(
                                    categories.filter(
                                      (category) => category !== "vintage"
                                    )
                                  );
                                } else {
                                  setCategories([...categories, "vintage"]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                Vintage
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filters__filter filters__filter--dropdown">
                      <div className="filters__filter__content">
                        <p className="filters__filter__name">Calibre</p>
                        <div className="filters__filter__dropdown-menu">
                          <ul className="filters__filter__dropdown-menu__item-container">
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter="automático"
                              onClick={(e) => {
                                allFilterClickListener("Automático", "calibre");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                if (categories.includes("automático")) {
                                  setCategories(
                                    categories.filter(
                                      (category) => category !== "automático"
                                    )
                                  );
                                } else {
                                  setCategories([...categories, "automático"]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                Automático
                              </p>
                            </li>
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter="cuarzo"
                              onClick={(e) => {
                                allFilterClickListener("Cuarzo", "calibre");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                if (categories.includes("cuarzo")) {
                                  setCategories(
                                    categories.filter(
                                      (category) => category !== "cuarzo"
                                    )
                                  );
                                } else {
                                  setCategories([...categories, "cuarzo"]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                Cuarzo
                              </p>
                            </li>
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter="cuerda"
                              onClick={(e) => {
                                allFilterClickListener("Cuerda", "calibre");
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                if (categories.includes("cuerda")) {
                                  setCategories(
                                    categories.filter(
                                      (category) => category !== "cuerda"
                                    )
                                  );
                                } else {
                                  setCategories([...categories, "cuerda"]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                Cuerda
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brand-catalogue__products col-12 col-lg-9">
                <Row>
                  {loading ? (
                    <p className="pt-3">Cargando productos...</p>
                  ) : products?.data.length > 0 && categories.length === 0 ? (
                    products?.data.map((product, key) => (
                      <ProductCard
                        product={product}
                        key={key}
                        loading={loading}
                      />
                    ))
                  ) : null}

                  {!loading && categories.length > 0
                    ? searchProducts().map((product, key) => (
                        <ProductCard
                          product={product}
                          key={key}
                          loading={loading}
                        />
                      ))
                    : null}
                  {!loading &&
                  categories.length > 0 &&
                  searchProducts().length === 0 ? (
                    <p className="py-3">
                      No se encontraron relojes con los filtros seleccionados.
                    </p>
                  ) : null}
                </Row>
              </div>
            </Row>
          </div>
        </div>
      </section>
    </section>
  );
}

import React, { useState, useEffect } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// API
import { getBidsProducts, getHeroBids } from "../api/bids";

// Custom Components
import BidProduct from "../components/BidProduct/BidProduct";

// Custom CSS
import "../assets/scss/pages/raffles.scss";

function Raffles() {
  const [products, setProducts] = useState(null);
  const [pageHeroBackground, setPageHeroBackground] = useState({});

  useEffect(() => {
    (() => {
      (async () => {
        const response = await getBidsProducts();
        setProducts(response.data);

        // if (query.platform) {
        // }
      })();
    })();
  }, []);

  useEffect(() => {
    (() => {
      (async () => {
        const response = await getHeroBids();
        if (response !== null) {
          setPageHeroBackground(response.data);
        } else {
          setPageHeroBackground({});
        }
        // if (query.platform) {
        // }
      })();
    })();
  }, []);
  return (
    <section className="raffles__wrapper">
      <div
        className="jumbotron"
        style={{
          backgroundImage: `url(${pageHeroBackground?.attributes?.portada.data.attributes.url})`,
        }}
      ></div>
      <div className="raffles__header">
        <Container>
          <h3>Rifas</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem iure,
            delectus quis minima voluptas dolore consequuntur inventore
            molestias obcaecati iste illum voluptates, dolor pariatur ab esse
            velit? Velit vitae quasi, rerum veniam est excepturi nisi ea
            asperiores dolorum culpa ratione.
          </p>
        </Container>
      </div>
      <div className="raffles__container">
        <Container>
          <Row>
            {products?.map((product, key) => (
              <BidProduct key={key} product={product} />
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Raffles;

// import { createContext } from "react";

// const CartContext = createContext({
//   productsCart: 0,
//   total: 0,
//   addProductCart: () => null,
//   decreaseProductCart: () => null,
//   getProductsCart: () => null,
//   increaseProductCart: () => null,
//   removeAllProductsCart: () => null,
//   removeProductCart: () => null,
// });

// export default CartContext;

import { createContext, useContext, useState, useEffect } from "react";

// API
import { setToken, getToken, removeToken } from "../api/token";
import {
  addProductCart,
  countProductsCart,
  decreaseProductCart,
  deleteCartItemApi,
  getProductsCart,
  getTotalCartPrice,
  increaseProductCart,
  removeAllProductsCart,
} from "../api/cart";

// Context
import { useAuth } from "./AuthContext";

export const CartContext = createContext();

export const useCart = () => {
  const context = useContext(CartContext);
  return context;
};

export function CartProvider({ children }) {
  const [totalProductsCart, setTotalProductsCart] = useState(0);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [reloadCart, setReloadCart] = useState(false);

  const { auth, logout } = useAuth();

  const addProduct = async (product, quantity, auth) => {
    // setLoading(true);
    setReloadCart(true);
    console.log(product);
    const token = getToken();
    if (token) {
      try {
        const response = await addProductCart(product, quantity, auth);
        console.log(response);
        if (response.data) {
          if (
            cartItems.find((item) => item.id === response.data.id) !== undefined
          ) {
            console.log("ya estaba ");
            let updatedCartItems = cartItems.map((item) => {
              if (item.id === response.data.id) {
                return {
                  ...item,
                  attributes: {
                    ...item.attributes,
                    cantidad: item.attributes.cantidad + quantity,
                  },
                };
              }
              return item;
            });
            setCartItems(updatedCartItems);
          } else {
            console.log("primero");
            setCartItems([
              ...cartItems,
              {
                id: response.data.id,
                attributes: {
                  cantidad: quantity,
                  productos: { data: [product] },
                },
              },
            ]);
          }

          return response;
        }
      } catch (error) {
        console.log(error);
      }
      setReloadCart(false);
      // if (response.data) {
      // } else {
      //   setReloadCart(false);
      // }
      // setLoading(false);
    } else {
      console.error("Para comprar un producto tienes que iniciar sesión");
      setReloadCart(false);
    }
  };

  const increaseProduct = async (
    userId,
    quantity,
    product,
    productId,
    auth
  ) => {
    // console.log("hola");
    setReloadCart(true);
    try {
      const response = await increaseProductCart(
        userId,
        quantity,
        product,
        productId,
        auth
      );
      var foundIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === response.data.id
      );
      cartItems[foundIndex].attributes.cantidad = quantity;
      setCartItems(cartItems);
      setTotalProductsCart(
        cartItems.reduce(
          (total, product) => total + product.attributes.cantidad,
          0
        )
      );
      setTotalPriceCart(
        cartItems.reduce(function (tally, cartItem) {
          return (
            tally +
            cartItem?.attributes.cantidad *
              cartItem?.attributes.productos.data[0]?.attributes.precio
          );
        }, 0)
      );
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
  };
  const decreaseProduct = async (
    userId,
    quantity,
    product,
    productId,
    auth
  ) => {
    // console.log("hola");
    setReloadCart(true);
    try {
      const response = await decreaseProductCart(
        userId,
        quantity,
        product,
        productId,
        auth
      );
      var foundIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === response.data.id
      );
      cartItems[foundIndex].attributes.cantidad = quantity;
      setCartItems(cartItems);
      setTotalProductsCart(
        cartItems.reduce(
          (total, product) => total + product.attributes.cantidad,
          0
        )
      );
      setTotalPriceCart(
        cartItems.reduce(function (tally, cartItem) {
          return (
            tally +
            cartItem?.attributes.cantidad *
              cartItem?.attributes.productos.data[0]?.attributes.precio
          );
        }, 0)
      );
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
  };

  const removeProduct = async (userId, productId, auth) => {
    // console.log("hola");
    setReloadCart(true);

    try {
      const response = await deleteCartItemApi(userId, productId, auth);
      if (response.data) {
        // setCartItems((prev) =>
        //   prev.filter((item) => {
        //     return item.id !== productId;
        //   })
        // );
        setTotalProductsCart(
          cartItems.reduce(
            (total, product) => total + product.attributes.cantidad,
            0
          )
        );
        setTotalPriceCart(
          cartItems.reduce(function (tally, cartItem) {
            return (
              tally +
              cartItem?.attributes.cantidad *
                cartItem?.attributes.productos.data[0]?.attributes.precio
            );
          }, 0)
        );
      }
      console.log(response);
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
    setReloadCart(false);
    // if (response.data) {
    // } else {
    //   setReloadCart(false);
    //   console.log(response);
    // }
    // try {
    // } catch (error) {
    //   console.log(error);
    // }
    // const token = getToken();
    // if (token) {
    // } else {
    //   console.error("Para comprar un producto tienes que iniciar sesión");
    // }
  };

  const resetCart = (cartId, auth) => {
    // console.log("hola");
    // setTotalProductsCart(0);
    // setTotalPriceCart(0);
    removeAllProductsCart(cartId, auth);
    setCartItems([]);
    // setReloadCart(true);
  };

  useEffect(() => {
    if (auth.idUser) {
      const getUserCart = async () => {
        setReloadCart(true);
        // setCartItems(await getProductsCart(auth?.idUser, logout));
        const response = await getProductsCart(auth?.idUser, logout);
        if (response?.data.length > 0) {
          for (let index = 0; index < response.data.length; index++) {
            delete response.data[index].attributes.createdAt;
            delete response.data[index].attributes.updatedAt;
            delete response.data[index].attributes.publishedAt;
            delete response.data[index].attributes.productos.data[0].attributes
              .createdAt;
            delete response.data[index].attributes.productos.data[0].attributes
              .caratula;
            delete response.data[index].attributes.productos.data[0].attributes
              .carcasa;
            delete response.data[index].attributes.productos.data[0].attributes
              .condicion;
            delete response.data[index].attributes.productos.data[0].attributes
              .correa;
            delete response.data[index].attributes.productos.data[0].attributes
              .descripcion;
            delete response.data[index].attributes.productos.data[0].attributes
              .informacionGeneral;
            delete response.data[index].attributes.productos.data[0].attributes
              .publishedAt;
            delete response.data[index].attributes.productos.data[0].attributes
              .updatedAt;
          }
          setCartItems(response.data);
          setTotalProductsCart(
            response.data.reduce(
              (total, product) => total + product.attributes.cantidad,
              0
            )
          );
          setTotalPriceCart(
            response.data.reduce(function (tally, cartItem) {
              return (
                tally +
                cartItem?.attributes.cantidad *
                  cartItem?.attributes.productos.data[0]?.attributes.precio
              );
            }, 0)
          );
          setReloadCart(false);
          // console.log(response);
        } else {
          setCartItems([]);
          setReloadCart(false);
        }
      };
      getUserCart();
    }
  }, [auth]);

  // useEffect(() => {
  //   if (auth.idUser) {
  //     // (async () => {
  //     //   setTotalProductsCart(await countProductsCart(auth?.idUser, logout));
  //     // })();
  //     setTotalProductsCart(
  //       cartItems.reduce(
  //         (total, product) => total + product.attributes.cantidad,
  //         0
  //       )
  //     );
  //   }
  // }, []);

  useEffect(() => {
    // if (auth.idUser) {
    //   // (async () => {
    //   //   setTotalPriceCart(await getTotalCartPrice(auth?.idUser, logout));
    //   // })();
    //   setTotalPriceCart(
    //     cartItems.reduce(function (tally, cartItem) {
    //       return (
    //         tally +
    //         cartItem?.attributes.cantidad *
    //           cartItem?.attributes.productos.data[0]?.attributes.precio
    //       );
    //     }, 0)
    //   );
    // }
    setTotalProductsCart(
      cartItems.reduce(
        (total, product) => total + product.attributes.cantidad,
        0
      )
    );
    setTotalPriceCart(
      cartItems.reduce(function (tally, cartItem) {
        return (
          tally +
          cartItem?.attributes?.cantidad *
            cartItem?.attributes?.productos?.data[0]?.attributes?.precio
        );
      }, 0)
    );
    if (cartItems.length > 0) {
    } else {
      setTotalPriceCart(0);
    }
  }, [cartItems]);

  return (
    <CartContext.Provider
      value={{
        totalProductsCart,
        totalPriceCart,
        cartItems,
        setCartItems,
        addProduct,
        increaseProduct,
        decreaseProduct,
        removeProduct,
        resetCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

import React from "react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";

import "./shareButton.styles.scss";

const title = "¡¡Descubre este reloj en La Relojeriair!!";

export function FacebookShare({ url }) {
  const shareUrl = url;
  return (
    <FacebookShareButton url={shareUrl} quote={title} className="button-share">
      <FacebookIcon size={38} round />
    </FacebookShareButton>
  );
}
export function WhatsAppShare({ url }) {
  return (
    <WhatsappShareButton url={url} title={title} className="button-share">
      <WhatsappIcon size={38} round />
    </WhatsappShareButton>
  );
}

import { BASE_PATH } from "../utils/constants";

export async function getWatchGenres() {
  try {
    const sortItem = "sort=createdAt:asc";
    const url = `${BASE_PATH}/api/generos?${sortItem}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/generos?[populate]=*&filters[slug][$eq]=${genreName}
  // @desc     Get information from a specific genre
  // @access   Public
*/
export async function getGenre(genreName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/generos?[populate]=*&filters[slug][$eq]=${genreName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/condiciones?[populate]=*&filters[slug][$eq]=${condition}
  // @desc     Get information from a specific genre
  // @access   Public
*/
export async function getCondition(condition) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/condiciones?[populate]=*&filters[slug][$eq]=${condition}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

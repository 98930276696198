import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Form validation
// import { Formik } from "formik";
// import * as yup from "yup";

// Custom components
import Timer from "react-compound-timer";
import moment from "moment-timezone";
// import { ProductCarousel } from "../Carousel/Carousel";
import { Carousel } from "react-responsive-carousel";

// Custom CSS
import "./bidProduct.styles.scss";

// API
import { getMeApi } from "../../api/user";
import { postBid } from "../../api/raffle";
import useAuth from "../../hooks/useAuth";

// Utils
import { BASE_PATH } from "../../utils/constants";
import formatMoney from "../../utils/formatMoney";
import useForm from "../../utils/useForm";
import useCart from "../../hooks/useCart";

// Media
import nationalLottery from "../../assets/images/national-lottery.png";

export default function BidProduct({ product }) {
  const timer = useRef();

  // const counterValue = () => {
  //   const dateFromApi = product.attributes?.fechaFinal;
  //   const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
  //   const added = createdAt.clone();
  //   const now = moment.tz("America/Mexico_City");
  //   const duration = added.diff(now, "milliseconds");
  //   return duration;
  // };
  // console.log(product);

  const [count, setCount] = useState(product?.attributes.pujaInicial);

  // useEffect(() => {
  //   if (bids?.length === 0) {
  //     setCount(product?.attributes.pujaInicial);
  //   } else {
  //     setCount(
  //       bids[bids?.length - 1]?.bid + product?.attributes.pujaSubsecuente
  //     );
  //   }
  // }, []);

  // console.log(timer?.current?.getTime());

  return (
    <Col xs="6" lg="3" className="raffle-product-card bid-product-card">
      <div className="raffle-product-card__media">
        <Link
          to={`/bid-product/${product.id}`}
          className="raffle-product-card__media__cta"
        >
          {new Date(`${product?.attributes.fechaFinal}`) > new Date() ? "Comprar boleto": "Rifa cerrada"}
        </Link>
        <Carousel showStatus={false} showIndicators={false}>
          {product.attributes?.imagenes.data?.map((image, key) => (
            <Link
              to={`/bid-product/${product.id}`}
              key={key}
              style={{ display: "block", height: "100%", width: "100%" }}
            >
              <img
                src={image.attributes.url}
                // src={`${BASE_PATH}${image.attributes.url}`}
                alt={product.attributes?.nombreProducto}
              />
            </Link>
          ))}
        </Carousel>
      </div>
      <div className="raffle-product-card__body">
        <Link to={`/bid-product/${product.id}`}>
          <h5>{product.attributes?.nombreProducto}</h5>
        </Link>
        {/* <p className="raffle-product-card__timer">
          {counterValue() < 0 ? (
            "La rifa ha finalizado"
          ) : (
            <>
              <span>La rifa termina en:</span>
              <Timer
                initialTime={counterValue()}
                direction="backward"
                ref={timer}
              >
                {() => (
                  <>
                    <Timer.Days /> días <Timer.Hours /> horas <Timer.Minutes />{" "}
                    minutos <Timer.Seconds /> segundos{" "}
                  </>
                )}
              </Timer>
            </>
          )}
        </p> */}
        <span>Precio del boleto:</span>
        <h6>{formatMoney(product.attributes?.precioBoleto)} MXN</h6>
        <span>Precio del reloj:</span>
        <h6>
          {formatMoney(product.attributes?.precio)} MXN <span>+ IVA</span>
        </h6>
        <div className="raffle-product-card__national-lottery">
          {product.attributes?.linkLoteriaNacional === null ||
          product.attributes?.linkLoteriaNacional === "" ? (
            <p className="national-lottery-link text-center">
              <img src={nationalLottery} alt="La Relojeríair" /> Espera el link
              del sitio
            </p>
          ) : (
            <a
              href="https://www.youtube.com/c/Loter%C3%ADaNacional-SorteosTradicionales/featured"
              target="_blank"
              rel="noreferrer"
              className="national-lottery-link"
            >
              <img src={nationalLottery} alt="La Relojeríair" />
              Link directo a Lotería Nacional
            </a>
          )}
        </div>

        {/* <p className="winner-text">
          El ganador favor de comunicarse por{" "}
          <a href="">
            <i className=" fab fa-whatsapp"></i> WhatsApp
          </a>
        </p> */}
        {new Date(`${product?.attributes.fechaFinal}`) > new Date() ? <h4 className="text-center my-2">
          Boletos restantes:{" "}
          {product.attributes?.boletosTotales -
            product.attributes?.boletosPagados}
        </h4> : <></> }
       
        {/* <h4>Pujas:</h4>
        <div className="raffle-product-card__bids__container">
          {bids?.map((bid, key) => (
            <div key={key} className="raffle-product-card__bids">
              <div className="d-flex justify-content-between w-100">
                <p>
                  <span>Nombre: </span>
                  {bid.usuario}
                </p>
                <p className="mt-2 mt-lg-0">
                  <span>Puja: </span>
                  {formatMoney(bid.bid)} MXN
                </p>
              </div>
            </div>
          ))}
        </div> */}
        {/* <div className="raffle-product-card__bids__input">
          {!user && (
            <p>
              Debes <Link to="/login">iniciar sesión</Link> o{" "}
              <Link to="/register">registrarte</Link> para publicar una puja.
            </p>
          )}
          {user && timer.current?.getTime() > 0 ? (
            <Form onSubmit={handleSubmit} id="bid-form">
              <Row>
                <Form.Group className="mb-1" controlId="formName">
                  <Form.Label>Nombre de usuario:</Form.Label>

                  <Form.Control
                    name="user"
                    plaintext
                    defaultValue={user?.username}
                    readOnly
                  />
                  <Form.Text className="text-muted">
                    Este es tu nombre de usuario
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-2" controlId="formBid">
                  <Form.Label>Oferta:</Form.Label>
                  <div className="bid-container">
                    <button
                      type="button"
                      className="counter-action"
                      disabled={
                        count <=
                        bids[bids.length - 1]?.bid +
                          product?.attributes.pujaSubsecuente
                      }
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                    <h5 className="form-control">{formatMoney(count)}</h5>
                    <button
                      type="button"
                      className="counter-action"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                </Form.Group>
              </Row>
              <Button className="send" type="submit">
                Enviar puja
              </Button>
            </Form>
          ) : (
            <></>
          )}
          {timer.current?.getTime() < 0 && <p>La subasta ha finalizado</p>}
        </div> */}
      </div>
    </Col>
  );
}

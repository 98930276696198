import { size, remove } from "lodash";
import { authFetch } from "../utils/fetch";
import { CART, BASE_PATH } from "../utils/constants";

export async function isCartItemApi(userId, productId, logout) {
  // &usuario=7&productos=4
  try {
    const url = `${BASE_PATH}/api/cart-items?filters[usuario][id]=${userId}&populate[productos][populate]=*&publicationState=preview&filters[productos][id]=${productId}`;
    return await authFetch(url, null, logout);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getProductsCart(userId, logout) {
  // if (!cart) {
  //   return null;
  // } else {
  //   const products = cart.split(",");
  //   return products;
  // }
  try {
    const sortItem = "sort=createdAt:asc";
    // const url = `${process.env.REACT_APP_API_URL}/api/comentarios?${sortItem}&populate=usuario`;
    const url = `${BASE_PATH}/api/cart-items?${sortItem}&filters[usuario][id]=${userId}&publicationState=preview&populate[productos][populate]=*`;
    const response = await authFetch(url, logout);
    // console.log(response);
    // const result = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function addProductCart(product, quantity, logout) {
  // delete product.attributes.createdAt;
  // delete product.attributes.updatedAt;
  // delete product.attributes.publishedAt;
  const { id: productId } = product;
  // console.log(product, "ADD PRODUCT CART");
  const productData = {
    data: {
      productos: [productId],
      cantidad: quantity,
      usuario: logout.idUser,
    },
  };

  // console.log(logout, "user");
  try {
    const dataFound = await isCartItemApi(logout.idUser, productId, logout);
    console.log(dataFound.data);
    if (size(dataFound.data) > 0 || !dataFound) {
      if (
        dataFound.data[0].attributes.productos.data[0].attributes.stock >=
          quantity &&
        dataFound.data[0].attributes.cantidad + quantity <=
          dataFound.data[0].attributes.productos.data[0].attributes.stock
      ) {
        const url = `${BASE_PATH}/api/cart-items/${dataFound.data[0].id}`;
        const params = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              productos: [productId],
              cantidad: quantity + dataFound.data[0].attributes.cantidad,
              usuario: logout.idUser,
            },
          }),
        };
        const result = await authFetch(url, params, logout);
        // console.log("Producto añadido al carrito");
        // TO-DO: ALERT CONTEXT
        console.log("Es menor y se agregó");
        return result;
      } else {
        // TO-DO: ALERT CONTEXT
        console.log("no se agregó");
      }
    } else {
      const data = JSON.stringify(productData);
      const url = `${BASE_PATH}/api/cart-items`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      };
      const result = await authFetch(url, params, logout);
      // console.log("Producto añadido al carrito");
      return result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
  // const cart = getProductsCart();
  // if (!cart) {
  //   // localStorage.setItem(CART, JSON.stringify(product));
  //   const productData = {
  //     data: { productos: productId, cantidad: 3, usuario: 6 },
  //   };
  //   const data = JSON.stringify(productData);
  //   console.log(data);
  // } else {
  //   console.log("Este producto ya esta en el carrito");
  // const productFound = includes(cart, JSON.stringify(product));
  // if (productFound) {
  //   console.log("Este producto ya esta en el carrito");
  // } else {
  //   cart.push(product);
  //   localStorage.setItem(CART, cart);
  //   console.log("Producto añadido correctamente");
  // }
  // }
}

export async function increaseProductCart(userId, quantity, productId, logout) {
  const productData = {
    data: {
      productos: [productId],
      cantidad: quantity,
      usuario: logout.id,
    },
  };
  const data = JSON.stringify(productData);

  try {
    const dataFound = await isCartItemApi(userId, productId, logout);
    if (size(dataFound.data) > 0) {
      const url = `${BASE_PATH}/api/cart-items/${dataFound.data[0]?.id}`;
      const params = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      };
      const result = authFetch(url, params, logout);
      return result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}
export async function decreaseProductCart(userId, quantity, productId, logout) {
  const productData = {
    data: {
      productos: [productId],
      cantidad: quantity,
      usuario: logout.id,
    },
  };
  const data = JSON.stringify(productData);

  try {
    const dataFound = await isCartItemApi(userId, productId, logout);
    if (size(dataFound.data) > 0) {
      const url = `${BASE_PATH}/api/cart-items/${dataFound.data[0]?.id}`;
      const params = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      };
      const result = authFetch(url, params, logout);
      return result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getTotalCartPrice(userId, logout) {
  try {
    const cart = await getProductsCart(userId, logout);
    if (!cart.data) {
      return 0;
    } else {
      // console.log(size(cart.data));
      const amount = cart.data.reduce(function (tally, cartItem) {
        // console.log(cartItem);
        return (
          tally +
          cartItem?.attributes.cantidad *
            cartItem?.attributes.productos.data[0]?.attributes.precio
        );
      }, 0);
      // console.log(amount);
      return amount;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function countProductsCart(userId, logout) {
  try {
    const cart = await getProductsCart(userId, logout);
    if (!cart.data) {
      return 0;
    } else {
      const itemCount = cart.data.reduce(
        (total, product) => total + product.attributes.cantidad,
        0
      );
      // console.log(itemCount, "itemCount");
      return itemCount;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteCartItemApi(userId, productId, logout) {
  try {
    const dataFound = await isCartItemApi(userId, productId, logout);
    if (size(dataFound.data) > 0) {
      const url = `${BASE_PATH}/api/cart-items/${dataFound.data[0]?.id}`;
      const params = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const result = authFetch(url, params, logout);
      return result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function removeProductCart(product) {
  const cart = getProductsCart();

  remove(cart, (item) => {
    return item === product;
  });

  if (size(cart) > 0) {
    localStorage.setItem(CART, cart);
  } else {
    localStorage.removeItem(CART);
  }
}

export async function paymentCartApi(token, products, idUser, address, logout) {
  try {
    const addressShipping = address;
    delete addressShipping.user;
    delete addressShipping.createdAt;

    const url = `${BASE_PATH}/orders`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        products,
        idUser,
        addressShipping,
      }),
    };
    const result = await authFetch(url, params, logout);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function removeAllProductsCart(cartId, logout) {
  try {
    const url = `${BASE_PATH}/api/cart-items/${cartId}`;
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const result = authFetch(url, params, logout);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

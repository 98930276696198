import React, { useState } from "react";

import { Link } from "react-router-dom";

import formatMoney from "../../utils/formatMoney";

// Custom styles
import "./searchbar.styles.scss";

function SearchBar({ placeholder, data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  // console.log(wordEntered);
  // console.log(filteredData);

  // console.log("data", data);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    let splitArray = searchWord.toLowerCase().split(" ");
    setWordEntered(searchWord);
    // console.log(splitArray);

    // const newFilter = data.filter((value) => {
    //   // console.log(
    //   //   value.attributes.nombre.toLowerCase().includes(searchWord.toLowerCase())
    //   // );
    //   console.log(value);
    //   console.log(
    //     value.attributes.nombre.toLowerCase().search(searchWord.toLowerCase())
    //   );
    //   console.log(searchWord.indexOf(" ") >= 0);
    //   console.log(
    //     value.attributes.nombre.toLowerCase().includes(searchWord.toLowerCase())
    //   );
    //   return value.attributes.nombre
    //     .toLowerCase()
    //     .startsWith(searchWord.toLowerCase());

    //   // .includes(searchWord.toLowerCase());
    // });

    const newFilter2 = data.map((e1, i) => {
      // console.log(e1);
      splitArray.some(
        (e2) => e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1
      );
      // console.log(
      const matches = splitArray.map((e2) => {
        // console.log(
        //   e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1,
        //   "e2"
        // );
        // if (e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1) {
        //   console.log(e1.attributes.nombre.toLowerCase());
        // }
        return {
          matched: e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1,
          product: e1.attributes.nombre.toLowerCase(),
          productId: e1.id,
          productPrice: e1.attributes.precio,
          productImage: e1.attributes.imagenes.data[0].attributes.url,
        };
      });
      // );

      // matches es el arreglo de todos los productos que tienen esos string. matched puede ser verdadero o falso
      // console.log(matches);
      if (matches.every((element) => element.matched === true)) {
        console.log(
          "se supone que es producto que coincidió depsués de e2",
          matches.map((element) => element)
        );
        console.log(matches.map((element) => element));
        return matches[0];
        // return matches.every((element) => element.matched === true);
      }
      // return matches.filter((match) => match !== null)
      // for (let o = 0; o < matches.length; o++) {
      //   const element = matches[o];
      //   if (element) {

      //   }
      // }
      // console.log(matches);
      // if (
      //   splitArray.some(
      //     (e2) => e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1
      //   )
      // ) {
      //   // If there's a match
      //   // console.log(
      //   //   splitArray.some(
      //   //     (e2) => e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1
      //   //   )
      //   // );
      //   // console.log("<div>Match</div>", e1);
      //   return splitArray.some(
      //     (e2) => e1.attributes.nombre.toLowerCase().indexOf(e2) !== -1
      //   );
      // }
      else {
        return null;
      }
    });
    console.log(newFilter2);
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter2.filter((match) => match !== null));
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  // console.log(filteredData);

  return (
    <div className="search">
      <div className="searchInputs">
        <input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <i className="far fa-search"></i>
          ) : (
            <i className="fas fa-times" onClick={clearInput}></i>
          )}
        </div>
      </div>
      {filteredData.length !== 0 ||
      filteredData[0] === undefined ||
      filteredData[0] === null ? (
        <div className="dataResult">
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <Link
                key={key}
                className="dataItem"
                to={`/product/${value?.productId}`}
                onClick={() => {
                  setWordEntered("");
                  setFilteredData([]);
                }}
              >
                <img src={value?.productImage} alt={value?.product} />
                <div className="d-flex flex-column dataItem__info">
                  <p>{value?.product} </p>
                  <p>{formatMoney(value?.productPrice)} </p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="dataResult">
          <p className="p-2">No hay resultados</p>
        </div>
      )}
      {filteredData.length === 0 && wordEntered !== "" && (
        <div className="dataResult">
          <p className="py-3 ps-2">No hay resultados</p>
        </div>
      )}
    </div>
  );
}

export default SearchBar;

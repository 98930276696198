import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
// import { BASE_PATH } from "../utils/constants";

// Context
import { useAuth } from "../context/AuthContext";
import { useCart } from "../context/CartContext";
import AlertContext from "../context/AlertContext";

// API
import { getProduct } from "../api/product";
import { getMeApi } from "../api/user";
// import useAuth from "../hooks/useAuth";

// Bootstrap components
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Custom Components
import { ProductCarousel } from "../components/Carousel/Carousel";
import { FacebookShare, WhatsAppShare } from "../components/ShareButton";

// Utils
import { toggleClass } from "../utils/toggleClass";
import formatMoney from "../utils/formatMoney";

// Assets
import WatchIconGeneral from "../assets/images/watch-icon.svg";
import WatchIconCase from "../assets/images/watch-icon-case.svg";
import WatchIconPointer from "../assets/images/watch-icon-pointer.svg";
import WatchIconStrap from "../assets/images/watch-icon-strap.svg";
import ArrowIcon from "../assets/images/arrow-icon--red.svg";
import ProductInfoImage1 from "../assets/images/watch-about--individual-page-1.jpg";
import ProductInfoImage2 from "../assets/images/watch-about--individual-page-2.jpg";

import ReactImageMagnify from "react-image-magnify";

export default function Product() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState(undefined);

  const { auth, logout } = useAuth();
  const { addProduct } = useCart();

  const alert = useContext(AlertContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getProduct(id);
      setProduct(response);
      setLoading(false);
      // if (query.platform) {
      // }
    })();
  }, [id]);
  // console.log(product);

  // function onClick() {
  //   setLoading(true);
  //   setTimeout(() => {
  //     // alert("Submited");
  //   }, 2000);
  //   setLoading(false);
  // }

  // useEffect(
  //   (logout) => {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       setUser(response);
  //     })();
  //   },
  //   [auth]
  // );

  async function handleClick() {
    // console.log(loading);
    if (auth?.idUser) {
      const response = await addProduct(product?.data, quantity, auth);
      if (response.data) {
        alert.success("Producto añadido a tu carrito.");
        return;
      }
    }
    alert.error(
      "Error al añadir el producto a tu carrito. Por favor, intenta de nuevo"
    );
    return;
    // setLoading(false);
  }

  const initDropdowns = () => {
    if (!loading) {
      const sectionContainer = document.querySelector(".product__details");
      // console.log(document.querySelector(".product__details"));
      const sections = Array.from(sectionContainer.children);

      sections.forEach((section) => {
        section.addEventListener("click", (event) => {
          const header = event.target.closest(
            ".product__details__section__header"
          );

          header !== null &&
            header !== undefined &&
            toggleClass(section, "open");
        });
      });
    }
  };

  useEffect(() => {
    initDropdowns();
  }, [loading, initDropdowns, id]);

  return (
    // <div className="product__container">
    //   <Row className="h-100">
    //     <Col xs="12" lg="6" className="product__container__media">
    //       <img
    //         src={`${BASE_PATH}${product?.data.attributes.imagenes?.data[0].attributes.url}`}
    //         alt={product?.data.attributes.imagenes?.data[0].attributes.name}
    //       />
    //     </Col>
    //     <Col xs="12" lg="6" className="product__container__content">
    //       <h3>{product?.data.attributes.nombre}</h3>
    //       <p>{product?.data.attributes.marca?.data.attributes.nombre}</p>
    //       <p>
    //         <span>Stock:</span>
    //         <span>{product?.data.attributes.stock} pza(s)</span>
    //       </p>
    //       <p>{formatMoney(product?.data.attributes.precio)}</p>
    //       {user ? (
    //         <Button
    //           variant="primary"
    //           type="submit"
    //           disabled={product?.data.attributes.stock === 0}
    //           onClick={() => {
    //             // console.log(user);
    //             // setLoading(true);
    //             handleClick();
    //           }}
    //         >
    //           Añadir al carrito
    //         </Button>
    //       ) : (
    //         <Link to="/login">Añadir al carrito</Link>
    //       )}
    //     </Col>
    //   </Row>
    // </div>
    <section className="product">
      <div className="product__container">
        {!loading && (
          <>
            <div className="product__carousel-container col-12 col-md-6">
              {product?.data.attributes.imagenes?.data.length > 0 && (
                <ProductCarousel
                  productName={product?.data.attributes.nombre}
                  productImages={product.data.attributes.imagenes?.data}
                />
                // <ReactImageMagnify
                //   // className="textMagnify"
                //   {...{
                //     // className: "product-carousel__slide__image",
                //     smallImage: {
                //       alt: product?.data.attributes.nombre,
                //       isFluidWidth: true,
                //       src: product.data.attributes.imagenes?.data[0].attributes
                //         .url,
                //     },
                //     largeImage: {
                //       src: product.data.attributes.imagenes?.data[0].attributes
                //         .url,
                //       width: 1200,
                //       height: 1800,
                //     },
                //   }}
                // />
              )}
            </div>
            <div className="product__content col-12 col-md-6">
              <div className="product__content-holder">
                <div className="product__header">
                  <p className="product__brand">
                    {product?.data.attributes.marca?.data.attributes.nombre}
                  </p>
                  <p className="product__genre">
                    {product?.data.attributes.genero?.data.attributes.nombre}
                  </p>
                  {/*{newModel && <p className="product__newModel">Nuevo modelo</p>} */}
                </div>
                <p className="product__name">
                  {product?.data.attributes.nombre}
                </p>
                <p className="product__description">
                  {product?.data.attributes.descripcion}
                </p>
                <p className="product__stock">
                  <span>Stock: </span>
                  {product?.data.attributes.stock} pza(s)
                </p>
                <p className="product__eta">
                  <span>Tiempo de entrega: </span>
                  {product?.data.attributes.eta} días
                </p>
                <div className="product__share">
                  <span>Compartir:</span>
                  <div className="ms-2">
                    <FacebookShare
                      url={`https://larelojeriair.com/product/${product?.data.id}`}
                    />
                    <WhatsAppShare
                      url={`https://larelojeriair.com/product/${product?.data.id}`}
                    />
                  </div>
                </div>
                <p className="product__price">
                  {formatMoney(product?.data.attributes.precio)} MXN{" "}
                  <span>envío incluido</span>
                </p>

                {/* <div className="cart__item__quantity-container">
                  <p>cantidad:</p>
                  <div className="cart__item__quantity-holder">
                    <div
                      className="cart__item__quantity__count-button cart__item__quantity__count-button--less"
                      onClick={decreaseQty}
                    >
                      <i className="cart__item__quantity__count-button__icon fal fa-minus"></i>
                    </div>
                    <p className="cart__item__quantity__value">{quantity}</p>
                    <div
                      className="cart__item__quantity__count-button cart__item__quantity__count-button--plus"
                      onClick={increaseQty}
                    >
                      <i className="cart__item__quantity__count-button__icon fal fa-plus"></i>
                    </div>
                  </div>
                </div> */}
                {auth.idUser ? (
                  <>
                    <div className="quantity-selector">
                      <button
                        className="counter-action"
                        disabled={quantity <= 1}
                        onClick={() => {
                          setQuantity(quantity - 1);
                        }}
                      >
                        <span>-</span>
                      </button>
                      <h5 className="m-0 px-4 px-lg-3">
                        {product?.data.attributes.stock === 0 ? 0 : quantity}
                      </h5>
                      <button
                        className="counter-action"
                        disabled={quantity >= product?.data.attributes.stock}
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                        // onClick={handleIncrement}
                      >
                        <span>+</span>
                      </button>
                    </div>
                    <div className="d-flex flex-column flex-lg-row justify-content-between">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={product?.data.attributes.stock === 0}
                        className="product__cta product__cta--cart"
                        onClick={() => {
                          // console.log(user);
                          // setLoading(true);
                          setQuantity(1);
                          handleClick();
                        }}
                      >
                        Añadir al carrito
                      </Button>
                      <Button
                        disabled={product?.data.attributes.stock === 0}
                        className="product__cta product__cta--buy"
                        onClick={() => {
                          // console.log(user);
                          // setLoading(true);
                          setQuantity(1);
                          handleClick();
                          setTimeout(() => {
                            history.push("/cart");
                          }, 500);
                        }}
                      >
                        Comprar
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p>
                      Para comprar necesitas{" "}
                      <Link to="/register">registrarte</Link> o haber{" "}
                      <Link to="/login">iniciado sesión</Link>.
                    </p>
                  </>
                )}
                <div className="product__details">
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconGeneral}
                        alt="Información general"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Información general
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p>{product?.data.attributes.informacionGeneral}</p>
                      </div>
                      {/* <div className="product__details__content__section col-12 col-md-6">
                        <p className="product__details__content__section__title">
                          Género
                        </p>
                        <p className="product__details__content__section__text">
                          Reloj de {data.Producto.genero}
                        </p>
                      </div>
                      <div className="product__details__content__section col-12 col-md-6">
                        <p className="product__details__content__section__title">
                          Estado
                        </p>
                        <p className="product__details__content__section__text">
                          {data.Producto.condicion}
                        </p>
                      </div>
                      <div className="product__details__content__section col-12 col-md-6">
                        <p className="product__details__content__section__title">
                          Contenido de la entrega
                        </p>
                        <p className="product__details__content__section__text">
                          {packageContents}
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconCase}
                        alt="Carcasa"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Carcasa
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p>{product?.data.attributes.carcasa}</p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconPointer}
                        alt="Carátula"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Carátula
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section ">
                        <p>{product?.data.attributes.caratula}</p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconStrap}
                        alt="Correa"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Correa
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p>{product?.data.attributes.correa}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product__cta-container">
                  {/* <Link to="/cart" className="product__cta product__cta--buy">
                    <p className="product__cta__text">Comprar</p>
                  </Link> */}
                  {/* {user && (
                    <AddToCart
                      id={data.Producto.id}
                      stock={data.Producto.stock}
                    />
                  )}
                  {!user && (
                    <Link
                      to="/cuenta"
                      className="product__cta product__cta--cart"
                    >
                      <p className="product__cta__text">Agregar al carrito</p>
                    </Link>
                  )} */}
                  {/* <div
                    className="product__cta product__cta--cart"
                    onClick={() => addToCart(data.Producto)}
                  >
                    <p className="product__cta__text">Agregar al carrito</p>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        )}
        {/* <div className="product__info__content-container col-12 col-md-6">
          <div className="product__info__content">
            <h3 className="product__info__title">
              Lorem ipsum dolor sit amet, consectetur adipscing
            </h3>
            <p className="product__info__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aute
              irure dolor in reprehenderit in voluptate velit esse cillum dolore
              eu fugiat
            </p>
          </div>
        </div>
        <div className="product__info__image__container col-12 col-md-6">
          <div className="product__info__image-container">
            <img
              src={ProductInfoImage1}
              alt="La Relojeriair"
              className="product__info__image"
            />
            <img
              src={ProductInfoImage2}
              alt="La Relojeriair"
              className="product__info__image"
            />
            <img
              src={ProductInfoImage1}
              alt="La Relojeriair"
              className="product__info__image"
            />
          </div>
        </div> */}
      </div>
    </section>
  );
}

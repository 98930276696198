import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom CSS
import "../assets/scss/pages/confirmation.scss";

// API
import { getMeApi } from "../api/user";

// Hooks
import useAuth from "../hooks/useAuth";

// Assets
import checkIcon from "../assets/images/check-icon--green--sm.svg";

export default function Confirmation() {
  const [user, setUser] = useState(undefined);
  const { id } = useParams();
  const { auth, logout } = useAuth();
  useEffect(() => {
    (async () => {
      const response = await getMeApi(logout);
      setUser(response);
    })();
  }, [auth]);
  // console.log(user);
  return (
    <div className="confirmation">
      <Container className="text-center confirmation__container">
        <h3>Confirmación de orden</h3>
        <div className="confirmation__container__content">
          <img src={checkIcon} alt="" />
          <h4>
            Orden <span>#{id}</span> recibida
          </h4>
          <p>
            Gracias por tu compra, {user?.username}. Por favor revisa la bandeja
            de entrada de tu correo electrónico.
          </p>
          <div className="confirmation__container__cta">
            <Link to="/catalogue">Regresar</Link>
            <Link to="/profile">Mis ordenes</Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

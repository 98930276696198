import React from "react";

import Pagination from "react-bootstrap/Pagination";

import "./pagination.styles.scss";

function CustomPagination({ postsPerPage, totalPosts, paginate, currentPage }) {
  // console.log(postsPerPage, totalPosts, paginate, currentPage);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Pagination>
      {/* <Pagination.First
        onClick={() => {
          paginate(1);
          window.scrollTo(0, 0);
        }}
      /> */}
      <Pagination.Prev
        onClick={() => {
          if (currentPage !== 1) {
            paginate(currentPage - 1);
            window.scrollTo(0, 0);
          } else {
            window.scrollTo(0, 0);
            return;
          }
        }}
        disabled={currentPage === 1}
      />
      {pageNumbers.map((number, key) => (
        <Pagination.Item
          onClick={() => {
            paginate(number);
            window.scrollTo(0, 0);
          }}
          className={currentPage === number ? "active" : ""}
          key={key}
        >
          {number}
        </Pagination.Item>
      ))}

      <Pagination.Next
        onClick={() => {
          if (currentPage !== pageNumbers.length) {
            paginate(currentPage + 1);
            window.scrollTo(0, 0);
          }
        }}
        disabled={currentPage === pageNumbers.length}
      />
      {/* <Pagination.Last
        onClick={() => {
          paginate(pageNumbers.at(-1));
          window.scrollTo(0, 0);
        }}
      /> */}
    </Pagination>
  );
}

export default CustomPagination;

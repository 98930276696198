// import { createContext } from "react";

// const AuthContext = createContext({
//   auth: undefined,
//   login: () => null,
//   logout: () => null,
//   setReloadUser: () => null,
// });

// export default AuthContext;

import { createContext, useContext, useState, useEffect } from "react";

// API
import { setToken, getToken, removeToken } from "../api/token";
import { getMeApi } from "../api/user";
import {
  addProductCart,
  countProductsCart,
  decreaseProductCart,
  deleteCartItemApi,
  getProductsCart,
  getTotalCartPrice,
  increaseProductCart,
  removeAllProductsCart,
} from "../api/cart";

// Utils
import jwtDecode from "jwt-decode";

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({});
  const [user, setUser] = useState({});

  const [totalProductsCart, setTotalProductsCart] = useState(0);

  const logout = () => {
    if (auth) {
      removeToken();
      setAuth({});
      return;
    }
  };

  const login = (token) => {
    setToken(token);
    setAuth({
      token,
      idUser: jwtDecode(token).id,
    });
  };

  const token = getToken();
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        setAuth({
          token,
          idUser: jwtDecode(token).id,
        });
        const response = await getMeApi(token);
        if (response) {
          setUser(response);
        }
      } else {
        setAuth({});
        setUser({});
      }
    };
  
    fetchData();
  
  }, [token]);

  return (
    <AuthContext.Provider value={{ auth, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
}

import React from "react";

import { STRIPE_KEY } from "../utils/constants";

// Custom Components
import PaymentForm from "../components/PaymentForm/PaymentForm";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom CSS
import "../assets/scss/pages/payment.scss";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripeLib = loadStripe(`${STRIPE_KEY}`, {
  locale: "es-419",
});

export default function Checkout() {
  return (
    <div className="py-3 payment">
      <Container fluid>
        <h4>Checkout</h4>
        <Elements stripe={stripeLib}>
          <PaymentForm />
        </Elements>
      </Container>
    </div>
  );
}

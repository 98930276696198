import React from "react";

import { CardElement } from "@stripe/react-stripe-js";

// Custom Styles
import "./cardSection.styles.scss";

function CardSection(props) {
  return (
    <div>
      <fieldset style={{ border: "none" }}>
        <div className="form-row">
          <div id="card-element">
            <CardElement
              options={{
                style: { base: { fontSize: "18px" } },
              }}
            />
          </div>
          {props.stripeError ? <div>{props.stripeError.toString()}</div> : null}
          <div id="card-errors" role="alert" />
        </div>
      </fieldset>
    </div>
  );
}
export default CardSection;

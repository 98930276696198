import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Bootstrap Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Context
import { useProducts } from "../context/ProductsContext";

// Custom components
import ProductCard from "../components/ProductCard/ProductCard";

// API
import { getConditionProducts, getBrandProducts } from "../api/product";
import { getCondition, getWatchGenres } from "../api/genre";
import { getBrand } from "../api/brand";
import { getWatchBands } from "../api/watch-band";

// Util
import { toggleClass } from "../utils/toggleClass";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";

// Custom styles
import "../assets/scss/pages/condition.scss";

function Condition() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [condition, setCondition] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState("");

  const { brands } = useProducts();

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(
      ".condition__catalogue__filters"
    );

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };

  useEffect(() => {
    initDropdownToggle();
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getConditionProducts(slug);
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
          delete response.data[index].attributes.caratula;
          delete response.data[index].attributes.carcasa;
          delete response.data[index].attributes.condicion;
          delete response.data[index].attributes.correa;
          delete response.data[index].attributes.descripcion;
          delete response.data[index].attributes.eta;
          delete response.data[index].attributes.informacionGeneral;
          delete response.data[index].attributes.genero.data.attributes
            .createdAt;
          delete response.data[index].attributes.genero.data.attributes
            .publishedAt;
          delete response.data[index].attributes.genero.data.attributes
            .updatedAt;
          delete response.data[index].attributes.marca.data.attributes
            .createdAt;
          delete response.data[index].attributes.marca.data.attributes
            .publishedAt;
          delete response.data[index].attributes.marca.data.attributes
            .updatedAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .createdAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .publishedAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .updatedAt;
        }
        setProducts(response);
      }
      setLoading(false);
    })(slug);
  }, [slug]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getCondition(slug);
      if (response !== null) {
        setCondition(response.data[0].attributes);
      }
    })(slug);
  }, [slug]);

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      genero: {
        hombre: false,
        Mujer: false,
        unisex: false,
      },
      marca: {
        Bulova: false,
        Citizen: false,
        IWC: false,
        Longines: false,
        Mido: false,
        Omega: false,
        "Porsche Design": false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
    },
  };

  const [testinState, setTestingState] = useState(state);
  const [categories, setCategories] = useState([]);
  const [watchBands, setWatchBands] = useState([]);
  const [watchGenres, setWatchGenres] = useState([]);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      genero: [],
      marca: [],
      tipo: [],
    };
    const { calibre, genero, correa, marca, tipo, tipoCorrea } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let generoKey in genero) {
      if (genero[generoKey]) collectedTrueKeys.genero.push(generoKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let marcaKey in marca) {
      if (marca[marcaKey]) collectedTrueKeys.marca.push(marcaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    return collectedTrueKeys;
  };

  const multiPropsFilter = (products, filters) => {
    const filterKeys = Object.keys(filters);
    return products?.data.filter((product) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) return true;
        return filters[key].includes(
          product?.attributes[key].data.attributes.nombre
        );
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(products, filteredCollected());
    return filteredProducts2;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getWatchGenres();
      if (response !== null) {
        setWatchGenres(response);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getWatchBands();
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
        }
        setWatchBands(response);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, []);

  // if (loading) {
  //   return (
  //     <section className="brand-catalogue">
  //       <Container>
  //         <p>Cargando...</p>
  //       </Container>
  //     </section>
  //   );
  // }

  return (
    <section className="condition__catalogue">
      <div
        className="products__genre__header"
        style={{
          backgroundImage: `linear-gradient(
                   rgba(0, 0, 0, 0.53),
                   rgba(0, 0, 0, 0.275)
                 ), url(${condition?.portada.data.attributes.url})`,
        }}
      >
        <h3>{condition?.nombre}</h3>
      </div>
      <div className="condition__catalogue__products">
        <Container fluid>
          <Row>
            <div
              className="condition__catalogue__filters col-12 col-lg-3"
              // onClick={() => setExpanded(expanded ? false : "show")}
            >
              <div className="filters__container">
                <div className="filters__header">
                  <img
                    src={FilterIcon}
                    alt="Filtros"
                    className="filters__icon"
                  />
                  <p className="filters__title">Filtros</p>
                </div>
                <div className="filters__filter-container">
                  <div
                    className="filters__filter filters__filter--clear filter__item"
                    data-filter="all"
                  >
                    <div className="filters__filter__content">
                      <p
                        className="filters__filter__name filters__filter__name--small"
                        onClick={() => {
                          const selectedFilter = document.querySelectorAll(
                            ".filters__filter__dropdown-menu__item__name"
                          );
                          selectedFilter.forEach((filter) =>
                            filter.classList.remove("active")
                          );
                          setFilteredProducts([]);
                          setCategories([]);
                          setTestingState(state);
                        }}
                      >
                        Limpiar filtros <i className="far fa-times"></i>
                      </p>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Correa</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {watchBands.data?.map((watchBand, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={watchBand.attributes.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                allFilterClickListener(
                                  watchBand.attributes.nombre,
                                  "tipoCorrea"
                                );
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");
                                // if (
                                //   filteredProducts.length === 0 &&
                                //   category === ""
                                // ) {
                                //   filterFunction(watchBand.attributes.nombre);
                                //   setCategory(watchBand.attributes.nombre);
                                // } else {
                                //   setFilteredProducts([]);
                                //   setCategory("");
                                // }
                                if (
                                  categories.includes(
                                    watchBand.attributes.nombre.toLowerCase()
                                  )
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !==
                                        watchBand.attributes.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    watchBand.attributes.nombre.toLowerCase(),
                                  ]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                {watchBand.attributes.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Género</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {watchGenres.data?.map((watchGenre, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={watchGenre.attributes.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                allFilterClickListener(
                                  watchGenre.attributes.nombre,
                                  "genero"
                                );
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");

                                // if (
                                //   filteredProducts.length === 0 &&
                                //   category === ""
                                // ) {
                                //   filterGenreFunction(
                                //     watchGenre.attributes.nombre
                                //   );
                                //   setCategory(watchGenre.attributes.nombre);
                                // } else {
                                //   filterGenreFunction([]);
                                //   setCategory("");
                                // }
                                if (
                                  categories.includes(
                                    watchGenre.attributes.nombre.toLowerCase()
                                  )
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !==
                                        watchGenre.attributes.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    watchGenre.attributes.nombre.toLowerCase(),
                                  ]);
                                }
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                {watchGenre.attributes.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Marca</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          {brands.data?.map((brand, key) => (
                            <li
                              className="filters__filter__dropdown-menu__item filter__item"
                              data-filter={brand.attributes.nombre.toLowerCase()}
                              key={key}
                              onClick={(e) => {
                                // console.log(e.target);
                                allFilterClickListener(
                                  brand.attributes.nombre,
                                  // .toLowerCase()
                                  // .split(" ")
                                  // .join("_"),
                                  "marca"
                                );
                                const filtersDropdownMenu =
                                  document.querySelector(
                                    ".filters__filter-container"
                                  );
                                filtersDropdownMenu.classList.remove("show");
                                e.target.classList.toggle("active");

                                if (
                                  categories.includes(
                                    brand.attributes.nombre.toLowerCase()
                                  )
                                ) {
                                  setCategories(
                                    categories.filter(
                                      (category) =>
                                        category !==
                                        brand.attributes.nombre.toLowerCase()
                                    )
                                  );
                                } else {
                                  setCategories([
                                    ...categories,
                                    brand.attributes.nombre.toLowerCase(),
                                  ]);
                                }

                                // if (
                                //   filteredProducts.length === 0 &&
                                //   category === ""
                                // ) {
                                //   filterBrandFunction(brand.attributes.nombre);
                                //   setCategory(brand.attributes.nombre);
                                // } else {
                                //   filterGenreFunction([]);
                                //   setCategory("");
                                // }
                                // console.log(
                                //   products?.filter(
                                //     (product) =>
                                //       product.attributes.tipoCorrea.data.attributes
                                //         .nombre === brand.attributes.nombre
                                //   )
                                // );
                              }}
                            >
                              <p className="filters__filter__dropdown-menu__item__name">
                                {brand.attributes.nombre}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Tipo</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          <li
                            className="filters__filter__dropdown-menu__item filter__item"
                            data-filter="japones"
                            onClick={(e) => {
                              allFilterClickListener("Japonés", "tipo");
                              const filtersDropdownMenu =
                                document.querySelector(
                                  ".filters__filter-container"
                                );
                              filtersDropdownMenu.classList.remove("show");
                              e.target.classList.toggle("active");
                              // if (
                              //   filteredProducts.length === 0 &&
                              //   category === ""
                              // ) {
                              //   filterTypeFunction("japonés");
                              //   setCategory("japones");
                              // } else {
                              //   setFilteredProducts([]);
                              //   setCategory("");
                              // }
                              if (categories.includes("japonés")) {
                                setCategories(
                                  categories.filter(
                                    (category) => category !== "japonés"
                                  )
                                );
                              } else {
                                setCategories([...categories, "japonés"]);
                              }
                            }}
                          >
                            <p className="filters__filter__dropdown-menu__item__name">
                              Japonés
                            </p>
                          </li>
                          <li
                            className="filters__filter__dropdown-menu__item filter__item"
                            data-filter="suizo"
                            onClick={(e) => {
                              allFilterClickListener("Suizo", "tipo");
                              const filtersDropdownMenu =
                                document.querySelector(
                                  ".filters__filter-container"
                                );
                              filtersDropdownMenu.classList.remove("show");
                              e.target.classList.toggle("active");
                              // if (
                              //   filteredProducts.length === 0 &&
                              //   category === ""
                              // ) {
                              //   filterTypeFunction("suizo");
                              //   setCategory("suizo");
                              // } else {
                              //   setFilteredProducts([]);
                              //   setCategory("");
                              // }
                              if (categories.includes("suizo")) {
                                setCategories(
                                  categories.filter(
                                    (category) => category !== "suizo"
                                  )
                                );
                              } else {
                                setCategories([...categories, "suizo"]);
                              }
                            }}
                          >
                            <p className="filters__filter__dropdown-menu__item__name">
                              Suizo
                            </p>
                          </li>
                          <li
                            className="filters__filter__dropdown-menu__item filter__item"
                            data-filter="vintage"
                            onClick={(e) => {
                              allFilterClickListener("Vintage", "tipo");
                              const filtersDropdownMenu =
                                document.querySelector(
                                  ".filters__filter-container"
                                );
                              filtersDropdownMenu.classList.remove("show");
                              e.target.classList.toggle("active");
                              // if (
                              //   filteredProducts.length === 0 &&
                              //   category === ""
                              // ) {
                              //   filterFunction("vintage");
                              //   setCategory("vintage");
                              // } else {
                              //   setFilteredProducts([]);
                              //   setCategory("");
                              // }
                              if (categories.includes("vintage")) {
                                setCategories(
                                  categories.filter(
                                    (category) => category !== "vintage"
                                  )
                                );
                              } else {
                                setCategories([...categories, "vintage"]);
                              }
                            }}
                          >
                            <p className="filters__filter__dropdown-menu__item__name">
                              Vintage
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filters__filter filters__filter--dropdown">
                    <div className="filters__filter__content">
                      <p className="filters__filter__name">Calibre</p>
                      <div className="filters__filter__dropdown-menu">
                        <ul className="filters__filter__dropdown-menu__item-container">
                          <li
                            className="filters__filter__dropdown-menu__item filter__item"
                            data-filter="automático"
                            onClick={(e) => {
                              allFilterClickListener("Automático", "calibre");
                              const filtersDropdownMenu =
                                document.querySelector(
                                  ".filters__filter-container"
                                );
                              filtersDropdownMenu.classList.remove("show");
                              e.target.classList.toggle("active");
                              if (categories.includes("automático")) {
                                setCategories(
                                  categories.filter(
                                    (category) => category !== "automático"
                                  )
                                );
                              } else {
                                setCategories([...categories, "automático"]);
                              }
                              // if (
                              //   filteredProducts.length === 0 &&
                              //   category === ""
                              // ) {
                              //   filtersDropdownMenu.classList.remove(
                              //     "active"
                              //   );
                              //   filterCaliberFunction("automático");
                              //   setCategory("automático");
                              // } else {
                              //   setFilteredProducts([]);
                              //   setCategory("");
                              // }
                            }}
                          >
                            <p className="filters__filter__dropdown-menu__item__name">
                              Automático
                            </p>
                          </li>
                          <li
                            className="filters__filter__dropdown-menu__item filter__item"
                            data-filter="cuarzo"
                            onClick={(e) => {
                              allFilterClickListener("Cuarzo", "calibre");
                              const filtersDropdownMenu =
                                document.querySelector(
                                  ".filters__filter-container"
                                );
                              filtersDropdownMenu.classList.remove("show");
                              e.target.classList.toggle("active");
                              // if (
                              //   filteredProducts.length === 0 &&
                              //   category === ""
                              // ) {
                              //   filterCaliberFunction("cuarzo");
                              //   setCategory("cuarzo");
                              // } else {
                              //   setFilteredProducts([]);
                              //   setCategory("");
                              // }
                              if (categories.includes("cuarzo")) {
                                setCategories(
                                  categories.filter(
                                    (category) => category !== "cuarzo"
                                  )
                                );
                              } else {
                                setCategories([...categories, "cuarzo"]);
                              }
                            }}
                          >
                            <p className="filters__filter__dropdown-menu__item__name">
                              Cuarzo
                            </p>
                          </li>
                          <li
                            className="filters__filter__dropdown-menu__item filter__item"
                            data-filter="cuerda"
                            onClick={(e) => {
                              allFilterClickListener("Cuerda", "calibre");
                              const filtersDropdownMenu =
                                document.querySelector(
                                  ".filters__filter-container"
                                );
                              filtersDropdownMenu.classList.remove("show");
                              e.target.classList.toggle("active");
                              // if (
                              //   filteredProducts.length === 0 &&
                              //   category === ""
                              // ) {
                              //   filterCaliberFunction("cuerda");
                              //   setCategory("cuerda");
                              // } else {
                              //   setFilteredProducts([]);
                              //   setCategory("");
                              // }
                              if (categories.includes("cuerda")) {
                                setCategories(
                                  categories.filter(
                                    (category) => category !== "cuerda"
                                  )
                                );
                              } else {
                                setCategories([...categories, "cuerda"]);
                              }
                            }}
                          >
                            <p className="filters__filter__dropdown-menu__item__name">
                              Cuerda
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <Row>
                {!loading &&
                  categories.length > 0 &&
                  searchProducts().map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))}
                {!loading &&
                categories.length > 0 &&
                searchProducts().length === 0 ? (
                  <p className="py-3">
                    No se encontraron relojes con los filtros seleccionados.
                  </p>
                ) : null}
                {products?.data.length === 0 && categories.length === 0 && (
                  <div className="col-12 col-lg-9">
                    <p className="pt-3">No hay productos</p>
                  </div>
                )}
                {filteredProducts?.length === 0 &&
                  categories.length === 0 &&
                  products?.data.length > 0 &&
                  products?.data.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))}
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Condition;

import React from "react";
import { Link } from "react-router-dom";

const ctaButton = ({
  href = "/",
  color = "red",
  textColor = "white",
  text,
  align = "center",
  size = "big",
  extraContainerClasses = "",
  extraClasses = "",
  arrow = false,
}) => {
  return (
    <div
      className={`cta-button-container align-${align} ${extraContainerClasses}`}
    >
      <Link
        to={href}
        className={`cta-button cta-button--${color} cta-button--${size} ${extraClasses} text-${textColor}`}
        title={text}
      >
        <p className="cta-button__text">{text}</p>
        {arrow && (
          <i className="cta-button__text__icon fal fa-long-arrow-right"></i>
        )}
      </Link>
    </div>
  );
};

export default ctaButton;

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
// import { BASE_PATH } from "../utils/constants";
// API
import { getAuctionProduct } from "../api/product";
import { getMeApi } from "../api/user";
import { postBid } from "../api/raffle";
import useAuth from "../hooks/useAuth";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Custom Components
import { ProductCarousel } from "../components/Carousel/Carousel";
import { FacebookShare, WhatsAppShare } from "../components/ShareButton";
import Timer from "react-compound-timer";
import moment from "moment-timezone";

// Utils
import { toggleClass } from "../utils/toggleClass";
import formatMoney from "../utils/formatMoney";
import useForm from "../utils/useForm";

// Assets
import WatchIconGeneral from "../assets/images/watch-icon.svg";
import WatchIconCase from "../assets/images/watch-icon-case.svg";
import WatchIconPointer from "../assets/images/watch-icon-pointer.svg";
import WatchIconStrap from "../assets/images/watch-icon-strap.svg";
import ArrowIcon from "../assets/images/arrow-icon--red.svg";

export default function AuctionProduct() {
  const [product, setProduct] = useState(null);
  const [bids, setBids] = useState([]);
  const [dateFromApi, setDateFromApi] = useState(null);
  const [count, setCount] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [user, setUser] = useState({});
  const { auth, user, logout } = useAuth();
  let history = useHistory();

  const startDate = new Date(`${product?.data.attributes.fechaFinal}`);

  // console.log(startDate.getFullYear());

  // console.log(product);

  // useEffect(() => {
  //   // setLoading(true);
  //   if (Object.keys(auth) === 0) {
  //     return;
  //   } else {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       if (response) {
  //         setUser(response);
  //       }
  //       return;
  //       // setLoading(false);
  //     })();
  //   }
  // }, []);

  useEffect(() => {
    // setLoading(true);
    (async () => {
      const response = await getAuctionProduct(id);
      setProduct(response);
      console.log(response);
      if (response.data.attributes.publishedAt === null) {
        history.push("/bids");
      }
      // setLoading(false);
      setBids(response.data.attributes.raffleEntries);
      setCount(response.data.attributes.pujaInicial);
      setDateFromApi(response.data.attributes.fechaFinal);

      // if (query.platform) {
      // }
    })(id);
  }, [id]);

  const counterValue = () => {
    // const dateFromApi = product.data.attributes.fechaFinal;
    if (dateFromApi !== null) {
      // const dateFromApi = product.data.attributes?.fechaFinal;
      const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
      const added = createdAt.clone();
      const now = moment.tz("America/Mexico_City");
      const duration = added.diff(now, "milliseconds");
      return duration;
    }
    // const createdAt = moment.tz(
    //   product?.data.attributes.fechaFinal,
    //   "America/Mexico_City"
    // );
    // const added = createdAt.clone();
    // const now = moment.tz("America/Mexico_City");
    // setDuration(added.diff(now, "milliseconds"));
  };

  const handleIncrement = () => {
    setCount(
      (prevCount) => prevCount + product?.data.attributes.pujaSubsecuente
    );
  };
  const handleDecrement = () => {
    // Here to validate not to be less than last posted

    // Get all bids and loop through them to find last bid
    // if (bids.length === 0) {

    // }
    if (count > bids[bids?.length - 1]?.bid) {
      setCount(
        (prevCount) => prevCount - product?.data.attributes.pujaSubsecuente
      );
    } else if (count > product?.data.attributes.pujaInicial) {
      setCount(
        (prevCount) => prevCount - product?.data.attributes.pujaSubsecuente
      );
    } else {
      return;
    }
  };

  const { inputs, handleChange, resetForm } = useForm({
    user: user?.username,
    bid: 0,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const formDataTemp = {
      id: product?.data.id,
      data: {
        raffleEntries: [
          ...bids,
          { id: user.id, usuario: user.username, bid: count },
        ],
      },
    };
    const response = await postBid(product?.data.id, formDataTemp, logout);
    if (!response) {
      console.log("Hubo un error en tu solicitud de puja");
    } else {
      console.log(response);
      setBids(response.raffleEntries);
      resetForm({
        user: user.username,
      });
      setCount(
        response.raffleEntries[response.raffleEntries.length - 1].bid +
        product?.data.attributes.pujaSubsecuente
      );
    }
  }

  const initDropdowns = () => {
    if (!loading) {
      const sectionContainer = document.querySelector(".product__details");
      // console.log(document.querySelector(".product__details"));
      const sections = Array.from(sectionContainer.children);

      sections.forEach((section) => {
        section.addEventListener("click", (event) => {
          const header = event.target.closest(
            ".product__details__section__header"
          );

          header !== null &&
            header !== undefined &&
            toggleClass(section, "open");
        });
      });
    }
  };

  useEffect(() => {
    initDropdowns();
  }, []);

  // useEffect(() => {
  //   // if (dateFromApi !== null) {
  //   //   const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
  //   //   const added = createdAt.clone();
  //   //   const now = moment.tz("America/Mexico_City");
  //   //   const duration = added.diff(now, "milliseconds");
  //   //   return duration;
  //   // }
  //   const createdAt = moment.tz(
  //     product.data.attributes?.fechaFinal,
  //     "America/Mexico_City"
  //   );
  //   const added = createdAt.clone();
  //   const now = moment.tz("America/Mexico_City");
  //   setDuration(added.diff(now, "milliseconds"));
  // }, [product, dateFromApi]);
  // console.log(duration);
  useEffect(() => {
    if (bids.length === 0) {
      setCount(product?.data.attributes.pujaInicial);
    } else {
      setCount(
        bids[bids.length - 1].bid + product?.data.attributes.pujaSubsecuente
      );
    }
  }, [product, bids]);
  return (
    <section className="product">
      <div className="product__container">
        {!loading && (
          <>
            <div className="product__carousel-container col-12 col-md-6">
              {product?.data.attributes.imagenes?.data.length > 0 && (
                <ProductCarousel
                  productName={product?.data.attributes.nombre}
                  productImages={product.data.attributes.imagenes?.data}
                />
              )}
            </div>
            <div className="product__content col-12 col-md-6">
              <div className="product__content-holder">
                <div className="product__header">
                  <p className="product__brand">
                    {product?.data.attributes.marca?.data.attributes.nombre}
                  </p>
                  {/*{newModel && <p className="product__newModel">Nuevo modelo</p>} */}
                  {product?.data.attributes.condicion !== "" && product?.data.attributes.condicion !== undefined && <p className="product__newModel"><span>Condición:</span>{product?.data.attributes.condicion}</p>}
                </div>
                <p className="product__name">
                  {product?.data.attributes.nombreProducto}
                </p>
                {product?.data.attributes.ganador !== null &&
                  product?.data.attributes.ganador !== "" && (
                    <div className="product__winner">
                      <p>
                        🎉 Ganador: 🎉{" "}
                        <span>{product?.data.attributes.ganador}</span>
                      </p>
                      <a
                        href="https://wa.me/524495457748"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className=" fab fa-whatsapp"></i> El ganador favor de
                        comunicarse por WhatsApp
                      </a>
                    </div>
                  )}
                <p className="product__description">
                  {product?.data.attributes.descripcion}
                </p>
                <h5>Políticas de funcionamiento de la subasta:</h5>
                <p
                  className="my-2"
                  style={{ textAlign: "justify", lineHeight: "1.5" }}
                >
                  Empieza esta subasta en{" "}
                  {formatMoney(product?.data.attributes.pujaInicial)} pesos
                  siguiendo de pujas subsecuentes mínimas de{" "}
                  {formatMoney(product?.data.attributes.pujaSubsecuente)} en{" "}
                  {formatMoney(product?.data.attributes.pujaSubsecuente)}!! La
                  subasta da incio desde este momento y termina el día{" "}
                  {startDate.getDate() +
                    "-" +
                    (startDate.getMonth() + 1) +
                    "-" +
                    startDate.getFullYear()}{" "}
                  a las 22:59:59 hrs. hasta ese tiempo tienen oportunidad de
                  pujar, ganara el que tenga la puja más alta, gastos del envío
                  por parte del ganador!! suerte a todos, excelente
                  reloj!!🍀🍀🍀⌚⌚⌚ si alguien va a pujar es porque se cuenta
                  con el dinero en mano!! Si pujas y no cumples se te bloqueara
                  de la página ❌❌❌ Suerte y que gane el mejor postor⌚⌚⌚
                  por nueva disposición de ahora en adelante otorgamos facturas
                  de todas nuestras ventas para que ustedes puedan deducir la
                  compra de impuestos‼️‼️ Ocupes factura o no se cobrará el 16 %
                  de IVA a la totalidad del monto ganador en la subasta, si
                  quieres quedar exento de este pago de IVA tendrás que realizar
                  el pago con retiro sin tarjeta para poder pagar tu reloj,
                  depósitos y transferencias rápidas o no factura llevarán ese
                  valor agregado, no entregas personales‼️‼️
                </p>
                <p className="raffle-product-card__timer">
                  {dateFromApi !== null && counterValue() > 0 && (
                    <Timer initialTime={counterValue()} direction="backward">
                      {() => (
                        <>
                          <Timer.Days /> días <Timer.Hours /> horas{" "}
                          <Timer.Minutes /> minutos <Timer.Seconds /> segundos{" "}
                        </>
                      )}
                    </Timer>
                  )}
                </p>
                {counterValue() < 0 && (
                  <p style={{ color: "#E10009", fontWeight: "bolder" }}>
                    La subasta ha finalizado
                  </p>
                )}
                <div className="product__bids__container">
                  <h4>Pujas:</h4>
                  {bids?.map((bid, key) => (
                    <div key={key} className="product__bids">
                      <p>
                        <span>Nombre: </span>
                        {bid.usuario}
                      </p>
                      <p>
                        <span>Puja: </span>
                        {formatMoney(bid.bid)} MXN
                      </p>
                    </div>
                  ))}
                </div>
                {!auth?.idUser && (
                  <p>
                    Debes <Link to="/login">iniciar sesión</Link> o{" "}
                    <Link to="/register">registrarte</Link> para publicar una
                    puja.
                  </p>
                )}
{console.log(auth.idUser)}
                <div className="product__bids__input">
                  {auth.idUser && counterValue() > 0 ? (
                    <Form onSubmit={handleSubmit} id="bid-form">
                      <Row>
                        <Form.Group
                          className="mt-1"
                          controlId="formName"
                          as={Col}
                          xs="6"
                          lg="6"
                        >
                          <Form.Label>Nombre de usuario:</Form.Label>
                          <Form.Control
                            name="user"
                            plaintext
                            value={user?.username}
                            readOnly
                          />
                          <Form.Text className="text-muted">
                            Este es tu nombre de usuario
                          </Form.Text>
                        </Form.Group>
                        <Form.Group
                          className="mb-2"
                          controlId="formBid"
                          as={Col}
                          xs="6"
                          lg="6"
                        >
                          <Form.Label>Oferta:</Form.Label>
                          <div className="bid-container">
                            <button
                              type="button"
                              className="counter-action"
                              disabled={
                                count <=
                                bids[bids?.length - 1]?.bid +
                                product?.data.attributes.pujaSubsecuente
                              }
                              onClick={handleDecrement}
                            >
                              -
                            </button>
                            <h5 className="form-control">
                              {formatMoney(count)}
                            </h5>
                            <button
                              type="button"
                              className="counter-action"
                              onClick={handleIncrement}
                            >
                              +
                            </button>
                          </div>
                        </Form.Group>
                      </Row>
                      <Button className="send" type="submit">
                        Enviar puja
                      </Button>
                    </Form>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="product__share">
                  <span>Compartir:</span>
                  <div className="ms-2">
                    <FacebookShare
                      url={`https://larelojeriair.com/auction-product/${product?.data.id}`}
                    />
                    <WhatsAppShare
                      url={`https://larelojeriair.com/auction-product/${product?.data.id}`}
                    />
                  </div>
                </div>
                <p className="product__price">
                  {formatMoney(product?.data.attributes.precio)} MXN
                </p>

                <div className="product__details">
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconGeneral}
                        alt="Información general"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Información general
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p>{product?.data.attributes.informacionGeneral}</p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconCase}
                        alt="Carcasa"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Carcasa
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p>{product?.data.attributes.carcasa}</p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconPointer}
                        alt="Carátula"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Carátula
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section ">
                        <p>{product?.data.attributes.caratula}</p>
                      </div>
                    </div>
                  </div>
                  <div className="product__details__section">
                    <div className="product__details__section__header">
                      <img
                        src={WatchIconStrap}
                        alt="Correa"
                        className="product__details__section__header__icon"
                      />
                      <p className="product__details__section__header__name">
                        Correa
                      </p>
                      <img
                        src={ArrowIcon}
                        alt="Abrir menú"
                        className="product__details__section__header__drop-icon"
                      />
                    </div>
                    <div className="product__details__content">
                      <div className="product__details__content__section">
                        <p>{product?.data.attributes.correa}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

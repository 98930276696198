import React from "react";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom CSS
import "./orderSummary.styles.scss";

// Utils
import { BASE_PATH } from "../../utils/constants";
import formatMoney from "../../utils/formatMoney";

export default function OrderSummary({
  cartItems,
  productsCart,
  total,
  totalProductsCart,
}) {
  console.log(total);
  // console.log(cartItems);
  return (
    <div>
      <h4>Resumen de tu orden</h4>
      <div className="labels">
        <h4>producto(s)</h4>
        <h4>precio</h4>
      </div>
      {cartItems.length > 0 &&
        cartItems?.map((product, key) => (
          <div key={key} className="order-summary__item">
            <div className="order-summary__item__details">
              <img
                // src={`${BASE_PATH}${product.attributes.productos.data[0].attributes.imagenes.data[0].attributes.url}`}
                src={
                  product.attributes.productos.data[0].attributes.imagenes
                    .data[0].attributes.url
                }
                className="img-fluid"
                alt={
                  product.attributes.productos.data[0].attributes.imagenes
                    .data[0].attributes.name
                }
              />
              <h5>{product.attributes.productos.data[0].attributes.nombre}</h5>
              <p>Cantidad: {product.attributes.cantidad}</p>
            </div>

            <p>
              {formatMoney(
                product.attributes.productos.data[0].attributes.precio
              )}{" "}
              MXN
            </p>

            {/* <button
              type="button"
              onClick={() => {
                removeProductCart(
                  user.id,
                  product.attributes.productos.data[0].id,
                  logout
                );
                setCartItems((prev) =>
                  prev.filter((item) => item.id !== product.id)
                );
              }}
            >
              Eliminar
            </button> */}
          </div>
        ))}
      <div className="order-summary__total">
        <Row>
          <Col xs="6">Piezas totales:</Col>
          <Col xs="6" className="mb-3">
            {totalProductsCart} pieza(s)
          </Col>
          <Col xs="6">
            <h3>Total:</h3>
          </Col>
          <Col xs="6">
            <h3>{formatMoney(total)} MXN</h3>
          </Col>
        </Row>
      </div>
    </div>
  );
}

import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";

// Context
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";
import AlertContext from "../../context/AlertContext";

// Utils
// import { BASE_PATH } from "../../utils/constants";
import formatMoney from "../../utils/formatMoney";

// API
// import { getMeApi } from "../../api/user";
// import { getProductsCart } from "../../api/cart";
// import useCart from "../../hooks/useCart";
// import useAuth from "../../hooks/useAuth";

// Custom CSS
import "./cartList.styles.scss";

// TO-DO: Mover toda la lógica de Cart.js aquí para no pasar products como props, sino usarlo en state y poder actualizar los productos en tiempo real

// const CartItem = ({ product, user, logout }) => {
//   const [quantity, setQuantity] = useState(parseInt(product.cantidad));
//   // console.log(product);
//   // const { removeItem } = useContext(CartContext);
//   // const decreaseQty = () => {
//   //   setQuantity((prevQuantity) =>
//   //     prevQuantity > 1 ? prevQuantity - 1 : prevQuantity
//   //   );
//   // };

//   // const increaseQty = () => {
//   //   setQuantity((prevQuantity) => prevQuantity + 1);
//   // };
//   // console.log(product.id, "26");

//   return (
//     <div className="cart__item">
//       {product && (
//         <div className="cart__item-holder">
//           <div className="cart__item__image-container">
//             <img
//               src={`${BASE_PATH}${product.attributes.productos.data[0].attributes.imagenes.data[0].attributes.url}`}
//               alt={product.data[0].attributes.imagenes.data[0].attributes.name}
//               className="cart__item__image"
//             />
//           </div>
//           <div className="cart__item__name-container">
//             <p className="cart__item__name">
//               {product.productos.data[0].attributes.nombre}
//             </p>
//           </div>
//           <div className="cart__item__price-container">
//             <p className="cart__item__price">
//               {formatMoney(product.productos.data[0].attributes.precio)}
//             </p>
//           </div>
//           <div className="cart__item__quantity-container">
//             <div className="cart__item__quantity-holder">
//               {/* <div className="cart__item__quantity__count-button cart__item__quantity__count-button--less" onClick={ decreaseQty }>
//                             <i className="cart__item__quantity__count-button__icon fal fa-minus"></i>
//                         </div>
//                         <p className="cart__item__quantity__value">{ quantity }</p>
//                         <div className="cart__item__quantity__count-button cart__item__quantity__count-button--plus" onClick={ increaseQty }>
//                             <i className="cart__item__quantity__count-button__icon fal fa-plus"></i>
//                         </div> */}
//               <p className="cart__item__quantity__value">{product.cantidad}</p>
//             </div>
//           </div>
//           <div className="cart__item__subtotal-container">
//             <p className="cart__item__subtotal__header">Subtotal</p>
//             <p className="cart__item__subtotal">
//               {formatMoney(
//                 parseFloat(product.productos.data[0].attributes.precio) *
//                   parseInt(product.cantidad)
//               )}{" "}
//               MXN
//             </p>
//           </div>
//           {/* <RemoveFromCart id={product.id} /> */}
//           <button
//             type="button"
//             onClick={() => {
//               removeProductCart(
//                 user.id,
//                 product.attributes.productos.data[0].id,
//                 logout
//               );
//               setCartItems((prev) =>
//                 prev.filter((item) => item.id !== product.id)
//               );
//               // console.log("Clicked", product.id);
//             }}
//           >
//             Eliminar
//           </button>
//           {/* <div className="cart__item__actions-container">
//           <div
//             className="cart__item__action cart__item__action--delete"
//             onClick={() => removeItem(product.product.id)}
//           >
//             <i className="cart__item__action__icon fal fa-trash"></i>
//           </div>
//         </div> */}
//         </div>
//       )}
//     </div>
//   );
// };

function CartItem({
  cartItem,
  increaseProduct,
  decreaseProduct,
  removeProduct,
  logout,
  user,
  setCartItems,
  alert,
}) {
  let newQuantity = useRef(cartItem?.attributes?.cantidad);
  // console.log(newQuantity, "newQuantity");
  // console.log(cartItem);
  return (
    <div className="cart__item-holder">
      <div className="cart__item__image-container">
        <img
          // src={`${BASE_PATH}${cartItem.attributes.productos.data[0].attributes.imagenes.data[0].attributes.url}`}
          src={
            cartItem.attributes.productos.data[0].attributes.imagenes.data[0]
              .attributes.url
          }
          alt={cartItem.attributes.productos.data[0].attributes.nombre}
          className="cart__item__image"
        />
      </div>
      <div className="cart__item__name-container">
        <Link
          to={`/product/${cartItem.attributes.productos.data[0].id}`}
          className="cart__item__name"
        >
          {cartItem.attributes.productos.data[0].attributes.nombre}
        </Link>
      </div>
      <div className="cart__item__price-container">
        <p className="cart__item__price">
          {formatMoney(cartItem.attributes.productos.data[0].attributes.precio)}{" "}
          MXN
        </p>
      </div>
      <div className="cart__item__quantity-container">
        <div className="cart__item__quantity-holder">
          {/* <div className="cart__item__quantity__count-button cart__item__quantity__count-button--less" onClick={ decreaseQty }>
                              <i className="cart__item__quantity__count-button__icon fal fa-minus"></i>
                          </div>
                          <p className="cart__item__quantity__value">{ quantity }</p>
                          <div className="cart__item__quantity__count-button cart__item__quantity__count-button--plus" onClick={ increaseQty }>
                              <i className="cart__item__quantity__count-button__icon fal fa-plus"></i>
                          </div> */}
          {/* <p className="cart__item__quantity__value">
                    {cartItem.attributes.cantidad}
                  </p> */}
          <button
            className="counter-action"
            disabled={newQuantity.current <= 1}
            onClick={() => {
              newQuantity.current--;
              decreaseProduct(
                user.idUser,
                newQuantity.current,
                cartItem.attributes.productos.data[0].id,
                logout
              );
            }}
          >
            <span>-</span>
          </button>
          <h5 className="m-0 px-2 px-lg-3">{newQuantity.current}</h5>
          <button
            className="counter-action"
            disabled={
              newQuantity.current >=
              cartItem.attributes.productos.data[0].attributes.stock
            }
            onClick={async () => {
              newQuantity.current++;
              const response = await increaseProduct(
                user.idUser,
                newQuantity.current,
                cartItem.attributes.productos.data[0].id,
                logout
              );
              // if (response.data) {
              //   // setCartItems(response.data);
              //   console.log("hello");
              // }
            }}
            // onClick={handleIncrement}
          >
            <span>+</span>
          </button>
        </div>
      </div>
      {/* <div className="cart__item__subtotal-container">
                <p className="cart__item__subtotal__header">Subtotal</p>
                <p className="cart__item__subtotal">
                  {formatMoney(
                    parseFloat(
                      cartItem.attributes.productos.data[0].attributes.precio
                    ) * parseInt(cartItem.attributes.cantidad)
                  )}{" "}
                  MXN
                </p>
              </div> */}
      {/* <RemoveFromCart id={product.id} /> */}
      <button
        type="button"
        className="cart__item__action cart__item__action--delete"
        onClick={async () => {
          // Nos quedamos en que si se elimina un producto del carrito no se elimina de la db
          const response = await removeProduct(
            user.idUser,
            cartItem.attributes.productos.data[0].id,
            logout
          );
          // console.log(response);
          if (response.data) {
            alert.success("Producto eliminado de tu carrito.");
            setCartItems((prev) =>
              prev.filter((item) => {
                // console.log(item);
                return item.id !== cartItem.id;
              })
            );
          } else {
            alert.error(
              "Error al eliminar el producto. Por favor, inténtalo de nuevo."
            );
          }
          // console.log("Clicked", product.id);
        }}
      >
        <i className="cart__item__action__icon fal fa-trash"></i>
      </button>
      {/* <div className="cart__item__actions-container">
            <div
              className="cart__item__action cart__item__action--delete"
              onClick={() => removeItem(product.product.id)}
            >
              <i className="cart__item__action__icon fal fa-trash"></i>
            </div>
          </div> */}
    </div>
  );
}

export default function CartList() {
  // const [user, setUser] = useState(undefined);
  // const [cartItems, setCartItems] = useState([]);
  const { auth, logout } = useAuth();
  const alert = useContext(AlertContext);
  const {
    removeProduct,
    totalPriceCart,
    increaseProduct,
    decreaseProduct,
    cartItems,
    setCartItems,
  } = useCart();
  // console.log(auth);
  // useEffect(
  //   (logout) => {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       setUser(response);
  //     })();
  //   },
  //   [auth]
  // );
  // console.log(products);
  // console.log(auth);

  // useEffect(() => {
  //   (async () => {
  //     if (Object.keys(auth).length !== 0) {
  //       const response = await getProductsCart(auth?.idUser, logout);
  //       if (response?.data.length > 0) {
  //         for (let index = 0; index < response.data.length; index++) {
  //           delete response.data[index].attributes.createdAt;
  //           delete response.data[index].attributes.updatedAt;
  //           delete response.data[index].attributes.publishedAt;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .createdAt;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .caratula;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .carcasa;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .condicion;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .correa;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .descripcion;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .informacionGeneral;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .publishedAt;
  //           delete response.data[index].attributes.productos.data[0].attributes
  //             .updatedAt;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.createdAt;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.publishedAt;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.updatedAt;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.caption;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.createdAt;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.ext;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.height;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.mime;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.previewUrl;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.provider_metadata;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.size;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.updatedAt;
  //           // delete response.data[index].attributes.productos?.data[index]
  //           //   .attributes.imagenes.data[index].attributes.width;

  //           // delete response.data[index].attributes.usuario.data.attributes
  //           //   .blocked;
  //           // delete response.data[index].attributes.usuario.data.attributes
  //           //   .confirmed;
  //           // delete response.data[index].attributes.usuario.data.attributes
  //           //   .createdAt;
  //           // delete response.data[index].attributes.usuario.data.attributes
  //           //   .provider;
  //           // delete response.data[index].attributes.usuario.data.attributes
  //           //   .updatedAt;
  //         }
  //         setCartItems(response.data);
  //         // console.log(response);
  //       } else setCartItems([]);
  //     }
  //   })();
  // }, [auth]);
  // console.log(cartItems);
  return (
    <section className="cart__products">
      <div className="cart__products__header">
        <div className="cart__products__header__title-container cart__products__header__title-container--product">
          <p className="cart__products__header__title">Producto</p>
        </div>
        <div className="cart__products__header__title-container cart__products__header__title-container--price">
          <p className="cart__products__header__title">Precio</p>
        </div>
        <div className="cart__products__header__title-container cart__products__header__title-container--quantity">
          <p className="cart__products__header__title">Cantidad</p>
        </div>
        {/* <div className="cart__products__header__title-container cart__products__header__title-container--subtotal">
          <p className="cart__products__header__title">Subtotal</p>
        </div>
        <div className="cart__products__header__title-container cart__products__header__title-container--actions">
          <p className="cart__products__header__title">Acciones</p>
        </div> */}
      </div>
      <div className="cart__item-container">
        {cartItems.length > 0 ? (
          cartItems?.map((cartItem, index) => (
            <CartItem
              alert={alert}
              cartItem={cartItem}
              index={index}
              user={auth}
              logout={logout}
              removeProduct={removeProduct}
              increaseProduct={increaseProduct}
              decreaseProduct={decreaseProduct}
              setCartItems={setCartItems}
              key={index}
            />
          ))
        ) : (
          <p className="text-center">Tu carrito está vacío...</p>
        )}
        <div className="cart__item__total-container">
          <p className="cart__item__total__header">Total </p>
          <p className="cart__item__total__value ml-2">
            {formatMoney(totalPriceCart)}
          </p>
        </div>
      </div>
      <div className="cart__action-container">
        <Link to="/catalogue" className="cart__action cart__action--shop">
          <p className="cart__action__text">Continuar comprando</p>
        </Link>
        {cartItems.length > 0 && (
          <Link
            to="/checkout"
            className="cart__action cart__action--checkout"
            // style={{ width: "max-content" }}
          >
            <p className="cart__action__text">Proceder a pago</p>
          </Link>
        )}
        {/* {user && (
            <Link to="/pago" className="cart__action cart__action--checkout">
              <p className="cart__action__text">Proceder a pago</p>
            </Link>
          )} */}
        {/* {!user && (
            <Link to="/cuenta" className="cart__action cart__action--checkout">
              <p className="cart__action__text">Proceder a pago</p>
            </Link>
          )} */}
      </div>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Col from "react-bootstrap/Col";

// Custom CSS
import "./orderList.styles.scss";

// Utils
import formatMoney from "../../utils/formatMoney";

export default function OrderList({ orderItems }) {
  // console.log(orderItems);
  return orderItems?.map((item, key) => (
    <Col xs="12" lg="4" className="orders__container__list" key={key}>
      <Link to={`/profile/orders/${item.id}`} className="order-card">
        <p>
          <span>Id de orden: </span>
          <span>{item.attributes.orderId}</span>
        </p>
        <p>
          <span>Cantidad de productos: </span>
          <span>{item.attributes.orderItems.length}</span>
        </p>
        <p>
          <span>Total: </span>
          <span>{formatMoney(item.attributes.total)} MXN</span>
        </p>
      </Link>
    </Col>
  ));
}

import { BASE_PATH } from "../utils/constants";

export async function getProducts(pageSize) {
  try {
    const sortItem = "sort=createdAt:asc";
    const url = `${BASE_PATH}/api/productos?${sortItem}&[populate]=*&pagination${
      pageSize > 0 ? `[pageSize]=${pageSize}` : `[pageSize]=100`
    }`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getPopularProducts() {
  try {
    const sortItem = "sort=createdAt:asc";
    const url = `${BASE_PATH}/api/productos-populares?${sortItem}&populate[producto][populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getProduct(id) {
  try {
    const url = `${BASE_PATH}/api/productos/${id}?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[marca][slug][$eq]=BRANDNAME
  // @desc     Get all products from a specific brand
  // @access   Public
*/
export async function getBrandProducts(brandName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/productos?[populate]=*&filters[marca][slug][$eq]=${brandName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[genero][slug][$eq]=GENRENAME
  // @desc     Get all products from a specific genre
  // @access   Public
*/
export async function getGenreProducts(genreName) {
  try {
    const url = `${BASE_PATH}/api/productos?[populate]=*&filters[genero][slug][$eq]=${genreName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/subastas/AUCTIONPRODUCID?[populate]=*
  // @desc     Get a specific auction product from an id
  // @access   Public
*/
export async function getAuctionProduct(id) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/subastas/${id}?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[condicion][slug][$eq]=CONDITIONNAME
  // @desc     Get all products from a specific condition
  // @access   Public
*/
export async function getConditionProducts(condition) {
  try {
    const url = `${BASE_PATH}/api/productos?[populate]=*&filters[condicion][slug][$eq]=${condition}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/portada-catalogo
  // @desc     Get hero background for Catalogue page
  // @access   Public
*/
export async function getHeroCatalogue() {
  try {
    const url = `${BASE_PATH}/api/portada-catalogo?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

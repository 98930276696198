import React from "react";
// import { Link } from "react-router-dom";

// Bootstrap components
// import Container from "react-bootstrap/Container";

// Custom Components
import CartList from "../components/CartList/CartList";

export default function Cart() {
  return (
    // <Container className="py-3">
    //   <h3>Tu carrito de compra</h3>
    //   <CartList />
    // </Container>
    <section className="cart">
      <div className="cart__container">
        <div className="cart__header">
          <p className="cart__title">Tu carrito de compra</p>
        </div>
        <CartList />
      </div>
    </section>
  );
}

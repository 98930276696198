import React, { useEffect } from "react";
import { BASE_PATH } from "../../utils/constants";

// Components
import CtaButton from "../CtaButton/CtaButton";

// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";

import ReactImageMagnify from "react-image-magnify";

export const JumbotronCarousel = () => {
  const initCarousel = () => {
    const slideContainer = document.querySelector(
      ".jumbotron__slide-container"
    );
    const slides = Array.from(slideContainer.children);
    const carouselNav = document.querySelector(".jumbotron__carousel__nav");
    const carouselPages = Array.from(carouselNav.children);

    const updatePage = (currentPage, wantedPage) => {
      currentPage.classList.remove("current-page");
      wantedPage.classList.add("current-page");
    };

    carouselPages.forEach((carouselPage, index) => {
      carouselPage.addEventListener("click", (event) => {
        slides.forEach((slide) => slide.classList.remove("current-slide"));
        slides[index].classList.add("current-slide");

        const currentPage = document.querySelector(".current-page");
        updatePage(currentPage, carouselPage);
        // setTimeout(() => {
        //   setSuccess(false);
        // }, 2000);
      });
    });
  };

  useEffect(() => {
    initCarousel();
  });

  return (
    <section className="jumbotron">
      <div className="jumbotron__container">
        <div className="jumbotron__carousel">
          <div className="jumbotron__slide-container">
            <div className="jumbotron__slide current-slide">
              <div className="jumbotron__slide__content">
                <h2 className="jumbotron__slide__title">
                  Descubre los últimos relojes de esta temporada
                </h2>
                <CtaButton
                  text="Descubre"
                  align="left"
                  extraContainerClasses="jumbotron__slide__cta-container"
                />
              </div>
            </div>
            <div className="jumbotron__slide">
              <div className="jumbotron__slide__content">
                <h2 className="jumbotron__slide__title">
                  Descubre los últimos relojes de esta temporada
                </h2>
                <CtaButton
                  text="Descubre"
                  align="left"
                  extraContainerClasses="jumbotron__slide__cta-container"
                />
              </div>
            </div>
            <div className="jumbotron__slide">
              <div className="jumbotron__slide__content">
                <h2 className="jumbotron__slide__title">
                  Descubre los últimos relojes de esta temporada
                </h2>
                <CtaButton
                  text="Descubre"
                  align="left"
                  extraContainerClasses="jumbotron__slide__cta-container"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron__carousel__nav">
          <button className="jumbotron__carousel__pageButton current-page"></button>
          <button className="jumbotron__carousel__pageButton"></button>
          <button className="jumbotron__carousel__pageButton"></button>
        </div>
      </div>
    </section>
  );
};

export const ProductCarousel = ({ productName, productImages }) => {
  const initCarousel = () => {
    // Product images carousel
    const slideContainer = document.querySelector(
      ".product-carousel__slide-container"
    );
    const slides = Array.from(slideContainer.children);
    const nextButton = document.querySelector(
      ".product-carousel__carousel__button--right"
    );
    const prevButton = document.querySelector(
      ".product-carousel__carousel__button--left"
    );
    const carouselNav = document.querySelector(
      ".product-carousel__carousel__nav"
    );
    const carouselPages = Array.from(carouselNav.children);

    const slideWidth = slides[0].getBoundingClientRect().width;

    slides[0]?.classList.add("current-slide");
    carouselPages[0]?.classList.add("current-page");

    // Slides layout
    const setSlideLayout = (slide, index) => {
      if (index !== 0) {
        slide.style.left = slideWidth * index + 20 + "px";
      } else {
        slide.style.left = slideWidth * 0 + "px";
      }
    };
    slides.forEach(setSlideLayout);

    const slideMovement = (slideContainer, currentSlide, wantedSlide) => {
      slideContainer.style.transform =
        "translateX(-" + wantedSlide.style.left + ")";

      // Update current slide class
      currentSlide.classList.remove("current-slide");
      wantedSlide.classList.add("current-slide");
    };

    const updatePage = (currentPage, wantedPage) => {
      currentPage.classList.remove("current-page");
      wantedPage.classList.add("current-page");
    };

    const hideShowButtons = (slides, prevButton, nextButton, wantedIndex) => {
      if (wantedIndex === 0) {
        prevButton.classList.add("v-hidden");
        nextButton.classList.remove("v-hidden");
      } else if (wantedIndex === slides.length - 1) {
        prevButton.classList.remove("v-hidden");
        nextButton.classList.add("v-hidden");
      } else {
        prevButton.classList.remove("v-hidden");
        nextButton.classList.remove("v-hidden");
      }
    };

    // Left movement on left click
    prevButton.addEventListener("click", (event) => {
      const currentSlide = slideContainer.querySelector(".current-slide");
      const prevSlide = currentSlide.previousElementSibling;

      const currentPage = carouselNav.querySelector(".current-page");
      const prevPage = currentPage.previousElementSibling;
      const prevIndex = carouselPages.findIndex((page) => page === prevPage);

      slideMovement(slideContainer, currentSlide, prevSlide);
      updatePage(currentPage, prevPage);
      hideShowButtons(slides, prevButton, nextButton, prevIndex);
    });

    // Right movement on right click
    nextButton.addEventListener("click", (event) => {
      const currentSlide = slideContainer.querySelector(".current-slide");
      const nextSlide = currentSlide.nextElementSibling;

      const currentPage = carouselNav.querySelector(".current-page");
      const nextPage = currentPage.nextElementSibling;
      const nextIndex = carouselPages.findIndex((page) => page === nextPage);

      slideMovement(slideContainer, currentSlide, nextSlide);
      updatePage(currentPage, nextPage);
      hideShowButtons(slides, prevButton, nextButton, nextIndex);
    });

    carouselNav.addEventListener("click", (event) => {
      // Get clicked page
      const wantedPage = event.target.closest("button");
      if (!wantedPage) return;

      const currentPage = carouselNav.querySelector(".current-page");
      const currentSlide = slideContainer.querySelector(".current-slide");
      const wantedIndex = carouselPages.findIndex(
        (page) => page === wantedPage
      );
      const wantedSlide = slides[wantedIndex];

      slideMovement(slideContainer, currentSlide, wantedSlide);
      updatePage(currentPage, wantedPage);
      hideShowButtons(slides, prevButton, nextButton, wantedIndex);
    });
  };

  useEffect(() => {
    initCarousel();
  });

  return (
    <section className="product-carousel">
      <div className="product-carousel__container">
        <div className="product-carousel__carousel">
          <button className="product-carousel__carousel__button product-carousel__carousel__button--left v-hidden">
            <i className="product-carousel__carousel__button__icon fal fa-arrow-left"></i>
          </button>
          <div className="product-carousel__slide-container">
            {productImages.map((productImage, index) => {
              // console.log(productImage);
              return (
                <div key={index} className="product-carousel__slide">
                  <ReactImageMagnify
                    // className="textMagnify"
                    {...{
                      // className: "product-carousel__slide__image",
                      enlargedImageContainerClassName:
                        "product-carousel__slide__magnify",
                      smallImage: {
                        alt: productName,
                        isFluidWidth: true,
                        src: productImage.attributes.url,
                      },
                      largeImage: {
                        src: productImage.attributes.url,
                        width: 800,
                        height: 1200,
                      },
                    }}
                  />
                  {/* <Zoom scrollableEl=".product-carousel__slide">
                    <img
                      // src={`${BASE_PATH}${productImage.attributes.url}`}
                      src={productImage.attributes.url}
                      alt={productName}
                      className="product-carousel__slide__image"
                    />
                  </Zoom> */}
                </div>
              );
            })}
          </div>
          <button className="product-carousel__carousel__button product-carousel__carousel__button--right">
            <i className="product-carousel__carousel__button__icon fal fa-arrow-right"></i>
          </button>
        </div>
        <div className="product-carousel__carousel__nav">
          {productImages.map((productImage, index) => (
            <button
              key={index}
              className="product-carousel__carousel__pageButton"
            ></button>
          ))}
        </div>
      </div>
    </section>
  );
};

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Styles
import "../assets/scss/pages/order.scss";

// API
import { getMeApi } from "../api/user";
import { getOrder } from "../api/order";

// Hooks
import useAuth from "../hooks/useAuth";

// Utils
import formatMoney from "../utils/formatMoney";

export default function Order() {
  const { id } = useParams();
  const [user, setUser] = useState(undefined);
  const [order, setOrder] = useState({});
  const { auth, logout } = useAuth();
  useEffect(() => {
    (async () => {
      const response = await getMeApi(logout);
      setUser(response);
    })();
  }, [auth]);

  useEffect(
    (logout) => {
      (async () => {
        if (user !== undefined) {
          const response = await getOrder(id, logout);
          if (response?.data !== null) {
            setOrder(response.data);
            // console.log(response);
          } else setOrder([]);
        }
      })();
    },
    [user]
  );
  // console.log(order);
  return (
    <div className="order__wrapper">
      <Container className="order__container">
        <Link to="/profile">Regresar</Link>
        <h2>Orden confirmada</h2>
        <p>
          <span>Id de orden: </span>
          <span>{order.attributes?.orderId}</span>
        </p>
        <p>
          <span>Id de pago: </span>
          <span>{order.attributes?.token}</span>
        </p>
        <p>
          <span>Cantidad de productos: </span>
          <span>{order.attributes?.orderItems.length}</span>
        </p>
        <div className="items">
          <h4>Productos:</h4>
          {order.attributes?.orderItems.map((item) => (
            <div className="order-item" key={item.id}>
              <p>
                <span>Producto: </span>
                <span>
                  {item.attributes.productos.data[0].attributes.nombre}
                </span>
              </p>
              <p>
                <span>Precio: </span>
                <span>
                  {formatMoney(
                    item.attributes.productos.data[0].attributes.precio
                  )}{" "}
                  MXN
                </span>
              </p>
            </div>
          ))}
        </div>
        <h3>
          <span>Total: </span>
          <span>{formatMoney(order.attributes?.total)} MXN</span>
        </h3>
      </Container>
    </div>
  );
}

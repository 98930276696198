import React, { useState, useEffect } from "react";

// Context
import { useProducts } from "../context/ProductsContext";

// API
import { getProducts, getHeroCatalogue } from "../api/product";
import { getBrands } from "../api/brand";
import { getWatchBands } from "../api/watch-band";
import { getWatchGenres } from "../api/genre";

// Bootstrap components
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Util
import { toggleClass } from "../utils/toggleClass";

// Custom Components
import ProductCard from "../components/ProductCard/ProductCard";
import CustomPagination from "../components/Pagination/Pagination";

// Assets
import FilterIcon from "../assets/images/filter-icon.svg";

export default function Catalogue() {
  const [products, setProducts] = useState(null);
  const [pageHeroBackground, setPageHeroBackground] = useState({});

  const { brands } = useProducts();

  const [loading, setLoading] = useState(false);
  // const [brands, setBrands] = useState([]);
  const [watchBands, setWatchBands] = useState([]);
  const [watchGenres, setWatchGenres] = useState([]);

  const [originalProducts, setOriginalProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts = products?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let state = {
    userInputContainerClicked: false,
    searchTerm: "",
    passingTags: {
      search: {
        inputTerm: "",
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
      calibre: {
        Automático: false,
        Cuarzo: false,
        Cuerda: false,
      },
      tipoCorrea: {
        "Acero inoxidable": false,
        "Bitono Acero y Chapa de Oro": false,
        Caucho: false,
        Cerámica: false,
        "Chapa de Oro": false,
        Nylon: false,
        Piel: false,
        Titanio: false,
      },
      genero: {
        hombre: false,
        Mujer: false,
        unisex: false,
      },
      marca: {
        Bulova: false,
        Citizen: false,
        IWC: false,
        Longines: false,
        Mido: false,
        Omega: false,
        "Porsche Design": false,
      },
      tipo: {
        Japonés: false,
        Suizo: false,
        Vintage: false,
      },
    },
  };

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProducts2, setFilteredProducts2] = useState([]);
  const [testinState, setTestingState] = useState(state);
  const [category, setCategory] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const [categories, setCategories] = useState([]);

  const allFilterClickListener = (e, filterProp) => {
    // console.log("FILTER clicked", e);
    const name = e;
    setTestingState((prevState) => ({
      passingTags: {
        ...prevState.passingTags,
        [filterProp]: {
          ...prevState.passingTags[filterProp],
          [name]: !prevState.passingTags[filterProp][name],
        },
      },
    }));
  };

  const filteredCollected = () => {
    const collectedTrueKeys = {
      calibre: [],
      tipoCorrea: [],
      genero: [],
      marca: [],
      tipo: [],
    };
    const { calibre, genero, correa, marca, tipo, tipoCorrea } =
      testinState.passingTags;
    for (let calibreKey in calibre) {
      // console.log(calibreKey);
      // console.log(calibre);
      if (calibre[calibreKey]) collectedTrueKeys.calibre.push(calibreKey);
    }
    for (let generoKey in genero) {
      // console.log(generoKey);
      // console.log(genero[generoKey]);
      if (genero[generoKey]) collectedTrueKeys.genero.push(generoKey);
    }
    for (let tipoCorreaKey in tipoCorrea) {
      // console.log(tipoCorreaKey);
      // console.log(tipoCorrea[tipoCorreaKey]);
      if (tipoCorrea[tipoCorreaKey])
        collectedTrueKeys.tipoCorrea.push(tipoCorreaKey);
    }
    for (let marcaKey in marca) {
      if (marca[marcaKey]) collectedTrueKeys.marca.push(marcaKey);
    }
    for (let tipoKey in tipo) {
      if (tipo[tipoKey]) collectedTrueKeys.tipo.push(tipoKey);
    }
    // console.log(collectedTrueKeys);
    return collectedTrueKeys;
  };

  // console.log("filteredCollected", filteredCollected());

  // Resource: https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
  const multiPropsFilter = (originalProducts, filters) => {
    const filterKeys = Object.keys(filters);
    // console.log(
    //   originalProducts?.filter((product) => {
    //     // console.log("product", product);
    //     return filterKeys.every((key) => {
    //       if (!filters[key].length) return true;
    //       // console.log("includes", filters[key].includes(product[key].attributes.gener))
    //       // console.log(product[key]);
    //       // In addition to the resource, I added below five lines because product[key] is an array for material attribute.
    //       // console.log(
    //       //   "product?.attributes[key].data.attributes.nombre",
    //       //   product?.attributes[key].data.attributes.nombre.toLowerCase()
    //       // );
    //       return filters[key].includes(
    //         product?.attributes[key].data.attributes.nombre
    //       );
    //     });
    //   })
    // );
    return originalProducts?.filter((product) => {
      // console.log("product", product);
      return filterKeys.every((key) => {
        // console.log("key", key);
        if (!filters[key].length) return true;
        // console.log(filters[key]);
        // console.log("productkey", product?.attributes[key]);
        // console.log("includes", filters[key].includes(product[key].attributes.gener))
        // console.log(product[key]);
        // In addition to the resource, I added below five lines because product[key] is an array for material attribute.
        // if (Array.isArray(product[key])) {
        //   // for (let i = 0; i < product[key].length; i++) {
        //   //   return filters[key].includes(product[key][i].toLowerCase());
        //   // }
        //   // console.log("pasó");
        //   return product[key].some((keyEle) => filters[key].includes(keyEle));
        // }
        // console.log(
        //   "product?.attributes[key].data.attributes.nombre",
        //   product?.attributes[key].data.attributes.nombre.toLowerCase()
        // );
        // console.log(
        //   filters[key].includes(product?.attributes[key].data.attributes.nombre)
        // );
        return filters[key].includes(
          product?.attributes[key].data.attributes.nombre
        );
      });
    });
  };

  const searchProducts = () => {
    const filteredProducts2 = multiPropsFilter(
      originalProducts,
      filteredCollected()
    );
    // console.log("filteredProducts2", filteredProducts2);
    return filteredProducts2;
  };

  // setFilteredProducts2(searchProducts());
  // console.log(filteredProducts2);
  // // useEffect(() => {
  // //   // searchProducts();
  // // }, [testinState, categories]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getProducts();
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
          delete response.data[index].attributes.caratula;
          delete response.data[index].attributes.carcasa;
          delete response.data[index].attributes.condicion;
          delete response.data[index].attributes.correa;
          delete response.data[index].attributes.descripcion;
          delete response.data[index].attributes.eta;
          delete response.data[index].attributes.informacionGeneral;
          delete response.data[index].attributes.genero.data.attributes
            .createdAt;
          delete response.data[index].attributes.genero.data.attributes
            .publishedAt;
          delete response.data[index].attributes.genero.data.attributes
            .updatedAt;
          delete response.data[index].attributes.marca.data.attributes
            .createdAt;
          delete response.data[index].attributes.marca.data.attributes
            .publishedAt;
          delete response.data[index].attributes.marca.data.attributes
            .updatedAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .createdAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .publishedAt;
          delete response.data[index].attributes.tipoCorrea.data.attributes
            .updatedAt;
        }
        setProducts(response.data);
        setOriginalProducts(response.data);
        setTotalPages(response.meta.pagination.total);
      }
      setLoading(false);
      return response;
      // if (query.platform) {
      // }
    })();
  }, []);

  // useEffect(() => {
  //   // declare the data fetching function
  //   setLoading(true);
  //   const fetchProductsData = async () => {
  //     const response = await getProducts();
  //     if (response !== null) {
  //       for (let index = 0; index < response.data.length; index++) {
  //         delete response.data[index].attributes.createdAt;
  //         delete response.data[index].attributes.publishedAt;
  //         delete response.data[index].attributes.updatedAt;
  //         delete response.data[index].attributes.caratula;
  //         delete response.data[index].attributes.carcasa;
  //         delete response.data[index].attributes.condicion;
  //         delete response.data[index].attributes.correa;
  //         delete response.data[index].attributes.descripcion;
  //         delete response.data[index].attributes.eta;
  //         delete response.data[index].attributes.informacionGeneral;
  //         delete response.data[index].attributes.genero.data.attributes
  //           .createdAt;
  //         delete response.data[index].attributes.genero.data.attributes
  //           .publishedAt;
  //         delete response.data[index].attributes.genero.data.attributes
  //           .updatedAt;
  //         delete response.data[index].attributes.marca.data.attributes
  //           .createdAt;
  //         delete response.data[index].attributes.marca.data.attributes
  //           .publishedAt;
  //         delete response.data[index].attributes.marca.data.attributes
  //           .updatedAt;
  //         delete response.data[index].attributes.tipoCorrea.data.attributes
  //           .createdAt;
  //         delete response.data[index].attributes.tipoCorrea.data.attributes
  //           .publishedAt;
  //         delete response.data[index].attributes.tipoCorrea.data.attributes
  //           .updatedAt;
  //       }
  //       setProducts(response.data);
  //       setOriginalProducts(response.data);
  //       setTotalPages(response.meta.pagination.total);
  //     }
  //     setLoading(false);
  //     return response;
  //   };

  //   // call the function
  //   fetchProductsData().catch(console.error);
  // }, []);

  const initFilters = () => {
    const filters = Array.from(document.querySelectorAll(".filter__item"));
    const products = Array.from(
      document.querySelectorAll(".products__product")
    );
    let activeFilters = [];

    const resetFilters = (products, filters) => {
      products.forEach((item) => item.classList.remove("hidden"));
      filters.forEach((filter) => filter.classList.remove("active"));
      activeFilters = [];
    };

    const showCurrentElements = (activeFilters, products) => {
      activeFilters.forEach((currentFilter) => {
        products.forEach((item) => {
          if (
            item.classList.contains(currentFilter) &&
            item.classList.contains("hidden")
          )
            item.classList.remove("hidden");
        });
      });
    };

    const removeFilter = (products, activeFilters, filter) => {
      // Remove filter from activeFilters array
      activeFilters.splice(
        activeFilters.findIndex((currentFilter) => {
          return currentFilter === filter;
        }),
        1
      );

      // Hide this filter elements
      products.forEach((item) => {
        if (
          item.classList.contains(filter) &&
          !item.classList.contains("hidden")
        )
          item.classList.add("hidden");
      });

      // Reload removed elements if they belong to another active filter
      showCurrentElements(activeFilters, products);
    };

    const addFilter = (products, activeFilters, filter) => {
      // Add current filter to active array
      activeFilters.push(filter);

      // Apply visibility to all active filter elements
      showCurrentElements(activeFilters, products);
    };

    filters.forEach((filter) => {
      filter.addEventListener("click", (event) => {
        // Get clicked filter
        const filterSelector = filter.dataset.filter;

        if (filterSelector === "all") resetFilters(products, filters);
        else {
          products.forEach((item) => item.classList.add("hidden"));
          if (filter.classList.contains("active")) {
            removeFilter(products, activeFilters, filterSelector);
            filter.classList.remove("active");
            if (activeFilters.length === 0) resetFilters(products, filters);
          } else {
            addFilter(products, activeFilters, filterSelector);
            filter.classList.add("active");
          }
        }
      });
    });
  };

  useEffect(() => {
    (async () => {
      const response = await getWatchGenres();
      if (response !== null) {
        // for (let index = 0; index < response.data.length; index++) {
        //   delete response.data[index].attributes.createdAt;
        //   delete response.data[index].attributes.publishedAt;
        //   delete response.data[index].attributes.updatedAt;
        // }
        setWatchGenres(response);
      }
      // initFilters();
      // initFilters();
      // if (query.platform) {
      // }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getWatchBands();
      if (response !== null) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.updatedAt;
        }
        setWatchBands(response);
      }
      // initFilters();
      // initFilters();
      // if (query.platform) {
      // }
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const response = await getBrands();
  //     if (response !== null) {
  //       for (let index = 0; index < response.data.length; index++) {
  //         delete response.data[index].attributes.createdAt;
  //         delete response.data[index].attributes.publishedAt;
  //         delete response.data[index].attributes.updatedAt;
  //       }
  //       setBrands(response);
  //     }
  //     // initFilters();
  //     // initFilters();
  //     // if (query.platform) {
  //     // }
  //   })();
  // }, []);

  // console.log(products);

  const initDropdownToggle = () => {
    const filterContainer = document.querySelector(".filters");

    filterContainer.addEventListener("click", (event) => {
      const clickedFilter = event.target.closest(
        ".filters__filter:not(.filters__filter--toggle"
      );

      if (clickedFilter?.classList.contains("filters__filter--dropdown")) {
        if (event.target.closest(".filters__filter__dropdown-menu") === null) {
          const dropdownMenu = clickedFilter.querySelector(
            ".filters__filter__dropdown-menu"
          );

          toggleClass(clickedFilter, "open");
          toggleClass(dropdownMenu, "open");
        }
      }
    });

    if (window.innerWidth > 991) return;

    const filtersHeader = document.querySelector(".filters__header");
    filtersHeader.addEventListener("click", (event) => {
      const filterContainer = document.querySelector(
        ".filters__filter-container"
      );
      filterContainer.classList.contains("show")
        ? filterContainer.classList.remove("show")
        : filterContainer.classList.add("show");
    });
  };

  const filterFunction = (text) => {
    const filter = products?.filter(
      (product) => product.attributes.tipoCorrea.data.attributes.nombre === text
    );
    setFilteredProducts(filter);
  };

  const filterGenreFunction = (text) => {
    const filter = products?.filter(
      (product) => product.attributes.genero.data.attributes.nombre === text
    );
    setFilteredProducts(filter);
  };
  const filterBrandFunction = (text) => {
    const filter = products?.filter(
      (product) => product.attributes.marca.data.attributes.nombre === text
    );
    setFilteredProducts(filter);
  };

  const filterTypeFunction = (text) => {
    console.log(text.charAt(0).toUpperCase() + text.slice(1));
    const filter = products?.filter(
      (product) =>
        product.attributes.tipo.data.attributes.nombre ===
        text.charAt(0).toUpperCase() + text.slice(1)
    );
    setFilteredProducts(filter);
  };

  const filterCaliberFunction = (text) => {
    const filter = products?.filter(
      (product) =>
        product.attributes.calibre?.data?.attributes.nombre ===
        text.charAt(0).toUpperCase() + text.slice(1)
    );
    setFilteredProducts(filter);
  };

  useEffect(() => {
    initDropdownToggle();
  }, []);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getHeroCatalogue();
      if (response !== null) {
        // for (let index = 0; index < response.data.length; index++) {
        //   delete response.data[index].attributes.createdAt;
        //   delete response.data[index].attributes.publishedAt;
        //   delete response.data[index].attributes.updatedAt;
        // }
        setPageHeroBackground(response);
        // setLoading(false);
      }
      // setLoading(false);
    })();
  }, []);

  const applyFilters = () => {
    let updatedList = products;

    // // const cuisinesChecked = cuisines
    // //   .filter((item) => item.checked)
    // //   .map((item) => item.label.toLowerCase());

    if (categories.length !== 0) {
      updatedList = originalProducts.filter((product) =>
        categories.includes(
          product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase()
        )
      );

      setProducts(updatedList);
      return;
      // currentPosts = updatedList.slice(indexOfFirstPost, indexOfLastPost);
    } else {
      setProducts(originalProducts);
    }
    // // currentPosts = updatedList?.slice(indexOfFirstPost, indexOfLastPost);
    // updatedList =
    //   categories.length !== 0
    //     ? products?.filter((product) =>
    //         categories.includes(
    //           product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase()
    //         )
    //       )
    //     : originalProducts;
    // console.log(updatedList.length);
    // setProducts(updatedList);
  };

  const applyBrandFilters = () => {
    let updatedList = products;
    if (categories.length !== 0) {
      updatedList = originalProducts.filter((product) =>
        categories.includes(
          product.attributes.marca.data.attributes.nombre.toLowerCase()
        )
      );

      setProducts(updatedList);
      return;
    } else {
      setProducts(originalProducts);
    }
  };

  // const filterByReference = () => {
  //   let res = [];
  //   if (categories.length !== 0) {
  //     res = products.filter((el) => {
  //       return categories.forEach((element) => {
  //         products.find(
  //           (product) =>
  //             element ===
  //               product.attributes.genero.data.attributes.nombre.toLowerCase() ||
  //             element ===
  //               product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ||
  //             element ===
  //               product.attributes.marca.data.attributes.nombre.toLowerCase()
  //         );
  //       });
  //     });
  //     // let hh = categories.forEach((index) => {
  //     //   res.push(
  //     //     products.find(
  //     //       (element) =>
  //     //         element.attributes.genero.data.attributes.nombre.toLowerCase() ===
  //     //           index ||
  //     //         element.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ===
  //     //           index ||
  //     //         element.attributes.marca.data.attributes.nombre.toLowerCase() ===
  //     //           index
  //     //     )
  //     //   );
  //     //   return res;
  //     // });
  //     // console.log(hh);
  //     setProducts(res);
  //   } else {
  //     setProducts(originalProducts);
  //   }
  // };

  /*---------- WORKING VERISON -----------*/
  const filterByReference = () => {
    let res = products;
    if (categories.length !== 0) {
      res = res.filter((el) => {
        return categories.find((element) => {
          return (
            element ===
              el.attributes.genero.data.attributes.nombre.toLowerCase() ||
            element ===
              el.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ||
            element ===
              el.attributes.marca.data.attributes.nombre.toLowerCase() ||
            element ===
              el.attributes.tipo.data.attributes.nombre.toLowerCase() ||
            element ===
              el.attributes.calibre.data.attributes.nombre.toLowerCase()
          );
        });
      });
      setProducts(res);
      return;
    } else {
      setProducts(originalProducts);
    }
  };

  // const filterByReference2 = () => {
  //   // let res = products;
  //   // let productsMatch = [];
  //   // if (categories.length !== 0) {
  //   //   if (categories.length === 1) {
  //   //     categories.forEach((element) => {
  //   //       productsMatch = originalProducts.filter(
  //   //         (product) =>
  //   //           element ===
  //   //             product.attributes.genero.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.marca.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipo.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.calibre.data.attributes.nombre.toLowerCase()
  //   //       );
  //   //     });
  //   //   } else {
  //   //     categories.forEach((element) => {
  //   //       productsMatch = res.filter(
  //   //         (product) =>
  //   //           element ===
  //   //             product.attributes.genero.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipoCorrea.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.marca.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.tipo.data.attributes.nombre.toLowerCase() ||
  //   //           element ===
  //   //             product.attributes.calibre.data.attributes.nombre.toLowerCase()
  //   //       );
  //   //     });
  //   //   }
  //   //   console.log(productsMatch);
  //   //   setProducts(productsMatch);
  //   //   return;
  //   // } else {
  //   //   setProducts(originalProducts);
  //   // }
  //   // var data = [
  //   //   { firstName: "John", lastName: "Doe", age: 40 },
  //   //   { firstName: "Foo", lastName: "Bar", age: 46 },
  //   // ];
  //   // var keywords = ["Doe", 46];
  //   var map = {};

  //   if (categories.length > 0) {
  //     products?.forEach((x) => {
  //       let genero = x.attributes.genero.data.attributes.nombre.toLowerCase();
  //       let values = map[genero];
  //       if (!values) {
  //         values = [];
  //       }
  //       values.push(x);
  //       // console.log(map[genero]);
  //       map[genero] = values;

  //       let tipoCorrea =
  //         x.attributes.tipoCorrea.data.attributes.nombre.toLowerCase();
  //       let ageValues = map[tipoCorrea];
  //       if (!ageValues) {
  //         ageValues = [];
  //       }
  //       if (
  //         categories.every((i) =>
  //           categories.includes(
  //             x.attributes.genero.data.attributes.nombre.toLowerCase()
  //           )
  //         )
  //       ) {
  //         ageValues.push(x);
  //         map[tipoCorrea] = ageValues;
  //       }
  //       // console.log(map[genero], "map");
  //     });
  //     var result = [];
  //     categories.forEach((x) => {
  //       var tmpResult = map[x];
  //       if (tmpResult) {
  //         console.log("adding values");
  //         result.push(...tmpResult);
  //       }
  //     });
  //     // console.log(result, "result");
  //     setProducts(result);
  //   } else {
  //     setProducts(originalProducts);
  //   }
  // };

  // useEffect(() => {
  //   filterByReference2();
  //   // setCurrentsPosts(products?.slice(indexOfFirstPost, indexOfLastPost));
  // }, [categories.length]);

  // useEffect(() => {
  //   if (
  //     brands.data?.length > 0 &&
  //     watchBands.data?.length > 0 &&
  //     watchGenres.data?.length > 0
  //   ) {
  //     initFilters();
  //   }
  // }, [brands.data?.length, watchBands.data?.length, watchGenres.data?.length]);
  return (
    // <Container>
    //   <p>Hello this is catalogue</p>
    //   <div className="products">
    //     <div className="products__container">
    //       <Row>
    //         {products?.data.map((product, key) => (
    //           <ProductCard product={product} key={key} />
    //         ))}
    //       </Row>
    //     </div>
    //   </div>
    // </Container>
    <section className="catalogue">
      <div
        className="jumbotron"
        style={{
          backgroundImage: `url(${pageHeroBackground?.data?.attributes.portada.data.attributes.url})`,
        }}
      ></div>
      <Row>
        <section
          className="filters col-12 col-lg-3"
          // onClick={() => setExpanded(expanded ? false : "show")}
        >
          <div className="filters__container">
            <div className="filters__header">
              <img src={FilterIcon} alt="Filtros" className="filters__icon" />
              <p className="filters__title">Filtros</p>
            </div>
            <div className="filters__filter-container">
              <div
                className="filters__filter filters__filter--clear filter__item"
                data-filter="all"
              >
                <div className="filters__filter__content">
                  <p
                    className="filters__filter__name filters__filter__name--small"
                    onClick={() => {
                      const selectedFilter = document.querySelectorAll(
                        ".filters__filter__dropdown-menu__item__name"
                      );
                      // const filtersDropdownMenu = document.querySelectorAll(
                      //   ".filters__filter__dropdown-menu"
                      // );
                      // const filtersDropdown = document.querySelectorAll(
                      //   ".filters__filter--dropdown"
                      // );
                      selectedFilter.forEach((filter) =>
                        filter.classList.remove("active")
                      );
                      // filtersDropdown.forEach((filter) =>
                      //   filter.classList.remove("open")
                      // );
                      // filtersDropdownMenu.forEach((filter) =>
                      //   filter.classList.remove("open")
                      // );
                      // selectedFilter.classList.remove("active");
                      // filtersDropdownMenu.classList.remove("show");
                      setFilteredProducts([]);
                      setCategories([]);
                      setCategory("");
                      setTestingState(state);
                    }}
                  >
                    Limpiar filtros <i className="far fa-times"></i>
                  </p>
                </div>
              </div>
              {/* <div
                className="filters__filter filters__filter--toggle filter__item"
                data-filter="discount"
              >
                <div className="filters__filter__content">
                  <p className="filters__filter__name filters__filter__name--small">
                    Con descuento
                  </p>
                  <div className="filters__toggle">
                    <input
                      type="checkbox"
                      className="filters__toggle__checkbox"
                      id="filters__toggle--discount"
                    />
                    <label
                      htmlFor="filters__toggle--discount"
                      className="filters__toggle__btn"
                    ></label>
                  </div>
                </div>
              </div> */}
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Género</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {watchGenres.data?.map((watchGenre, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={watchGenre.attributes.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            // console.log(e.target);
                            allFilterClickListener(
                              watchGenre.attributes.nombre,
                              "genero"
                            );
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");
                            if (
                              categories.includes(
                                watchGenre.attributes.nombre.toLowerCase()
                              )
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !==
                                    watchGenre.attributes.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                watchGenre.attributes.nombre.toLowerCase(),
                              ]);
                            }
                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterGenreFunction(watchGenre.attributes.nombre);
                            //   setCategory(watchGenre.attributes.nombre);
                            // } else {
                            //   filterGenreFunction([]);
                            //   setCategory("");
                            // }
                            // console.log(
                            //   products?.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === watchGenre.attributes.nombre
                            //   )
                            // );
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {watchGenre.attributes.nombre}
                          </p>
                        </li>
                      ))}
                      {/* <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="hombre"
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Hombre
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="mujer"
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Mujer
                        </p>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Correa</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {watchBands.data?.map((watchBand, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={watchBand.attributes.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            allFilterClickListener(
                              watchBand.attributes.nombre,
                              // .split(" ")
                              // .join("_"),
                              "tipoCorrea"
                            );
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            // const filterMenu =
                            //   document.querySelector(".filter__item");
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");
                            // setProducts(
                            //   products?.data.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === watchBand.attributes.nombre
                            //   )
                            // );
                            if (
                              categories.includes(
                                watchBand.attributes.nombre.toLowerCase()
                              )
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !==
                                    watchBand.attributes.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                watchBand.attributes.nombre.toLowerCase(),
                              ]);
                            }
                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterFunction(watchBand.attributes.nombre);
                            //   setCategory(watchBand.attributes.nombre);
                            // } else {
                            //   setFilteredProducts([]);
                            //   setCategory("");
                            // }
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {watchBand.attributes.nombre.toLowerCase()}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Marca</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      {brands.data?.map((brand, key) => (
                        <li
                          className="filters__filter__dropdown-menu__item filter__item"
                          data-filter={brand.attributes.nombre.toLowerCase()}
                          key={key}
                          onClick={(e) => {
                            // console.log(e.target);
                            allFilterClickListener(
                              brand.attributes.nombre,
                              // .toLowerCase()
                              // .split(" ")
                              // .join("_"),
                              "marca"
                            );
                            const filtersDropdownMenu = document.querySelector(
                              ".filters__filter-container"
                            );
                            filtersDropdownMenu.classList.remove("show");
                            e.target.classList.toggle("active");

                            if (
                              categories.includes(
                                brand.attributes.nombre.toLowerCase()
                              )
                            ) {
                              setCategories(
                                categories.filter(
                                  (category) =>
                                    category !==
                                    brand.attributes.nombre.toLowerCase()
                                )
                              );
                            } else {
                              setCategories([
                                ...categories,
                                brand.attributes.nombre.toLowerCase(),
                              ]);
                            }

                            // if (
                            //   filteredProducts.length === 0 &&
                            //   category === ""
                            // ) {
                            //   filterBrandFunction(brand.attributes.nombre);
                            //   setCategory(brand.attributes.nombre);
                            // } else {
                            //   filterGenreFunction([]);
                            //   setCategory("");
                            // }
                            // console.log(
                            //   products?.filter(
                            //     (product) =>
                            //       product.attributes.tipoCorrea.data.attributes
                            //         .nombre === brand.attributes.nombre
                            //   )
                            // );
                          }}
                        >
                          <p className="filters__filter__dropdown-menu__item__name">
                            {brand.attributes.nombre}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Tipo</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="japones"
                        onClick={(e) => {
                          allFilterClickListener("Japonés", "tipo");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("japonés")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "japonés"
                              )
                            );
                          } else {
                            setCategories([...categories, "japonés"]);
                          }

                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterTypeFunction("japonés");
                          //   setCategory("japones");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Japonés
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="suizo"
                        onClick={(e) => {
                          allFilterClickListener("Suizo", "tipo");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("suizo")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "suizo"
                              )
                            );
                          } else {
                            setCategories([...categories, "suizo"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterFunction("suizo");
                          //   setCategory("suizo");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Suizo
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="vintage"
                        onClick={(e) => {
                          allFilterClickListener("Vintage", "tipo");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("vintage")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "vintage"
                              )
                            );
                          } else {
                            setCategories([...categories, "vintage"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterFunction("vintage");
                          //   setCategory("vintage");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Vintage
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filters__filter filters__filter--dropdown">
                <div className="filters__filter__content">
                  <p className="filters__filter__name">Calibre</p>
                  <div className="filters__filter__dropdown-menu">
                    <ul className="filters__filter__dropdown-menu__item-container">
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="automático"
                        onClick={(e) => {
                          allFilterClickListener("Automático", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("automático")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "automático"
                              )
                            );
                          } else {
                            setCategories([...categories, "automático"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filtersDropdownMenu.classList.remove("active");
                          //   filterCaliberFunction("automático");
                          //   setCategory("automático");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Automático
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="cuarzo"
                        onClick={(e) => {
                          allFilterClickListener("Cuarzo", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("cuarzo")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "cuarzo"
                              )
                            );
                          } else {
                            setCategories([...categories, "cuarzo"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterCaliberFunction("cuarzo");
                          //   setCategory("cuarzo");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Cuarzo
                        </p>
                      </li>
                      <li
                        className="filters__filter__dropdown-menu__item filter__item"
                        data-filter="cuerda"
                        onClick={(e) => {
                          allFilterClickListener("Cuerda", "calibre");
                          const filtersDropdownMenu = document.querySelector(
                            ".filters__filter-container"
                          );
                          filtersDropdownMenu.classList.remove("show");
                          e.target.classList.toggle("active");
                          if (categories.includes("cuerda")) {
                            setCategories(
                              categories.filter(
                                (category) => category !== "cuerda"
                              )
                            );
                          } else {
                            setCategories([...categories, "cuerda"]);
                          }
                          // if (
                          //   filteredProducts.length === 0 &&
                          //   category === ""
                          // ) {
                          //   filterCaliberFunction("cuerda");
                          //   setCategory("cuerda");
                          // } else {
                          //   setFilteredProducts([]);
                          //   setCategory("");
                          // }
                        }}
                      >
                        <p className="filters__filter__dropdown-menu__item__name">
                          Cuerda
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="products col-12 col-lg-9">
          <div className="products__container">
            <div className="products__header">
              <h2 className="products__title">Nuestra colección</h2>
              <p className="products__text">
                Disfruta de nuestros ejemplares de los cuales encontrarás una
                excelente calidad entre ellos relojes nuevos, usados, suizos,
                japonenes, vintage, con calibres automáticos, de cuarzo y de
                cuerda. Todos 100% originales, verificados previamente por
                personal capacitado. Y con los mejores precios en el mercado.
              </p>
            </div>
            <div className="products__product-container">
              {/* {!loading && data.allProductos?.length > 0 ? (
              data.allProductos.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))
            ) : (
              <p className="products__emtpy">Cargando productos...</p>
            )} */}
              <Row>
                {/* {filteredProducts?.length > 0 &&
                  filteredProducts?.map((product, key) => (
                    <ProductCard product={product} key={key} />
                  ))} */}

                {/* {filteredProducts?.length === 0 && categories.length === 0 && (
                  <p className="pt-3">No hay productos</p>
                )} */}
                {/* {filteredProducts?.length < 1 && (products?.length > 0 && (
                  products?.map((product, key) => (
                    <ProductCard product={product} key={key} />
                  ))}) */}

                {loading ? (
                  <p className="pt-3">Cargando productos...</p>
                ) : currentPosts?.length > 0 && categories.length === 0 ? (
                  currentPosts?.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))
                ) : null}

                {!loading && categories.length > 0
                  ? searchProducts()
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((product, key) => (
                        <ProductCard
                          product={product}
                          key={key}
                          loading={loading}
                        />
                      ))
                  : null}
                {!loading &&
                categories.length > 0 &&
                searchProducts().length === 0 ? (
                  <p className="py-3">
                    No se encontraron relojes con los filtros seleccionados.
                  </p>
                ) : null}

                {/* {filteredProducts?.length == 0 &&
                  categories?.length === 0 &&
                  currentPosts?.length > 0 &&
                  currentPosts?.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      loading={loading}
                    />
                  ))} */}
              </Row>
              {/* {!loading && (
                <CustomPagination
                  postsPerPage={postsPerPage}
                  totalPosts={originalProducts.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              )} */}
              {categories.length !== 0 ? (
                <CustomPagination
                  postsPerPage={postsPerPage}
                  totalPosts={searchProducts()?.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              ) : (
                <CustomPagination
                  postsPerPage={postsPerPage}
                  totalPosts={totalPages}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              )}
            </div>
          </div>
        </section>
      </Row>
    </section>
  );
}

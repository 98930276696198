import React, { useState, useEffect } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Custom CSS
import "../assets/scss/pages/order.scss";

// API
import { getMeApi } from "../api/user";
import { getUserOrders } from "../api/order";

// Hooks
import { useAuth } from "../context/AuthContext";

// Custom Components
import OrderList from "../components/OrderList/OrderList";

export default function Profile() {
  // const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const { auth, logout, user } = useAuth();
  // useEffect(() => {
  //   (async () => {
  //     const response = await getMeApi(logout);
  //     setUser(response);
  //   })();
  // }, [auth]);
  useEffect(
    (logout) => {
      (async () => {
        setLoading(true);
        if (user !== undefined) {
          const response = await getUserOrders(user?.id, auth.token);
          if (response?.data.length > 0) {
            setOrderItems(response.data);
            setLoading(false);
            // console.log(response);
          } else {
            setOrderItems([]);
            setLoading(false);
          }
        }
      })();
    },
    [user]
  );

  return (
    <div className="orders__wrapper">
      <Container className="orders__container">
        {loading ? (
          <p className="py-2">Cargando ordenes...</p>
        ) : (
          <>
            <div className="orders__container__heading">
              <h2>
                Bienvenida/o <span>{user?.username}</span>
              </h2>
              <h3 className="mb-2">Historial de ordenes.</h3>
              <p>Número de ordenes: {orderItems.length}</p>
            </div>
            <Row>
              <OrderList orderItems={orderItems} />
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}

import { BASE_PATH } from "../utils/constants";

export async function getBrands() {
  try {
    const sortItem = "sort=nombre:asc";
    const url = `${BASE_PATH}/api/marcas?${sortItem}&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET ?filters[slug][$eq]=SLUG&[populate]=*
  // @desc     Get a brand from a specific slug
  // @access   Public
*/
export async function getBrand(brandName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${BASE_PATH}/api/marcas?filters[slug][$eq]=${brandName}&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

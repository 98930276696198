import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Form validation
// import { Formik } from "formik";
// import * as yup from "yup";

// Context
import AlertContext from "../../context/AlertContext";
import { useAuth } from "../../context/AuthContext";

// Custom components
import Timer from "react-compound-timer";
import moment from "moment-timezone";
// import { ProductCarousel } from "../Carousel/Carousel";
import { Carousel } from "react-responsive-carousel";

// Custom CSS
import "./raffleProduct.styles.scss";

// API
import { getMeApi } from "../../api/user";
import { postBid } from "../../api/raffle";
// import useAuth from "../../hooks/useAuth";

// Utils
import { BASE_PATH } from "../../utils/constants";
import formatMoney from "../../utils/formatMoney";
import useForm from "../../utils/useForm";
import useCart from "../../hooks/useCart";

// Media
import nationalLottery from "../../assets/images/national-lottery.png";
import Bids from "../../pages/Bids";

export default function RaffleProduct({ product }) {
  const [bids, setBids] = useState(product.attributes.raffleEntries);
  // const [user, setUser] = useState(undefined);
  const [hasEnded, setHasEnded] = useState(false);
  const { auth, logout, user } = useAuth();
  const timer = useRef();
  const { addProductCart } = useCart();

  const alert = useContext(AlertContext);

  // useEffect(() => {
  //   (async () => {
  //     const response = await getMeApi(logout);
  //     if (response) {
  //       setUser(response);
  //     }
  //   })();
  // }, []);

  const counterValue = () => {
    const dateFromApi = product.attributes?.fechaFinal;
    const createdAt = moment.tz(dateFromApi, "America/Mexico_City");
    const added = createdAt.clone();
    const now = moment.tz("America/Mexico_City");
    const duration = added.diff(now, "milliseconds");
    console.log(duration);
    return duration;
  };
  // console.log(product);

  const [count, setCount] = useState(product?.attributes.pujaInicial);
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + product?.attributes.pujaSubsecuente);
  };
  const handleDecrement = () => {
    // Here to validate not to be less than last posted

    // Get all bids and loop through them to find last bid
    // if (bids.length === 0) {

    // }
    if (count > bids[bids.length - 1]?.bid) {
      setCount((prevCount) => prevCount - product?.attributes.pujaSubsecuente);
    } else if (count > product?.attributes.pujaInicial) {
      setCount((prevCount) => prevCount - product?.attributes.pujaSubsecuente);
    } else {
      return;
    }
  };

  const { inputs, handleChange, resetForm } = useForm({
    user: user?.username,
    bid: 0,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const formDataTemp = {
      id: product.id,
      data: {
        raffleEntries: [
          ...bids,
          { id: user.id, usuario: user.username, bid: count },
        ],
      },
    };
    const response = await postBid(product.id, formDataTemp, logout);
    if (!response) {
      console.log("Hubo un error en tu solicitud de puja");
      alert.error("Hubo un error en tu solicitud de puja.");
    } else {
      alert.success("Tu puja fue publicada exitosamente.");
      console.log(response);
      setBids(response.raffleEntries);
      resetForm({
        user: user.username,
      });
      setCount(
        response.raffleEntries[response.raffleEntries.length - 1].bid +
          product?.attributes.pujaSubsecuente
      );
    }
  }

  useEffect(() => {
    if (bids.length === 0) {
      setCount(product?.attributes.pujaInicial);
    } else {
      setCount(bids[bids.length - 1].bid + product?.attributes.pujaSubsecuente);
    }
  }, [bids]);

  // console.log(timer.current.getTime());

  // const initCarousel = () => {
  //   // Product images carousel
  //   const slideContainer = document.querySelector(
  //     ".product-carousel__slide-container"
  //   );
  //   console.log(slideContainer);
  //   const slides = Array.from(slideContainer.children);
  //   const nextButton = document.querySelector(
  //     ".product-carousel__carousel__button--right"
  //   );
  //   const prevButton = document.querySelector(
  //     ".product-carousel__carousel__button--left"
  //   );
  //   const carouselNav = document.querySelector(
  //     ".product-carousel__carousel__nav"
  //   );
  //   const carouselPages = Array.from(carouselNav.children);

  //   const slideWidth = slides[0].getBoundingClientRect().width;
  //   console.log(slides);
  //   slides[0]?.classList.add("current-slide");
  //   carouselPages[0]?.classList.add("current-page");

  //   // Slides layout
  //   const setSlideLayout = (slide, index) => {
  //     slide.style.left = slideWidth * index + "px";
  //   };

  //   slides.forEach(setSlideLayout);

  //   const slideMovement = (slideContainer, currentSlide, wantedSlide) => {
  //     slideContainer.style.transform =
  //       "translateX(-" + wantedSlide.style.left + ")";

  //     // Update current slide class
  //     currentSlide.classList.remove("current-slide");
  //     wantedSlide.classList.add("current-slide");
  //   };

  //   const updatePage = (currentPage, wantedPage) => {
  //     currentPage.classList.remove("current-page");
  //     wantedPage.classList.add("current-page");
  //   };

  //   const hideShowButtons = (slides, prevButton, nextButton, wantedIndex) => {
  //     if (wantedIndex === 0) {
  //       prevButton.classList.add("v-hidden");
  //       nextButton.classList.remove("v-hidden");
  //     } else if (wantedIndex === slides.length - 1) {
  //       prevButton.classList.remove("v-hidden");
  //       nextButton.classList.add("v-hidden");
  //     } else {
  //       prevButton.classList.remove("v-hidden");
  //       nextButton.classList.remove("v-hidden");
  //     }
  //   };

  //   // Left movement on left click
  //   prevButton.addEventListener("click", (event) => {
  //     const currentSlide = slideContainer.querySelector(".current-slide");
  //     const prevSlide = currentSlide.previousElementSibling;

  //     const currentPage = carouselNav.querySelector(".current-page");
  //     const prevPage = currentPage.previousElementSibling;
  //     const prevIndex = carouselPages.findIndex((page) => page === prevPage);

  //     slideMovement(slideContainer, currentSlide, prevSlide);
  //     updatePage(currentPage, prevPage);
  //     hideShowButtons(slides, prevButton, nextButton, prevIndex);
  //   });

  //   // Right movement on right click
  //   nextButton.addEventListener("click", (event) => {
  //     const currentSlide = slideContainer.querySelector(".current-slide");
  //     const nextSlide = currentSlide.nextElementSibling;

  //     const currentPage = carouselNav.querySelector(".current-page");
  //     const nextPage = currentPage.nextElementSibling;
  //     const nextIndex = carouselPages.findIndex((page) => page === nextPage);

  //     slideMovement(slideContainer, currentSlide, nextSlide);
  //     updatePage(currentPage, nextPage);
  //     hideShowButtons(slides, prevButton, nextButton, nextIndex);
  //   });

  //   carouselNav.addEventListener("click", (event) => {
  //     // Get clicked page
  //     const wantedPage = event.target.closest("button");
  //     if (!wantedPage) return;

  //     const currentPage = carouselNav.querySelector(".current-page");
  //     const currentSlide = slideContainer.querySelector(".current-slide");
  //     const wantedIndex = carouselPages.findIndex(
  //       (page) => page === wantedPage
  //     );
  //     const wantedSlide = slides[wantedIndex];

  //     slideMovement(slideContainer, currentSlide, wantedSlide);
  //     updatePage(currentPage, wantedPage);
  //     hideShowButtons(slides, prevButton, nextButton, wantedIndex);
  //   });
  // };

  // useEffect(() => {
  //   initCarousel();
  // }, [product]);

  return (
    <Col xs="6" lg="3" className="raffle-product-card">
      <div className="raffle-product-card__media">
        {/* <img
          // src={`${BASE_PATH}${product.attributes?.imagenes.data[0].attributes.url}`}
          src={product.attributes?.imagenes.data[0].attributes.url}
          alt={product.attributes?.nombreProducto}
        /> */}
        {/* <ProductCarousel
          productName={product.attributes?.nombreProducto}
          productImages={product.attributes?.imagenes.data}
          product={product}
        /> */}
        <Carousel showStatus={false} showIndicators={false}>
          {product.attributes?.imagenes.data.map((image, key) => (
            <Link
              to={`/auction-product/${product.id}`}
              key={key}
              style={{ display: "block", height: "100%", width: "100%" }}
            >
              <img
                src={image.attributes.url}
                // src={`${BASE_PATH}${image.attributes.url}`}
                alt=""
              />
            </Link>
          ))}
        </Carousel>
      </div>
      <div className="raffle-product-card__body">
        <Link to={`/auction-product/${product.id}`}>
          <h5>{product.attributes?.nombreProducto}</h5>
        </Link>
        <span>La subasta termina en:</span>
        <p className="raffle-product-card__timer">
          <Timer
            initialTime={counterValue()}
            direction="backward"
            ref={timer}
            checkpoints={[
              {
                time: 0,
                // callback: (e) => {
                //   setHasEnded(true);
                //   console.log(e);
                //   // var myobj = document.getElementById("bid-form");
                //   // myobj.style.display = "none";
                //   console.log(bids[bids.length - 1].usuario);
                //   addProductCart(
                //     product?.attributes,
                //     1,
                //     bids[bids.length - 1].id
                //   );
                // },
              },
            ]}
          >
            {() => (
              <>
                <Timer.Days /> días <Timer.Hours /> horas <Timer.Minutes />{" "}
                minutos <Timer.Seconds /> segundos{" "}
              </>
            )}
          </Timer>
        </p>
        <span>Precio del reloj en el mercado:</span>
        <h6>
          {formatMoney(product.attributes?.precio)} MXN <span></span>
        </h6>
        {/* <a
          href="https://www.youtube.com/c/Loter%C3%ADaNacional-SorteosTradicionales/featured"
          target="_blank"
          rel="noreferrer"
          className="national-lottery-link"
        >
          <img src={nationalLottery} alt="La Relojeríair" />
          Link directo a Lotería Nacional
        </a> */}
        <p className="winner-text">
          El ganador favor de comunicarse por{" "}
          <a href="https://wa.me/524495457748" target="_blank" rel="noreferrer">
            <i className=" fab fa-whatsapp"></i> WhatsApp
          </a>
        </p>

        <h4>Pujas:</h4>
        <div className="raffle-product-card__bids__container">
          {Array.from(bids)
            ?.sort((a, b) => b.bid - a.bid)
            .map((bid, key) => (
              <div key={key} className="raffle-product-card__bids">
                <div className="d-flex justify-content-between w-100">
                  <p>
                    <span>Nombre: </span>
                    {bid.usuario}
                  </p>
                  <p className="mt-2 mt-lg-0">
                    <span>Puja: </span>
                    {formatMoney(bid.bid)} MXN
                  </p>
                </div>
                <span className="raffle-product-card__bids__next-bid">
                  La siguiente puja es de:{" "}
                  {formatMoney(
                    bids[bids.length - 1].bid +
                      product?.attributes.pujaSubsecuente
                  )}
                </span>
              </div>
            ))}
        </div>
        <div className="raffle-product-card__bids__input">
          {!auth?.idUser && (
            <p>
              Debes <Link to="/login">iniciar sesión</Link> o{" "}
              <Link to="/register">registrarte</Link> para publicar una puja.
            </p>
          )}
          {console.log(auth.idUser)}
          {auth.idUser && timer.current?.getTime() > 0 ? (
            <Form onSubmit={handleSubmit} id="bid-form">
              <Row>
                <Form.Group className="mb-1" controlId="formName">
                  <Form.Label>Nombre de usuario:</Form.Label>

                  <Form.Control
                    name="user"
                    plaintext
                    defaultValue={user?.username}
                    readOnly
                  />
                  <Form.Text className="text-muted">
                    Este es tu nombre de usuario
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-2" controlId="formBid">
                  <Form.Label>Oferta:</Form.Label>
                  <div className="bid-container">
                    <button
                      type="button"
                      className="counter-action"
                      disabled={
                        count <=
                        bids[bids.length - 1]?.bid +
                          product?.attributes.pujaSubsecuente
                      }
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                    {/* <h5 className="m-0 px-3">{count}</h5> */}
                    {/* <input
                      type="number"
                      className="form-control number"
                      name="bid"
                      readOnly
                      value={inputs.bid}
                      // onChange={handleChange}
                      // isInvalid={errors.bid}
                      // onChange={(e) => {
                      //   handleChange(e);
                      //   console.log(e);
                      // }}
                    /> */}
                    <h5 className="form-control">{formatMoney(count)}</h5>
                    <button
                      type="button"
                      className="counter-action"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>

                  {/* <div className="error-feedback">
                    {errors.bid && touched.bid && errors.bid}
                  </div> */}
                  {/* <Form.Text className="text-muted">Rango: 1-5</Form.Text> */}
                </Form.Group>
              </Row>
              <Button className="send" type="submit">
                Enviar puja
              </Button>
            </Form>
          ) : (
            <></>
          )}
          {timer.current?.getTime() < 0 && <p>La subasta ha finalizado</p>}
        </div>
      </div>
    </Col>
  );
}

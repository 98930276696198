import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Axios for sending email
import axios from "axios";

// Bootstrap components
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

// Custom CSS
// import "../../assets/scss/pages/payment.scss";

// Custom components
import OrderSummary from "../OrderSummary/OrderSummary";
import CardSection from "../CardSection/CardSection";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// API
import { getMeApi } from "../../api/user";
import { createOrder } from "../../api/order";
import { getProductsCart } from "../../api/cart";

// Hooks
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";

// Utils
import { v4 as uuidv4 } from "uuid";
import formatMoney from "../../utils/formatMoney";
import { BASE_PATH } from "../../utils/constants";

// Assets
import lockIconUrl from "../../assets/images/lock-icon.svg";
import creditCard1Url from "../../assets/images/credit-cards_visa.svg";
import creditCard2Url from "../../assets/images/credit-cards_mastercard.svg";
import creditCard3Url from "../../assets/images/credit-cards_amex.svg";

const initialValues = {
  clientName: "",
  // lastName: "",
  clientEmail: "",
  clientPhoneNumber: "",
  address: "",
  streetNumber: "",
  neighborhood: "",
  postalCode: 0,
  reference: "",
  municipality: "",
  city: "",
  state: "",
};

let schema = yup.object().shape({
  clientName: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  clientEmail: yup
    .string()
    .email("El email no es válido")
    .required("Este campo es requerido"),
  // .isValid("El email no es válido"),
  clientPhoneNumber: yup.number().required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  streetNumber: yup.string().required("Este campo es requerido"),
  neighborhood: yup.string().required("Este campo es requerido"),
  reference: yup.string().required("Este campo es requerido"),
  postalCode: yup
    .number()
    .required("Este campo es requerido")
    .moreThan(10000, "Ingresa un Código Postal válido"),
  municipality: yup.string().required("Este campo es requerido"),
  city: yup.string().required("Este campo es requerido"),
  state: yup.string().required("Este campo es requerido"),
});

export default function PaymentForm() {
  const [user, setUser] = useState({});
  // const [cartItems, setCartItems] = useState([]);
  const { auth, logout } = useAuth();
  const {
    productsCart,
    resetCart,
    totalPriceCart,
    cartItems,
    totalProductsCart,
  } = useCart();
  const [shipment, setShipment] = useState(0);
  // const [error, setError] = useState("");
  const [formError, setFormError] = useState();
  const history = useHistory();
  // console.log(total);
  // useEffect(
  //   (logout) => {
  //     (async () => {
  //       const response = await getMeApi(logout);
  //       setUser(response);
  //     })();
  //   },
  //   [auth]
  // );
  // console.log(cartItems);
  // console.log(auth);

  // useEffect(() => {
  //   (async () => {
  //     if (user !== undefined) {
  //       const response = await getProductsCart(user?.id, logout);
  //       if (response?.data.length > 0) {
  //         // for (let index = 0; index < response.data.length; index++) {
  //         //   delete response.data[index].attributes.updatedAt;
  //         //   delete response.data[index].attributes.publishedAt;
  //         //   delete response.data[index].attributes.productos.data[index]
  //         //     .attributes.imagenes;
  //         // }
  //         setCartItems(response.data);
  //       } else setCartItems([]);
  //     }
  //   })();
  // }, [user]);
  const stripe = useStripe();
  const elements = useElements();
  return (
    <Row>
      <Col xs="12" lg="8" className="client-data">
        <div className="client-data__address mt-4">
          <h5>
            <span>1</span> Datos de envío
          </h5>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              const today = new Date();
              const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
              const orderId = uuidv4().slice(0, 7);
              try {
                const { error, paymentMethod } =
                  await stripe.createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardElement),
                    billing_details: {
                      email: values.clientEmail,
                      name: values.clientName,
                      phone: values.clientPhoneNumber,
                      address: {
                        city: values.city,
                        country: "MX",
                        line1: values.address,
                        line2: values.neighborhood,
                        state: values.state,
                      },
                    },
                  });
                console.log(paymentMethod);
                const formDataTemp = {
                  data: {
                    token: paymentMethod.id,
                    orderId,
                    clientName: values.clientName,
                    clientEmail: values.clientEmail,
                    clientPhoneNumber: values.clientPhoneNumber,
                    address: values.address,
                    streetNumber: values.streetNumber,
                    neighborhood: values.neighborhood,
                    postalCode: parseInt(values.postalCode),
                    reference: values.reference,
                    municipality: values.municipality,
                    city: values.city,
                    state: values.state,
                    country: "México",
                    total: totalPriceCart,
                    orderItems: cartItems,
                    usuario: [auth.idUser],
                  },
                };
                const response = await createOrder(formDataTemp, auth);
                // console.log(response);
                if (response.data !== null) {
                  axios.all([
                    axios.post(
                      `${BASE_PATH}/api/email`,
                      {
                        To: "larelojeriaoficial17@gmail.com, rodrigo.zenteno7@gmail.com",
                        Subject: "Nueva orden de compra",
                        HtmlBody: `
                      <html lang="es">

                      <head>
                        <meta charset="UTF-8" />
                        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <link rel="preconnect" href="https://fonts.googleapis.com">
                        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                        <link href="https://fonts.googleapis.com/css2?family=Epilogue:wght@500;600&family=Montserrat&display=swap"
                          rel="stylesheet">
                      </head>
                      
                      <body style="margin: 0; padding: 0;">
                        <div style="font-family: 'Montserrat', sans-serif; background-color: #F3F3F3; padding: 2rem 1rem; height: auto;">
                          <div
                            style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #0D314F; padding: 0; overflow: hidden;">
                            <div style="padding: 2rem;">
                              <h2
                                style="text-transform: uppercase; font-family:  'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000;">
                                Nueva orden de compra de e-commerce La Relojeríair</h2>
                              <p style="margin-bottom: 1rem; color: #000;">${
                                values.clientName
                              } envío una orden de compra.</p>
                              <h3 style="font-family:  'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #bb2227;">
                                Datos de contacto:</h3>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Fecha:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${date}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Nombre:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientName}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Email:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientEmail}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Teléfono:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientPhoneNumber}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Dirección:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.address}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Número:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.streetNumber}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Colonia:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.neighborhood}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Referencia:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.reference}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Municipio:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.municipality}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Ciudad:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.city}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Estado:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.state}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Código Postal:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.postalCode}</td>
                                </tr>
                              </table>
                              <h3 style="font-family:  'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #bb2227">
                                Orden de compra: ${orderId}</h3>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                ${cartItems.map(
                                  (item) => `
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Producto:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${item.attributes.productos.data[0].attributes.nombre}
                                  </td>                                  
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Cantidad:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${item.attributes.cantidad}
                                  </td>
                                </tr>
                                `
                                )}
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Total:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${formatMoney(totalPriceCart)} MNX
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <img src="https://larelojeriair.com/static/media/logo.6c688721ca19b8134e9eb4c1cc34428c.svg" alt="Logo La Relojeríair" title="Logo La Relojeríair"
                              style="display: block; margin: 1rem auto;" width="205px" height="auto" />
                          </div>
                        </div>
                      </body>
                      
                      </html>`,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${auth.token}`,
                        },
                      }
                    ),
                    axios.post(
                      `${BASE_PATH}/api/email`,
                      {
                        To: `${values.clientEmail}`,
                        Subject: "Confirmación de orden",
                        HtmlBody: `
                          <html lang="es">

                          <head>
                            <meta charset="UTF-8" />
                            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="preconnect" href="https://fonts.googleapis.com">
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                            <link href="https://fonts.googleapis.com/css2?family=Epilogue:wght@500;600&family=Montserrat&display=swap"
                              rel="stylesheet">
                          </head>
                          
                          <body style="margin: 0; padding: 0; ">
                            <div style="font-family: 'Montserrat', sans-serif; background-color: #F3F3F3; padding: 2rem 1rem; height: auto;">
                              <div
                                style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #0D314F; padding:0; overflow: hidden;">
                                <div class="heading" style="background-color: #FBFBFB; padding: 2rem; text-align: center;">
                                  <img src="https://larelojeriair.com/static/media/logo.6c688721ca19b8134e9eb4c1cc34428c.svg"
                                    style="height: 60px;" alt="La Relojeríair">
                                </div>
                                <div style="padding: 1rem 1.5rem;">
                                  <h2
                                    style="text-transform: uppercase; font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000; text-align: center;">
                                    Confirmación de orden</h2>
                                  <div style="text-align: center;">
                                    <img src="https://larelojeriair.com/static/media/check-icon--green--sm.2689592d6d9a129756b67c8955b16f5c.svg"
                                      alt="La Relojeríair" title="La Relojeríair" style="display: block; margin: 1rem auto;" width="80px"
                                      height="auto" />
                                    <h3 style="margin-bottom: .5rem;">Hemos recibido tu orden</h3>
                                    <p style="margin-bottom: 1rem; margin-top: 0; color: #000;">Número de orden #${orderId}</p>
                                  </div>
                                  <h3 style="font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #bb2227;">
                                    Detalles de envío</h3>
                                  <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0;">
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Fecha:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${date}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Nombre:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.clientName}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Email:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.clientEmail}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Teléfono:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.clientPhoneNumber}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Dirección:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.address}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Número:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.streetNumber}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Colonia:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.neighborhood}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Referencia:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.reference}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Municipio:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.municipality}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Ciudad:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.city}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Estado:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.state}</td>
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Código Postal:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.postalCode}</td>
                                    </tr>
                                  </table>
                                  <h3 style="font-family: 'Epilogue', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #bb2227">
                                    Detalles de la orden:</h3>
                                  <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">
                          
                                    ${cartItems.map(
                                      (item) => `
                                    <div style="display: flex; justify-content: space-between;">
                                      <div style="display: flex; margin-top: .8rem;">
                                        <p style="margin: 0; margin-right: 1rem;">${
                                          item.attributes.cantidad
                                        } x</p>
                                        <p style=" margin: 0; font-weight: bolder;">${
                                          item.attributes.productos.data[0]
                                            .attributes.nombre
                                        }</p>
                                      </div>
                                      <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                        item.attributes.productos.data[0]
                                          .attributes.precio
                                      )} MNX
                                      </p>
                                    </div>
                                    <div style="display: flex; justify-content: space-between;">
                                      <div>
                                        <strong>Tiempo de entrega:</strong>
                                      </div>
                                      <div>
                                      <strong style="color: #E10009"> ${
                                        item.attributes.productos.data[0]
                                          .attributes.eta
                                      } días</strong>
                                       
                                      </div>
                                    </div>
                                    `
                                    )}
                                  </div>
                                  <p>El reloj debe de llegar a más tardar en tres días hábiles, sino favor de comunicarse por WhatsApp al
                                    siguiente número <a href="">4491234567</a></p>
                                  <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">
                                    <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                                      <p style="margin: 0; font-size: 1.1rem;">Envío</p>
                                      <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                        shipment
                                      )} MNX</p>
                                    </div>
                                  </div>
                                  <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                                    <h5 style="margin: 0; font-size: 1.1rem;">Total</h5>
                                    <p style="margin: 0; color: #bb2227; font-size: 1.4rem;">${formatMoney(
                                      totalPriceCart
                                    )} MNX</p>
                                  </div>
                                </div>
                              </div>
                              <div class="coupon"
                                style="background: #0D314F; width: 90vw; margin: 1rem auto; color: #fff; padding: 2.3rem 1rem; text-align: center; box-sizing: border-box; ">
                                <h3
                                  style="font-size: 1.7rem; margin: 0; line-height: 1.7px; font-family: 'Epilogue', sans-serif; line-height: 1;">
                                  Síguenos en
                                  facebook</h3>
                                <a style="display: block; text-decoration: none; font-weight: bolder; font-size: 1.5rem; margin-top: 1rem; color: #fff;"
                                  href="https://www.facebook.com/La-Relojeria-Original-103481138576106" target="_blank">La Relojeríair</a>
                              </div>
                            </div>
                          </body>
                          
                          </html>`,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${auth.token}`,
                        },
                      }
                    ),
                  ]);
                  // .then((res) => {

                  //   // console.log(res);
                  //   // let response = {};
                  //   // console.log(response);
                  //   // if (response.data !== null) {
                  //   //   history.push(`/confirmation/${orderId}`);
                  //   // }
                  // })
                  // .catch(function (error) {
                  //   console.log(error);
                  // });
                  for (let i = 0; i < cartItems.length; i++) {
                    const element = cartItems[i];
                    resetCart(element.id, auth);
                    // console.log(response);
                    // return response;
                  }
                  history.push(`/confirmation/${orderId}`);
                } else {
                  setFormError(
                    "Hubo un error en tu método de pago. Verifica que los datos sean correctos o comunícate con tu institución bancaria."
                  );
                }
              } catch (error) {
                setFormError(
                  "Hubo un error en servidor. Por favor, vuelve a intentarlo"
                );
              }

              // const response = await registerApi(values);
              // if (response?.jwt) {
              //   console.log(response);
              //   history.push("/login");
              // } else {
              //   console.error(
              //     "Error al registrar el usuario, inténtelo mas tarde"
              //   );
              // }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Nombre Completo:</Form.Label>
                    <Form.Control
                      name="clientName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientName}
                      isInvalid={errors.clientName}
                      disabled={isSubmitting}
                      placeholder="Tu nombre"
                    />
                    <div className="error-feedback">
                      {errors.clientName &&
                        touched.clientName &&
                        errors.clientName}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridEmail"
                  >
                    <Form.Label>Correo electrónico:</Form.Label>
                    <Form.Control
                      name="clientEmail"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientEmail}
                      isInvalid={errors.clientEmail}
                      disabled={isSubmitting}
                      placeholder="Email"
                    />
                    <div className="error-feedback">
                      {errors.clientEmail &&
                        touched.clientEmail &&
                        errors.clientEmail}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridPhone"
                  >
                    <Form.Label>Teléfono:</Form.Label>
                    <Form.Control
                      name="clientPhoneNumber"
                      type="tel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientPhoneNumber}
                      isInvalid={errors.clientPhoneNumber}
                      disabled={isSubmitting}
                      placeholder="Teléfono"
                    />
                    <div className="error-feedback">
                      {errors.clientPhoneNumber &&
                        touched.clientPhoneNumber &&
                        errors.clientPhoneNumber}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="12"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Dirección:</Form.Label>
                    <Form.Control
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      isInvalid={errors.address}
                      disabled={isSubmitting}
                      placeholder="Dirección"
                    />
                    <div className="error-feedback">
                      {errors.address && touched.address && errors.address}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Número:</Form.Label>
                    <Form.Control
                      name="streetNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.streetNumber}
                      isInvalid={errors.streetNumber}
                      disabled={isSubmitting}
                      placeholder="Número"
                    />
                    <div className="error-feedback">
                      {errors.streetNumber &&
                        touched.streetNumber &&
                        errors.streetNumber}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Colonia:</Form.Label>
                    <Form.Control
                      name="neighborhood"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.neighborhood}
                      isInvalid={errors.neighborhood}
                      disabled={isSubmitting}
                      placeholder="Colonia"
                    />
                    <div className="error-feedback">
                      {errors.neighborhood &&
                        touched.neighborhood &&
                        errors.neighborhood}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Referencia:</Form.Label>
                    <Form.Control
                      name="reference"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                      isInvalid={errors.reference}
                      disabled={isSubmitting}
                      placeholder="Referencia"
                    />
                    <div className="error-feedback">
                      {errors.reference &&
                        touched.reference &&
                        errors.reference}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Código Postal:</Form.Label>
                    <Form.Control
                      name="postalCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      isInvalid={errors.postalCode}
                      disabled={isSubmitting}
                      placeholder="Código Postal"
                    />
                    <div className="error-feedback">
                      {errors.postalCode &&
                        touched.postalCode &&
                        errors.postalCode}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Municipio:</Form.Label>
                    <Form.Control
                      name="municipality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.municipality}
                      isInvalid={errors.municipality}
                      disabled={isSubmitting}
                      placeholder="Municipio"
                    />
                    <div className="error-feedback">
                      {errors.municipality &&
                        touched.municipality &&
                        errors.municipality}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Ciudad:</Form.Label>
                    <Form.Control
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      isInvalid={errors.city}
                      disabled={isSubmitting}
                      placeholder="Ciudad"
                    />
                    <div className="error-feedback">
                      {errors.city && touched.city && errors.city}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3 form-group"
                    // controlId="formGridName"
                  >
                    <Form.Label>Estado:</Form.Label>
                    <Form.Control
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      isInvalid={errors.state}
                      placeholder="Estado"
                      disabled={isSubmitting}
                    />
                    <div className="error-feedback">
                      {errors.state && touched.state && errors.state}
                    </div>
                  </Form.Group>
                </Row>
                <Row className="client-data__payment">
                  <h5>
                    <span>2</span> Detalles de pago{" "}
                    <img src={lockIconUrl} alt="La Relojeríair" />
                  </h5>
                  <div className="d-flex flex-column flex-lg-row align-items-lg-center client-data__payment__cards mt-2">
                    <p className="mb-2 mb-lg-0 me-2">
                      Aceptamos pagos de las siguientes tarjetas de
                      crédito/débito:
                    </p>
                    <div>
                      <img src={creditCard1Url} alt="La Relojeríair" />
                      <img
                        src={creditCard2Url}
                        className="mx-2"
                        alt="La Relojeríair"
                      />
                      <img src={creditCard3Url} alt="La Relojeríair" />
                    </div>
                  </div>
                  <CardSection
                  // stripeError={error}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      margin: ".6rem 0",
                      padding: "0",
                    }}
                  >
                    {formError}
                  </span>
                </Row>
                <Row className="other-data__payment">
                  <h5>
                    <span>3</span> Otro método de pago
                  </h5>
                  <p>
                    Si quieres un descuento extra en tu compra, el pago debes
                    efectuarlo por medio de retiro sin tarjeta, comunícate con
                    nosotros por{" "}
                    <a
                      href="https://wa.me/524495457748"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className=" fab fa-whatsapp"></i> WhatsApp
                    </a>
                  </p>
                  <span> Sólo aplica en compras menores a $9,000.00</span>
                </Row>
                <Button
                  className="confirm-payment"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Pagar {formatMoney(totalPriceCart)}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
      <Col xs="12" lg="4" className="order-summary">
        <OrderSummary
          cartItems={cartItems}
          productsCart={productsCart}
          total={totalPriceCart}
          totalProductsCart={totalProductsCart}
        />
      </Col>
    </Row>
  );
}
